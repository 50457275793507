//@flow
import React, {useCallback, useMemo, useState} from 'react';
import msgs, {getLangValue, useMsgs} from "../lib/Language";
import {Alert, Breadcrumb, Button, Card, Col, Row, Tab, Tabs} from "react-bootstrap";
import {BreadcrumbItem, Dialog, dialogOpen, Hint, InlineDialog, PageHeader} from "../lib/Components";
import DesktopScreen from "./DesktopScreen";
import InvoiceMonitoringDesktopScreen from "./InvoiceMonitoringDesktopScreen";
import InvoiceMonitoringClientsScreen from "./InvoiceMonitoringClientsScreen";
import {useLocation, useParams} from "react-router";
import {formatTax, parseNumber} from "../lib/Utils";
import ContentPage from "../lib/ContentPage";
import {
    ChangeScenarioDialog,
    ClientInvoicesList,
    getMonitoredClientStageName,
    indexOfStage,
    InvoicesFilter,
    InvoicesList,
    MonitoringScenarioController,
    MonitoringScenarioStepsForm,
    NotificationCountersTable,
    NotificationFilter,
    NotificationsList,
    OrderedStages,
    ScenarioSelect,
    StageColors
} from "../lib/InvoiceMonitoringComponents";
import type {FilterQuery} from "../lib/Filter";
import {emptyQuery, extendFilterQuery} from "../lib/Filter";
import {useDelayed, useHistoryState} from "../lib/Hooks";
import LazyPieChart from "../lib/LazyPieChart";
import {QueryLoader, useRpcQuery, useRpcStaticQuery} from "../lib/QueryUtils";
import type {
    MonitoredClientDetails,
    MonitoredInvoice,
    MonitoringScenarioDetails,
    MonitoringScenarioStepSetup
} from "../api";
import {store} from "../application";
import {Redirect, useHistory} from "react-router-dom";
import {MonitoringStage} from "../api";
import {useQueryClient} from "@tanstack/react-query";
import cn from 'classnames';
import {VindicationNew} from "./ViewVindicationScreen";

const ClientInvoices = ({ id }: {
    id: string;
}) => {
    const [filter, setFilter] = useState<FilterQuery>(() => emptyQuery());
    const [dFilter, setDFilter] = useDelayed(filter, setFilter);
    const updatedFilter = useMemo<FilterQuery>(() => ({
        ...filter,
        fields: [
            {
                field: 'client',
                value: id,
            },
            ...filter.fields
        ]
    }), [filter, id]);

    return <>
        <Card className="search">
            <Card.Body>
                <InvoicesFilter value={dFilter} onChange={setDFilter}/>
            </Card.Body>
        </Card>
        <InvoicesList customFilters={updatedFilter} displayClient={false} actions={true}/>
    </>
}

const StagesPieChart = ({ stages }: {
    stages: { [MonitoringStage]: string }|null;
}) => {
    const msgs=useMsgs();

    if(stages===null || stages===undefined || typeof(stages)!=='object' || Object.keys(stages).length<1) return null;
    let sum=0;
    for(const stage of OrderedStages) sum+=parseNumber(stages[stage], 0);
    if(sum<=0.01) return null;

    let data=[];
    for(const stage of OrderedStages) {
        const v=parseNumber(stages[stage]);
        if(v===null || v<=0.01) continue;
        const pr=(v*100)/sum;
        if(pr<0.1) continue;
        data.push({
            name: msgs.gui['monitoringStage'+stage],
            value: pr,
            color: StageColors[indexOfStage(stage)],
        })
    }
    return <LazyPieChart data={data}/>
}


const ClientMonitoringScenario = ({ client, value, steps, paused, excluded }: {
    client: string;
    value: string|null;
    steps: Array<MonitoringScenarioStepSetup>|null;
    paused: boolean;
    excluded: boolean;
}) => {
    const msgs=useMsgs();
    const details = useRpcStaticQuery("/user", "getMonitoringScenario", value || "");
    const query = useQueryClient();
    const [edit, setEdit] = useState(false);
    const handleEdit = useCallback(() => {
        setEdit(true);
    }, []);
    const handleSubmitEdit = useCallback(async(steps) => {
        const res=await store.userApi.updateMonitoringScenario(client, value, steps);
        if(res) {
            await query.invalidateQueries(["rpc", "/user", "getMonitoredClient"]);
            await query.invalidateQueries(["rpc", "/user", "queryMonitoredClient"]);
        }
        setEdit(false);
    }, [ client, value ]);

    const [change, setChange] = useState(false);
    const handleChange = useCallback(() => {
        setChange(true);
    }, []);
    const handleSubmitChange = useCallback(async (newScenario: string) => {
        const res = await store.userApi.updateMonitoringScenario(client, newScenario, null);
        if (res) {
            await query.invalidateQueries(["rpc", "/user", "getMonitoredClient"]);
            await query.invalidateQueries(["rpc", "/user", "queryMonitoredClient"]);
        }
        setChange(false);
    }, [client]);
    const togglePause = useCallback(async () => {
        const res=await store.userApi.pauseMonitoringScenario(client, !paused);
        if(res) {
            await query.invalidateQueries(["rpc", "/user", "getMonitoredClient"]);
            await query.invalidateQueries(["rpc", "/user", "queryMonitoredClient"]);
        }
    },  [ client, paused ]);
    const toggleExcluded = useCallback(async () => {
        const res=await store.userApi.excludeMonitoringScenario(client, !excluded);
        if(res) {
            await query.invalidateQueries(["rpc", "/user", "getMonitoredClient"]);
            await query.invalidateQueries(["rpc", "/user", "queryMonitoredClient"]);
        }
    },  [ client, excluded ]);

    if (!details.isSuccess) return null;
    const data: MonitoringScenarioDetails = details.data;

    return <>
        {!data ? <h3 className="text-warning">{msgs.gui.imNoScenario}
            <Button className="float-right" onClick={handleChange}>{msgs.gui.buttonSelectScenario}</Button>
        </h3> : <>
            <h2>
                <div className="float-right">
                    <Button
                        onClick={togglePause}
                        variant={paused?"success":"danger"}
                    >
                        {paused?msgs.gui.imClientRestore:msgs.gui.imClientPause}
                    </Button> <Button
                    onClick={handleChange}
                >
                    {msgs.gui.buttonChangeScenario}
                </Button>
                </div>
                {getLangValue(data.title)}
            </h2>
            <Alert variant="info" className="my-3">{getLangValue(data.desc)}</Alert>
            {value && <MonitoringScenarioStepsForm scenario={value} previewClient={client} data={data} readonly={true} value={steps}/>}
            {value && <>
                <Row>
                    <Col md={6}>
                        <Button variant="success" onClick={handleEdit}>{msgs.gui.buttonEditSteps}</Button>
                    </Col>
                    <Col md={6} className="text-right">
                        <Button
                            variant={excluded?"success":"danger"}
                            onClick={toggleExcluded}
                        >{excluded?msgs.gui.imClientEnable:msgs.gui.imClientDisable}</Button>
                    </Col>
                </Row>
            </>}
            {edit && <MonitoringScenarioController
                scenario={value} previewClient={client}
                value={steps}
                onHide={() => setEdit(false)}
                readonly={false}
                onChange={handleSubmitEdit}
            />}
        </>}
        {change && <ChangeScenarioDialog
            value={value}
            onChange={handleSubmitChange}
            onHide={() => setChange(false)}
        />}
    </>;
}

const ContactLabel = ({ ready, valid, label, value, missingMessage, invalidMessage, type }: {
    /** Czy dane są gotowe do wyświetlenia */
    ready: boolean;
    /** Czy dane są poprawne */
    valid: boolean;
    /** Etykieta dla wartości */
    label: string|React$Node;
    /** Właściwa wartość */
    value: string|React$Node;
    /** Komunikat, gdy nie ma wartości */
    missingMessage: React$Node|string;
    /** Komunikat, gdy wartość niepoprawna */
    invalidMessage: React$Node|string;
    type: "tel"|"mailto";
}) => {
    let missing=false;
    let v=null;
    if(ready) {
        if(!value || (typeof(value)==='string' && (value.trim().length===0))) {
            v=missingMessage;
            missing=true;
        } else {
            v=<a href={type+':'+value}>{value}</a>;
        }
    }
    let l=<label
        className={cn("with-hint", ready && (missing || !valid) && "missing-value")} key="l"
    >{label}{ready && !valid && <Hint>{missing?missingMessage:invalidMessage}</Hint>}
    </label>;

    return [ l, <div key="v">{v}</div> ];
}

/**
 * Ekran utworzenia windykacji dla danego kontrahenta na podstawie listy faktur.
 */
// export const InvoiceMonitoringNewVindicationDialog = () => {
//     const msgs=useMsgs();
//     const { id } = useParams();
//     const query=useRpcQuery("/user", "getMonitoredVindicationInvoices", [ id ]);
//     const [ selected, setSelected ] = useState([]);
//     const handleSelectionChange=useCallback((rows) => {
//         setSelected(Object.keys(rows));
//     }, [ setSelected ]);
//
//     if(!query.data) return null;
//     const data: Array<MonitoredInvoice>=query.data;
//
//     return <Dialog
//         title={msgs.gui.imNewVindication}
//         acceptButton={msgs.gui.imNewVindicationCreate}
//         acceptDisabled={selected.length===0}
//         onAccept={async () => {
//             console.log("New vindication: ", id, selected);
//             const res=await store.userApi.createInvoiceMonitoringVindication(id, selected);
//             console.log("Create result: ", res);
//             return res;
//         }}
//         size="xl"
//     >
//         <h3 className="mb-3">{msgs.gui.imNewVindicationInvoices}</h3>
//         <ClientInvoicesList
//             data={data}
//             onSelectionChange={handleSelectionChange}
//         />
//     </Dialog>
// }
// InvoiceMonitoringNewVindicationDialog.url="/im/clients/:id/vindication";
// InvoiceMonitoringNewVindicationDialog.link=(id) => `/im/clients/${id}/vindication`;


const ClientNotifications=({id}) => {
    const [ filter, setFilter ] = useHistoryState("filter", () => emptyQuery());
    const [ dFilter, setDFilter ] = useDelayed(filter, setFilter);
    const clientFilter=useMemo(() => extendFilterQuery(filter, 'clientId', id), [ filter ]);

    return <>
        <Card className="search">
            <Card.Body>
                <NotificationFilter value={dFilter} onChange={setDFilter} client={id}/>
            </Card.Body>
        </Card>

        <NotificationsList historyState={`im_nt_${id}`} clientId={id} customFilters={clientFilter}/>
    </>
}

const InvoiceMonitoringClientScreen = () => {
    const msgs=useMsgs();
    const { id } = useParams();
    const [ tab, setTab ] = useState()
    const query = useRpcQuery("/user", "getMonitoredClient", [ id ]);
    const c: MonitoredClientDetails = query.data;
    const display=c?c.name:id;
    const history=useHistory();
    const location=useLocation();
    const handleAddVindication=useCallback(async (e) => {
        const data=await store.userApi.getMonitoredVindicationInvoices(id);
        if(data.length===0) {
            // Brak faktur, które można przekazać do windykacji
            return;
        }

        VindicationNew.extraProps={
            client: {
                client: c,
                invoices: data,
            }
        }
        VindicationNew.dialogProps={
            size: "xl"
        }
        dialogOpen(history, location, VindicationNew.url);
    }, [ id, history, location, c ])

    if(!store.invoiceMonitoring || !store.invoiceMonitoring.read) return <Redirect to="/" push={false}/>

    return <>
        <Breadcrumb>
            <BreadcrumbItem to={DesktopScreen.url}>{msgs.gui.titleSummary}</BreadcrumbItem>
            <BreadcrumbItem to={InvoiceMonitoringDesktopScreen.link()}>{msgs.gui.menuInvoiceMonitoringDesktop}</BreadcrumbItem>
            <BreadcrumbItem to={InvoiceMonitoringClientsScreen.link()}>{msgs.gui.menuInvoiceMonitoringClients}</BreadcrumbItem>
            <BreadcrumbItem active>{display}</BreadcrumbItem>
        </Breadcrumb>
        <PageHeader title={display}>
            <Button
                size="xl"
                className="btn-icon"
                disabled={!c || !c.canVindicate}
                // style={{ pointerEvents: disabled?'none':undefined }}
                onClick={handleAddVindication}>
                <span className="icon"><span className="icon-add-outline"/></span>
                {msgs.gui.imNewVindicationAction}
            </Button>
        </PageHeader>
        <Row>
            <Col md={8}>
                <Card className="data basic shadow">
                    <Card.Body>
                        <Row>
                            <Col md={7}>
                                <Row className="basic-header">
                                    <Col>
                                        <label>{msgs.gui.labelContractor}</label>
                                        <h3>{display}</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <label>{msgs.gui.labelNIP}</label>
                                        {c && formatTax(c.tax)}
                                    </Col>
                                    <Col md={8}>
                                        <label>{msgs.gui.labelAddress}</label>
                                        {c && <><span>{c.address}</span><br/><span>{c.postalCode} {c.city}</span></>}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <ContactLabel
                                            ready={!!c} valid={c && c.validPhone} label={msgs.gui.labelPhone} type="tel"
                                            value={c && c.phone} missingMessage={msgs.gui.imMissingPhone} invalidMessage={msgs.gui.imInvalidPhone}
                                        />
                                        {/*<label className={(c && (!c.phone || !c.validPhone)) && "missing-value"}>{msgs.gui.labelPhone}</label>*/}
                                        {/*{c && (c.phone ? <a href={`tel:${c.phone}`}>{c.phone}</a>: msgs.gui.imMissingPhone)}*/}
                                    </Col>
                                    <Col md={8}>
                                        <ContactLabel
                                            ready={!!c} valid={c && c.validEmail} label={msgs.gui.labelEmail} type="mailto"
                                            value={c && c.email} missingMessage={msgs.gui.imMissingEmail} invalidMessage={msgs.gui.imInvalidEmail}
                                        />
                                        {/*<label className={(c && (!c.email || !c.validEmail)) && "missing-value"}>{msgs.gui.labelEmail}</label>*/}
                                        {/*{c && (c.email ? <a href={`mailto:${c.email}`}>{c.email}</a> : msgs.gui.imMissingEmail)}*/}
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={5} className="col-splitter">
                                <Row className="basic-header">
                                    <Col>
                                        <Alert className="client-stage">
                                            <label>{msgs.gui.labelMonitoringState}</label>
                                            {/*{c && c.vindication && <h3>{msgs.gui.monitoringClientVindication}</h3>}*/}
                                            {c && (c.finalStage ?
                                                <h3 className={`stage-${c.finalStage}`}>{getMonitoredClientStageName(msgs, c)}<Hint>{msgs.gui.imScenarioHint}</Hint></h3>
                                                    :<h3>{msgs.gui.imClientPaid}</h3>
                                            )}
                                            {c && c.paused && <h4>{msgs.gui.imClientPaused}</h4>}
                                            {c && c.excluded && <h4>{msgs.gui.imClientDisabled}</h4>}
                                        </Alert>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label>{msgs.gui.labelMonitoringScenario}</label>
                                        <h3>{c && getLangValue(c.scenarioLabel)}</h3>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={4}>
                <Card className="data card-secondary state chart-view">
                    <Card.Body className="d-flex flex-column">
                        {c && c.stages && <StagesPieChart stages={c.stages}/>}
                        <Hint placement="left-end" className="hint-rb-corner">{msgs.gui.imScenarioHint}</Hint>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Card className="mt-5">
            <Card.Body>
                <Card.Title>{msgs.gui.imLastSent}</Card.Title>
                <NotificationCountersTable days={14} sent={c && c.sent} projected={c && c.projected} singleMode/>
            </Card.Body>
        </Card>
        <Tabs className="mt-5" activeKey={tab} onSelect={setTab}>
            <Tab eventKey="invoices" title={msgs.gui.tabInvoices}>
                <ClientInvoices id={id}/>
            </Tab>
            <Tab eventKey="notifications" title={msgs.gui.tabSendNotifications}>
                <ClientNotifications id={id}/>
            </Tab>
            <Tab eventKey="scenario" title={msgs.gui.tabMonitoringScenario}>
                {c && <ClientMonitoringScenario client={c.id} value={c.scenario} steps={c.steps} paused={c.paused} excluded={c.excluded}/>}
            </Tab>
            <Tab eventKey="other" title={msgs.gui.tabInvoiceMonitoringEffectiveness}>
                <ContentPage page="performance_analysis"/>
            </Tab>
        </Tabs>
    </>;
}

InvoiceMonitoringClientScreen.url="/im/clients/:id";
InvoiceMonitoringClientScreen.link = (id: string) => "/im/clients/"+id;
export default InvoiceMonitoringClientScreen;