//@flow
import React, {useMemo} from 'react';
import type {
    DebtExchangeInfo, LangOption,
    OrderStatus,
    VindicationDetails,
    VindicationDictionaries,
    VindicationEvent,
    VindicationPayment, VindicationPaymentAllocation,
    VindicationTableInfo,
    VindicationViewReceivable,
    VindicationViewCost, FieldFilter
} from "../api";
import {Dictionaries} from "../api";
import msgs, {getLangValue, useMsgs} from "./Language";
import type {DataTableColumnInstance} from "./DataTable";
import {ClientDataTable, dateCell} from "./DataTable";
import {
    addMoney,
    findOptionByValue,
    formatMoney, getOptionLabel,
    getOptionValue,
    identity,
    isArrayTwoOrMore,
    isNotEmptyArray,
    optionCompare,
    parseMoney,
    sumMoney
} from "./Utils";
import {formatDate, formatDateTime} from "./DateTime";
import {Badge, ProgressBar} from "react-bootstrap";
import BigNumber from "bignumber.js";
import {DaysAgo} from "./Components";
import {getDownloadLink} from "./Upload";
import {Countries} from "./Data";
import type {FilterFieldConfig} from "./Filter";
import FilterInput, {filterConfigMarkReadonly, filterOverride} from "./Filter";
import {FilterProps} from "react-table";

/**
 * Funkcja dla tabeli, która prezentuje informacje o wartości początkowej z rozbiciem na pola.
 */
export function debtInitialCell({ value, row }) {
    return <>
        <h1>{formatMoney(value, row.original.currency)}</h1>
        <span>
            {msgs.gui.labelCapital}: {formatMoney(row.original.initialCapital, row.original.currency, true)}
        </span> <span>
            {msgs.gui.labelInterests}: {formatMoney(row.original.initialInterests, row.original.currency, true)}
        </span> <span>
            {msgs.gui.labelCosts}: {formatMoney(row.original.initialCosts, row.original.currency, true)}
        </span>
    </>
}

/**
 * Funkcja dla tabeli, która wyświetla wartość pieniężną z założeniem, że value to wartość,
 * a w wierszu jest waluta w polu currency.
 */
export function moneyCell({ value, row }) {
    return formatMoney(value, row.original.currency);
}
/**
 * Komponent do wyświetlania statusu długu (ikonki + test).
 */
export const DebtStatus = ({ status, stage, variant, display, stageNames } : {
    status: OrderStatus, stage?: string;
    variant?: "small"|"default";
    display?: string|null;
    stageNames: Array<LangOption>;
}) => {
    const msgs=useMsgs();
    const sn="status"+status;
    if(!variant) variant="default";
    let res;
    if(variant==="default") res=<h2 className={"status status-default "+sn}>{display || msgs.gui[sn]}</h2>;
    else res=<h1 className={"status status-small "+sn}>{display || msgs.gui[sn]}</h1>;
    if(!stage) return res;
    const m=findOptionByValue(stageNames, stage);
    if(m) stage=getLangValue(m.label);
    return <>{res}<span className="stage">{stage}</span></>
}

/**
 * Pasek postępu do wyświetlania stanu odzyskiwania
 */
export const DebtProgress = ({ value, max, label, variant, className }: {
    value: number|string, max: number|string;
    label?: boolean|string|number;
    variant?: 'auto'|'success' | 'danger' | 'warning' | 'info';
    className?: string;
}) => {
    if(!className) className="";
    else className=" "+className;
    let res=0;
    const v = parseMoney(value);
    const m = parseMoney(max);
    if(v!==null && m!==null && m.toNumber()>0) {
        res = v.multipliedBy(100).dividedToIntegerBy(m).toNumber();
    }
    if(!variant || variant==='auto') {
        variant='success';  // TODO
    }
    if(label===true) {
        return <div className={"progress-bar-valued"+className}>
            <ProgressBar
                className="money-bar"
                now={res}
                variant={variant}
            />
            <div className="progress-bar-values">
                {res>4?<span className="min">0%</span>:null}
                {res<90?<span className="max">100%</span>:null}
                <span className="space" style={{ width: res+"%" }}/>
                <span className="now">{res+"%"}</span>
            </div>
        </div>
    } else if(typeof(label)==='string') {
        return <div className={"progress-bar-labeled"+className}>
            <div className="progress-bar-label">
                <label>{label}</label>
                <span>{res+"%"}</span>
            </div>
            <ProgressBar
                className="money-bar"
                now={res}
                variant={variant}
            />
        </div>
    } else if(label===1) {
        return <div className={"progress-bar-small"+className}>
            <ProgressBar
                className="money-bar"
                now={res}
                variant={variant}
            />
            <span>{res+"%"}</span>
        </div>
    }
    return <ProgressBar
        className={"money-bar"+className}
        now={res}
        label={label===true?res+"%":undefined}
        variant={variant}
    />
}

/**
 * Wyświetlający składniki długu (1101, 301)
 */
export const DebtStructure = ({ data, currency, className, initialSum }: {
    data: Array<VindicationViewReceivable>, currency: string;
    className?: string;
    initialSum?: boolean;
}) => {
    const msgs=useMsgs();
    const columns=useMemo<Array<DataTableColumnInstance<VindicationViewReceivable>>>(() => {
        return [
            {
                accessor: "type",
                className: "type",
                type: "string",
                Header: msgs.gui.labelType,
                Cell: ({ value }) => <Badge className="type-badge" variant="primary">{getLangValue(value)}</Badge>,
            }, {
                accessor: "number",
                className: "mw-md",
                type: "string",
                Header: msgs.gui.labelDocumentNr,
            }, {
                accessor: "initial",
                className: "money",
                type: "money",
                Header: msgs.gui.labelInitialValue,
                Cell: ({value}) => formatMoney(value, currency),
            }, {
                accessor: "sellDate",
                className: "date",
                type: "date",
                Header: msgs.gui.labelPublished,
                Cell: dateCell,
            }, {
                accessor: "paymentDate",
                className: "date",
                type: "date2",
                Header: msgs.gui.labelDueTo,
                Cell: ({ value }) => <>
                        {formatDate(value)}<br/>
                        <DaysAgo value={value}/>
                    </>,
            }, {
                accessor: "actualCapital",
                className: "money",
                type: "money",
                Header: msgs.gui.labelActualCapital,
                Cell: ({value}) => formatMoney(value, currency),
            }, {
                accessor: "recovered",
                className: "money",
                type: "money",
                Header: msgs.gui.labelRecovered,
                Cell: ({ value, row }) => <>
                    <h1>{formatMoney(value, currency)}</h1>
                    <DebtProgress value={value} max={sumMoney(row.original.initial, row.original.actualInterests, row.original.recoveredInterests)}/>
                </>
            }, {
                accessor: "actual",
                className: "money",
                type: "money",
                Header: msgs.gui.labelActualBalance,
                Cell: ({ value, row }) => <h1>{formatMoney(value, currency)}</h1>,
            }
        ]
    }, [msgs.gui.language ]);
    const sums = useMemo(() => {
        if(!Array.isArray(data) || data.length===0) return null;
        let initial=null, recovered=null, balance=null;
        data.forEach((d: VindicationViewReceivable) => {
            initial=addMoney(initial, d.initial);
            recovered=addMoney(recovered, d.recovered);
            balance=addMoney(balance, d.actual);
        })
        return [ recovered, balance, initial ];
    }, [data]);

    if(!Array.isArray(data)) return null;
    return <ClientDataTable
        className={className}
        columns={columns}
        data={data}
        summaryRow={!sums?null:<tr className="summary">
            <td colSpan={2}>{msgs.gui.labelTotal}</td>
            <td className="money">{initialSum?formatMoney(sums[2], currency):""}</td>
            <td colSpan={columns.length-5}/>
            <td className="money">{formatMoney(sums[0], currency)}</td>
            <td className="money">{formatMoney(sums[1], currency)}</td>
        </tr>}
    />
}

type SummaryRow = {
    type: string;
    capital: string;
    interests: string;
    costs: string;
    balance: string;
}

/**
 * Komponent podsumowanie dla długu
 */
export const DebtSummary = ({ value, className }: { value: VindicationDetails; className?: string; }) => {
    const msgs=useMsgs();
    const currency=value.currency;
    const columns=useMemo<Array<DataTableColumnInstance<SummaryRow>>>(() => {
        return [{
            accessor: "type",
            Header: "",
            type: "string",
        }, {
            accessor: "capital",
            Header: msgs.gui.labelSummaryCapital,
            className: "money-big",
            type: "money",
            Cell: ({ value }) => formatMoney(value, currency)
        }, {
            accessor: "interests",
            Header: msgs.gui.labelSummaryInterests,
            className: "money-big",
            type: "money",
            Cell: ({ value }) => formatMoney(value, currency)
        }, {
            accessor: "costs",
            Header: msgs.gui.labelSummaryCosts,
            className: "money-big",
            type: "money",
            Cell: ({ value }) => formatMoney(value, currency)
        }, {
            accessor: "balance",
            Header: msgs.gui.labelSummaryBalance,
            className: "money-big",
            type: "money",
            Cell: ({ value }) => formatMoney(value, currency)
        }];
    }, [msgs.gui.language, currency]);
    const data=useMemo<Array<SummaryRow>>(() => {
        return [
            {
                type: msgs.gui.labelSummaryInitial,
                capital: value.initialCapital,
                interests: value.initialInterests,
                costs: value.initialCosts,
                balance: value.initial,
            }, {
                type: msgs.gui.labelSummaryActual,
                capital: value.actualCapital,
                interests: value.actualInterests,
                costs: value.actualCosts,
                balance: value.actual,
            }
        ]
    }, [ msgs.gui.language, value ]);
    // https://pm.itpomoc.pl/issues/4757#change-34083
    // W przypadku odsetek trzeba by to chyba inaczej liczyć.
    // Rozumiem, że tutaj po prostu zostały naliczone odsetki i potem rozliczone wpłatą.
    // Propozycja może taka.
    //
    // Jeżeli wartość odzyskano dla odsetek > 0, to obliczamy procent jako odzyskano * 100% / (aktualna wartosc odsetek + odzyskano), czyli byłoby: (44,15*100%)/(44,15+0,09) = 99,79% (plus zaokrąglenie do pełnych jak widać).
    return <ClientDataTable
        className={className}
        columns={columns}
        data={data}
        summaryRow={<tr className="summary">
            <td>{msgs.gui.labelRecovered}</td>
            <td className="money-big">
                {formatMoney(value.recoveredCapital, currency)}
                <DebtProgress label={1} value={value.recoveredCapital} max={value.initialCapital}/>
            </td>
            <td className="money-big">
                {formatMoney(value.recoveredInterests, currency)}
                <DebtProgress label={1} value={value.recoveredInterests} max={sumMoney(value.recoveredInterests, data[1].interests)}/>
            </td>
            <td className="money-big">
                {formatMoney(value.recoveredCosts, currency)}
                <DebtProgress label={1} value={value.recoveredCosts} max={value.initialCosts}/>
            </td>
            <td className="money-big">
                {formatMoney(value.recovered, currency)}
                <DebtProgress label={1} value={value.recovered} max={sumMoney(value.initial, value.actualInterests, value.recoveredInterests)}/>
            </td>
        </tr>}
    />
}


/**
 * Komponent wyświetlający tabelę z długami
 */
export const DebtsTable = ({ data, className, onRowClick, globalDicts }: {
    data: Array<VindicationTableInfo>;
    className?: string;
    onRowClick?: (row: VindicationTableInfo) => void;
    globalDicts: Dictionaries;
}) => {
    const msgs=useMsgs();
    const columns=useMemo<Array<DataTableColumnInstance<VindicationTableInfo>>>(() => {
        return [{
            accessor: "fullId",
            Header: msgs.gui.labelDateAndNumber,
            className: "date",
            Cell: ({ row }) => <>
                {formatDate(row.original.date)}<br/>
                {row.original.fullId}
            </>,
        }, {
            accessor: "initial",
            Header: msgs.gui.labelInitialValue,
            Cell: debtInitialCell,
        }, {
            accessor: "changed",
            Header: msgs.gui.labelActivity,
            className: "date",
            Cell: dateCell
        }, {
            accessor: "status",
            Header: msgs.gui.labelStatusStage,
            className: "status",
            Cell: ({ row, value }) => <DebtStatus variant="small" status={value} stage={row.original.stage}  display={row.original.ecStatus} stageNames={globalDicts.stages}/>,
        }, {
            accessor: "recovered",
            Header: msgs.gui.labelRecovered,
            className: "money",
            Cell: ({ row, value }) => <>
                <h1>{formatMoney(value, row.original.currency, true)}</h1>
                <DebtProgress value={row.original.recovered} max={sumMoney(row.original.initial, row.original.actualInterests, row.original.recoveredInterests)} label={1}/>
            </>,
        }, {
            accessor: "actual",
            Header: msgs.gui.labelCurrentBalance,
            className: "money",
            Cell: ({ row, value }) => <h1>
                {formatMoney(value, row.original.currency, true)}
            </h1>
        }];
    }, [msgs.gui.labelInitialValue, globalDicts]);
    if(!Array.isArray(data)) return null;
    return <ClientDataTable onRowClick={onRowClick} columns={columns} data={data} className={className}/>;
}

/**
 * Komponent wyświetlający inne długi danego dłużnika.
 * Podobnie do DebtsTable, tylko mniej danych - dla giełdy długów!
 */
export const DebtsOtherTable = ({ className, data, ext, onRowClick, actionCell }: {
    data: Array<DebtExchangeInfo>;
    ext?: boolean;
    className?: string;
    onRowClick?: (row: DebtExchangeInfo) => void;
    actionCell?: DataTableColumnInstance<DebtExchangeInfo>;

}) => {
    const msgs = useMsgs();
    const columns = useMemo<Array<DataTableColumnInstance<DebtExchangeInfo>>>(() => {
        let res = [
            {
                accessor: "fullId",
                className: "id",
                type: "number",
                Header: msgs.gui.labelID,
            }];
        if (ext) res.push({
            accessor: "client",
            className: "half",
            type: "date",
            Header: msgs.gui.labelCustomer,
            Cell: ({ row }) => <>
                <h1>{row.original.client}</h1>
                <span>{row.original.clientFistName} {row.original.clientLastName}</span>
            </>
        });
        res.push({
                accessor: "value",
                Header: msgs.gui.labelInitialValue,
                Cell: ({value, row}) => formatMoney(value, row.original.currency)
            } , {
                accessor: "date",
                className: "date",
                Header: msgs.gui.labelPublished,
                Cell: dateCell,
            }, {
                accessor: "actualCapital",
                className: "money",
                type: "money",
                Header: msgs.gui.labelActualCapital,
                Cell: ({ row, value }) => <h1>
                    {formatMoney(value, row.original.currency, true)}
                </h1>
            }, {
                accessor: "recovered",
                className: "money",
                type: "money",
                Header: msgs.gui.labelRecovered,
                Cell: ({ row, value }) => <>
                    <h1>{formatMoney(value, row.original.currency, true)}</h1>
                    <DebtProgress value={value} max={sumMoney(row.original.initial, row.original.actualInterests, row.original.recoveredInterests)} label={1}/>
                </>,
            }, {
                accessor: "actual",
                className: "money",
                type: "money",
                Header: msgs.gui.labelActualBalance,
                Cell: ({ row, value }) => <h1>
                    {formatMoney(value, row.original.currency, true)}
                </h1>
            }
        );
        if(actionCell) res.push(actionCell);
        return res;
    }, [msgs.gui.labelCustomer, actionCell]);
    // const sums=useMemo(() => {
    //     if(!Array.isArray(data) || data.length===0) return null;
    //     let recovered=new BigNumber(0), balance=new BigNumber(0);
    //     data.forEach((v: VindicationTableInfo) => {
    //
    //     });
    //     return [ recovered, balance ];
    // }, [data]);
    if (!Array.isArray(data)) return null;
    return <ClientDataTable
        className={className}
        columns={columns}
        data={data}
        onRowClick={onRowClick}
    />;
}

/**
 * Komponent do wyświetlania alokacji środków we wpłacie.
 */
const PaymentAllocation = ({ allocations }: { allocations: Array<VindicationPaymentAllocation> }) => {
    return <table className="payment-alloc">
        <tbody>
        {allocations.map((a: VindicationPaymentAllocation, index) => (<tr key={index}>
            <td className="alloc-doc">{a.doc}</td>
            {/*<td className="alloc-value">{formatMoney(sumMoney(a.capital, a.costs, a.interest))}</td>*/}
            <td className="alloc-value">{formatMoney(a.capital)}</td>
            <td className="alloc-value">{formatMoney(a.costs)}</td>
            <td className="alloc-value">{formatMoney(a.interest)}</td>
        </tr>))}
        </tbody>
    </table>
}

function formatPaymentMessage({ value, row }): React$Node|null {
    const item: VindicationPayment = row.original;
    if(Array.isArray(item.allocations) && item.allocations.length>0) {
        if(!value) return <PaymentAllocation allocations={item.allocations}/>;

        return <>
            <p>{value}</p>
            <PaymentAllocation allocations={item.allocations}/>
        </>
    } else if(value) return <p>{value}</p>;
    else if(item.waiting) return <p>{msgs.gui.infoPaymentSend}</p>
    return null;
}

/**
 * Komponent wyświetlający wpłaty
 */
export const DebtsPaymentsTable = ({ data, className, currency }: {
    data: Array<VindicationPayment>;
    className?: string;
    currency: string;
}) => {
    const msgs=useMsgs();
    const columns=useMemo<Array<DataTableColumnInstance<VindicationPayment>>>(() => {
        return [{
            accessor: "date",
            className: "date",
            type: "date",
            Header: msgs.gui.labelDate,
            Cell: dateCell,
        }, {
            accessor: "amount",
            type: "money",
            className: "money",
            Header: msgs.gui.labelAmount,
            Cell: ({value}) => formatMoney(value, currency),
        }, {
            accessor: "message",
            type: "string",
            Header: msgs.gui.labelComments,
            Cell: formatPaymentMessage,
        }];
    }, [msgs.gui.language]);
    const sum:BigNumber=useMemo(() => {
        if(!Array.isArray(data) || data.length===0) return null;
        let res=new BigNumber(0);
        data.forEach((p: VindicationPayment) => res=addMoney(res, p.amount));

        return res;
    }, [ data ]);
    if(!Array.isArray(data)) return null;
    return <ClientDataTable
        className={className}
        columns={columns}
        data={data}
        summaryRow={!sum?null:<tr className="summary"><td colSpan={3}>
            <div className="lr-sum">
                <span>{msgs.gui.labelTotal}</span>
                <span>{formatMoney(sum, currency)}</span>
            </div>
        </td></tr>}
    />;
}

/**
 * Pomocniczy komponent, do wyświetlania wpłaty w postaci uproszczonej (nie tabela)
 */
export const PaymentEntry = ({ value, className, currency, children }: {
    value: VindicationPayment;
    currency: string;
    className?: string;
    children?: any;
}) => {
    return <div className={"payment-entry "+(className || "")}>
        <div className="left-right">
            <label>{formatDate(value.date)}</label>
            <div className="value">{formatMoney(value.amount, currency)}</div>
        </div>
        {!value.message?null:<div className="comment">
            {value.message}
        </div>}
        {children}
    </div>
}

/**
 * Komponent do wyświetlania informacji o wierzytelności w postaci wpisu (nie tabela)
 */
export const ReceivableEntry = ({ value, currency }: {
    value: VindicationViewReceivable,
    currency: string;
}) => {
    const msgs=useMsgs();
    return <div className="receivable-entry">
        <div className="left-right">
            <div>
                <label>{msgs.gui.labelActualBalance}</label>
                <div className="value">{formatMoney(value.actual, currency)}</div>
            </div>
            <div>
                <Badge className="type-badge" variant="primary">{getLangValue(value.type)}</Badge>
            </div>
        </div>
        <div className="left-right">
            <div>
                <label>{msgs.gui.labelPayDate}</label>
                <DaysAgo value={value.paymentDate}/>
                {/*<div>{formatDate(value.paymentDate)}</div>*/}
            </div>
            <div>
                <DebtProgress
                    label={msgs.gui.labelRecovered}
                    value={value.recovered}
                    max={value.initial}
                />
            </div>
        </div>
    </div>
}

export const DebtEventFile = ({ value }: { value: VindicationEvent }) => {
    if(!value ||  !value.fileId) return null;
    return <div className="file-info">
        <a href={getDownloadLink(value.fileId, value.fileName)}>{value.fileName}</a>
        <span className="file-date">{formatDateTime(value.fileDate)}</span>
    </div>;
}

/**
 * Funkcja zwraca konfigurację dla filtra windykacji.
 * Filtr działa w dwóch trybach: normalnych oraz praw.
 * W trybie praw część pół jest tekstowych (niesłownikowych).
 */
export const VindicationFilter = ( { dicts, globalDicts, rightsMode, insurer, preset, value,  ...props}: $Diff<FilterProps, { fields: any }> & {
    dicts: VindicationDictionaries, globalDicts: Dictionaries, rightsMode?: boolean;
    /** Filtry ustawione na starcie i tylko do odczytu */
    preset?: Array<FieldFilter>;
    /** Czy dla widoku ubezpieczyciela */
    insurer: ?boolean;
} ) => {
    const msgs = useMsgs();
    const fields = useMemo<Array<FilterFieldConfig>>(() => {
        let statuses;
        if(dicts && isNotEmptyArray(dicts.statuses)) statuses=dicts.statuses;
        else statuses=[];

        let res=[{
            permanent: true && !rightsMode,
            field: "status",
            label: msgs.gui.labelStatus,
            type: "select",
            className: "status",
            options: [
                {value: "Grace", label: msgs.gui.statusGrace},
                {value: "Ordered", label: msgs.gui.statusOrdered},
                {value: "InProgress", label: msgs.gui.statusInProgress},
                {value: "Rejected", label: msgs.gui.statusRejected},
                {value: "Done", label: msgs.gui.statusDone},
                ...statuses.map(s=> ({ value: s, label: " "+s })),
            ].sort(optionCompare)
        }];
        if(dicts && isNotEmptyArray(dicts.stages)) {
            const stageLabel = (v) => {
                if(v==='\n') return msgs.gui.stageDone; // specjalna opcja
                let r=findOptionByValue(globalDicts.stages, v);
                if(!r) return v;
                return getLangValue(r.label);
            }
            res.push({
                permanent: true,
                field: "stage",
                label: msgs.gui.labelStage,
                type: "select",
                className: "status",
                options:  [...dicts.stages, '\n' ],
                getValue: getOptionValue,
                getLabel: stageLabel,
            });
        }
        res.push({
            field: "fullId",
            label: msgs.gui.labelOrderId,
            type: "string",
        }, {
            field: "extId",
            label: msgs.gui.labelCustomDebtorId,
            type: "string",
        }, {
            field: "debtor",
            label: msgs.gui.labelDebtor,
            type: "string",
        }, {
            field: "tax",
            label: msgs.gui.labelDebtorNip,
            type: "string",
        }, {
            field: "added",
            label: msgs.gui.labelFileAdded,
            type: "date",
        }, {
            field: "changed",
            label: msgs.gui.labelActivityFilter,
            type: "activity"
        }, {
            field: "recovered",
            label: msgs.gui.labelRecovered,
            type: "money",
        });
        if(rightsMode && isArrayTwoOrMore(globalDicts.currencies)) {
            res.push({
                field: "currency",
                label: msgs.gui.labelDebtCurrency,
                type: "select",
                options: globalDicts.currencies,
                getValue: getOptionValue,
                getLabel: getOptionLabel,
            });
        } else if(dicts && isArrayTwoOrMore(dicts.currencies)) {
            res.push({
                field: "currency",
                label: msgs.gui.labelDebtCurrency,
                type: "select",
                options: dicts.currencies,
                getValue: identity,
                getLabel: identity,
            });
        }
        res.push({
            field: "initial",
            label: msgs.gui.labelInitialValue,
            type: "money",
        }, {
            field: "insured",
            label: msgs.gui.labelDebtInsured,
            type: "boolean",
        });
        if(insurer===true) {
            res.push({
                field: 'creditor',
                label: msgs.gui.labelCreditor,
                type: "string"
            })
        }
        if(rightsMode===true) { // w trybie praw to pole jest tekstowe
            res.push({
                field: "city",
                label: msgs.gui.labelCity,
                type: "string",
            });
        } else if(dicts && isArrayTwoOrMore(dicts.cities)) {
            res.push({
                field: "city",
                label: msgs.gui.labelCity,
                type: "select",
                options: dicts.cities
            });
        }
        if(rightsMode===true) { // w trybie praw to pole jest tekstowe
            res.push({
                field: "country",
                label: msgs.gui.labelCountry,
                type: "select",
                options: Countries.all,
                getValue: (v) => v.iso,
                getLabel: (v) => v.name,
            });
        } else if(dicts && isArrayTwoOrMore(dicts.countries)) {
            res.push({
                field: "country",
                label: msgs.gui.labelCountry,
                type: "select",
                options: dicts.countries,
                getValue: identity,
                getLabel: (v) => Countries.byCode.get(v).name,
            });
        }
        res.push({
            field: "caseNumber",
            label: msgs.gui.labelSignature,
            type: "string"
        });

        return filterConfigMarkReadonly(res, preset);
    }, [msgs.gui.language, dicts, globalDicts, rightsMode, preset ]);
    return <FilterInput fields={fields} value={filterOverride(value, preset)} {...props}/>
}

export const DisplayVindicationType= ({ value }: { value: { amicable: boolean, judicial: boolean } }) => {
    const msgs=useMsgs();
    if(!value) return null;
    if(value.amicable && value.judicial) return msgs.gui.nameVindicationAmicableJudicial;
    if(value.amicable) return msgs.gui.nameVindicationAmicable;
    if(value.judicial) return msgs.gui.nameVindicationJudicial;
    return "";
}

export const VindicationCosts = ({ costs, className }: { costs: Array<VindicationViewCost> }) => {
    const msgs=useMsgs();
    const columns=useMemo<Array<DataTableColumnInstance<VindicationViewCost>>>(() => {
        return [
            {
                accessor: "date",
                className: "date",
                type: "date",
                Header: msgs.gui.labelDate,
                Cell: dateCell,
            }, {
                accessor: "amount",
                className: "money",
                type: "money",
                Header: msgs.gui.labelAmount,
                Cell: moneyCell
            }, {
                accessor: "stage",
                className: "status",
                type: "string",
                Header: msgs.gui.labelStage,
            }, {
                accessor: "type",
                className: "mnd",
                type: "string",
                Header: msgs.gui.labelType,
            }, {
                accessor: "affectsBalance",
                className: "mmd",
                type: "string",
                Header: msgs.gui.labelCostAffectsBalance,
                Cell: ({ value }) => value?msgs.gui.labelYes:msgs.gui.labelNo,
            }, {
                accessor: "description",
                type: "string",
                Header: msgs.gui.labelDescription,
            }, {
                accessor: "invoiced",
                className: "mmd",
                type: "string",
                Header: msgs.gui.labelInvoiced,
                Cell: ({ value }) => value?msgs.gui.labelYes:msgs.gui.labelNo,
            }, {
                accessor: "invoice",
                className: "mmd",
                type: "string",
                Header: msgs.gui.labelInvoiceNote,
            }
        ]
    }, [msgs.gui.language ]);
    // if(!Array.isArray(costs) || costs.length===0) return null;

    return <ClientDataTable
        className={className}
        columns={columns}
        data={costs}
        initialSortBy="-date"
    />

}