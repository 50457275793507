//@flow
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import type {
    Dictionaries,
    LangOption,
    TableResult,
    VindicationDetails,
    VindicationDocument,
    VindicationEvent,
    VindicationFile,
    VindicationPayment,
    VindicationTableInfo,
    VindicationViewFile
} from "../api";
import type {FilterFieldConfig, FilterQuery} from "../lib/Filter";
import FilterInput, {emptyQuery, isEmptyQuery} from "../lib/Filter";
import {RouteComponentProps, useParams} from "react-router";
import {Events, store} from "../application";
import {
    BreadcrumbItem,
    DeleteButton,
    Dialog,
    dialogOpen,
    DisplayHTML, ExpandableText,
    ForEach, getDialogCloseCallback,
    Icon,
    Timeline,
    TimelineEntry
} from "../lib/Components";
import msgs, {formatString, LangContext, langLink, useMsgs} from "../lib/Language";
import {formatDate, formatDateTime, parseDate} from "../lib/DateTime";
import {differenceInCalendarDays} from "date-fns";
import {Alert, Breadcrumb, Button, Card, Col, InputGroup, Modal, ProgressBar, Row, Tab, Tabs} from "react-bootstrap";
import DesktopScreen from "./DesktopScreen";
import {formatMoney, formatTax, getLocationState, PropertyDelayer, replaceHistoryState, sumMoney} from "../lib/Utils";
import {
    DebtEventFile,
    DebtProgress,
    DebtsOtherTable,
    DebtsPaymentsTable,
    DebtsTable,
    DebtStatus,
    DebtStructure,
    DebtSummary,
    PaymentEntry,
    ReceivableEntry,
    VindicationCosts
} from "../lib/VindicationsView";
import {BForm} from "../lib/Form";
import type {UploadInfo} from "../lib/Upload";
import {
    DefaultDropzone,
    deleteUpload,
    getDownloadLink,
    getPreviewLink,
    getViewLink,
    hasPreview,
    mimeTypeToIcon,
    upload
} from "../lib/Upload";
import {useHistory} from "react-router-dom";
import type {AddVindicationAPI} from "../lib/AddVindication";
import {AddVindication, VindicationServerAPI} from "../lib/AddVindication";
import VindicationsScreen from "./VindicationsScreen";
import {Helmet} from "react-helmet";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';
import {FormikBag} from "formik";
import {AddInvoicePayment} from "../lib/AddInvoicePayment";


export type VindicationTab = 'summary'|'finances'|'history'|'files';

type VindicationState = {
    v: VindicationDetails;
    fileQuery: string;
    historyFilter: FilterQuery;
    tab: VindicationTab|null;
    filteredHistory: TableResult<VindicationEvent>|null;
    images: Array<VindicationViewFile>|null;
    globalDicts: Dictionaries|null;
    htmlView?: VindicationViewFile|null;
}

function getTab(location: Location): VindicationTab|null {
    if(location && location.hash && location.hash.length>1) {
        switch(location.hash){
            case "#summary": return "summary";
            case "#finances": return "finances";
            case "#history": return "history";
            case "#files": return "files";
        }
    }
    return "summary";
}

const HistoryFilter = ( props: $Diff<FilterInput, { fields: any }> ) => {
    const msgs=useMsgs();
    const [ eventTypes, setEventTypes ] = useState<Array<LangOption>>(store.tryGetEventTypes());
    const fields=useMemo<Array<FilterFieldConfig>>(() => [
        {
            field: "type",
            type: "select",
            label: msgs.gui.labelType,
            options: eventTypes,
            permanent: true,
        }, {
            field: "date",
            type: "date",
            label: msgs.gui.labelDate,
            permanent: true,
        }
    ], [ msgs.gui.language, eventTypes ]);
    useEffect(() => {
        if(eventTypes) return;
        store.getEventTypes().then(eventTypes => setEventTypes(eventTypes));
    }, []);
    if(!eventTypes) return null;
    return <FilterInput fields={fields} {...props}/>
}

const EpuViewer = ({ file, onHide }: {
    file: VindicationViewFile|null, onHide: () => void
} ) => {
    const [ content, setContent ] = useState<string|null>(null);
    const msgs = useMsgs();
    useEffect(() => {
        if(!file) {
            setContent(null);
            return;
        }
        const link=getDownloadLink(file.dbId, file.name);
        fetch(link).then(res => {
            res.text().then(content => {
                // TODO: Przetworznie
                setContent(content);
            });
        });
    }, [ file ] );
    // const handleDownload=useCallback(() => {
    //
    // }, [ file ]);
    if(!file || !content) return null;

    return <Modal onHide={onHide} backdrop="static" keyboard size="lg" show scrollable>
        <Modal.Header closeButton>
            <Modal.Title>{file && file.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {content && <div className="epu-content" dangerouslySetInnerHTML={{ __html: content }}/>}
        </Modal.Body>
        {file && <Modal.Footer>
            {msgs.gui.actionDownload} <Button variant="link" href={getDownloadLink(file.dbId, file.name)}>{file.name}</Button>
            <Button onClick={onHide}>{msgs.gui.buttonClose}</Button>
        </Modal.Footer>}
    </Modal>
}

const ImageViewer = ({ images }: { images: Array<VindicationViewFile>|null }) => {
    const msgs=useMsgs();
    const [ photoIndex, setPhotoIndex ] = useState(-1);
    useEffect(() => {
        setPhotoIndex(-1);
    }, [images]);

    if(!Array.isArray(images) || images.length===0) return null;
    let image, next, prev;
    if(photoIndex>=0) {
        image=images[photoIndex];
        if(images.length>1) {
            next=images[(photoIndex+images.length-1)%images.length]
            prev=images[(photoIndex+1)%images.length];
        }
    }

    return <div className="image-files">
        {images.map((f: VindicationViewFile, index: number) => <div
            key={f.dbId}
        >
            <a target="_blank" rel="noopener noreferrer"
               href={getDownloadLink(f.dbId, f.name)}
               onClick={(e) => {
                   e.preventDefault(); e.stopPropagation();
                   setPhotoIndex(index)
               }}
            >
                <div className="thumbnail">
                    <img alt={f.name} src={getPreviewLink(f.dbId, f.name)}/>
                </div>
                <div className="info">
                    <span className="name">{f.name}</span>
                    <span className="date">{formatDateTime(f.date)}</span>
                </div>
            </a>
        </div>)}
        {photoIndex>=0 && (<Lightbox
            mainSrc={getViewLink(image.dbId, image.name)}
            prevSrc={prev?getViewLink(prev.dbId, prev.name):undefined}
            nextSrc={next?getViewLink(next.dbId, next.name):undefined}

            mainSrcThumbnail={getPreviewLink(image.dbId, image.name)}
            prevSrcThumbnail={prev?getPreviewLink(prev.dbId, prev.name):undefined}
            nextSrcThumbnail={next?getPreviewLink(next.dbId, next.name):undefined}

            onCloseRequest={() => setPhotoIndex(-1)}
            onMovePrevRequest={() => setPhotoIndex((photoIndex+images.length-1)%images.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex+1)%images.length)}
            enableZoom={false}
            imageTitle={<>{image.name} <span className="date">({formatDateTime(image.date)})</span></>}
            toolbarButtons={[<button
                type="button" aria-label={msgs.gui.actionDownload}
                className="ril__toolbarItemChild ril__builtinButton fas"
                onClick={() => {
                    window.open(getDownloadLink(image.dbId, image.name), "_blank");
                }}
            ><Icon.Download/></button>]}
            nextLabel={msgs.gui.labelNextImage}
            prevLabel={msgs.gui.labelPrevImage}
            closeLabel={msgs.gui.buttonClose}
            imageLoadErrorMessage={msgs.gui.imageLoadError}
        />)}
    </div>
}

/**
 * Klasa dla ekranu widoku szczegółów windykacji - ekran 301.
 */
export class VindicationScreen extends React.Component<RouteComponentProps<{ id: string }>, VindicationState> {
    static url= "/debtcollection/:id";
    static link = (id: string, tab?: VindicationTab) => "/debtcollection/"+id+(tab?"#"+tab:"");

    debtExchange: boolean;
    historyDelayer: PropertyDelayer;

    constructor(props) {
        super(props);
        this.debtExchange=store.debtExchange && store.debtExchange.read;
        const tab=getLocationState(this.props.location, 'tab') || getTab(this.props.location);
        const filter=getLocationState(this.props.location, 'filter') || emptyQuery();
        this.state = {
            v: null,
            fileQuery: "",
            historyFilter: filter,
            tab: tab,
            filteredHistory: null,
            images: null,
            globalDicts: null,
            htmlView: null,
        }
        this.historyDelayer=new PropertyDelayer(this, this.setFilter, this.updateHistory);
    }

    setFilter = (filter: FilterQuery) => {
        this.setState({ historyFilter: filter });
        replaceHistoryState(this.props.history, this.props.location, 'filter', filter);
    }

    refresh() {
        store.userApi.getVindication(this.props.match.params.id).then((v: VindicationDetails) => {
            if(!v) {
                this.props.history.replace(langLink(DesktopScreen.url));
                return;
            }
            v.events.sort((e1: VindicationEvent, e2: VindicationEvent) => -e1.date.localeCompare(e2.date));
            v.payments.sort((e1: VindicationPayment, e2: VindicationPayment) => -e1.date.localeCompare(e2.date));
            v.files.sort((f1: VindicationViewFile, f2: VindicationViewFile) => -f1.date.localeCompare(f2.date));
            this.setState({
                v,
                images: v.files.filter(f => hasPreview(f.type))
            });
        })
    }

    updateHistory = () => {
        // console.log("Update history", this.state.historyFilter);
        if(isEmptyQuery(this.state.historyFilter)) {
            this.setState({ filteredHistory: null });   // wszystko
        } else {
            store.userApi.queryEvents({
                sort: '-date',
                from: 0,
                items: 1000,
                query: this.state.historyFilter.text,
                filters: [...this.state.historyFilter.fields, { field: 'vId', value: this.state.v.id } ],
            }).then(filteredHistory => this.setState({ filteredHistory }));
        }
    }


    componentDidUpdate(prevProps: $ReadOnly<RouteComponentProps<{ id: string }>>, prevState: $ReadOnly<VindicationState>, snapshot: any) {
        if(prevProps.location.hash!==this.props.location.hash) {
            console.log("Cash change");
            this.setState({ tab: getTab(this.props.location.hash) })
        }
        if(prevProps.match.params.id!==this.props.match.params.id) {
            console.log("View change");
            // Resetujemy stan, aby był jak dla nowego
            this.setState({
                fileQuery: null,
                historyFilter: emptyQuery(),
                tab: getTab(this.props.location),
                v: null,
                filteredHistory: null,
            }, () => this.refresh());
            window.scrollTo(0, 0);
        }
    }

    componentDidMount() {
        this.refresh();
        store.getDictionaries().then(globalDicts => this.setState({ globalDicts }));
    }

    componentWillUnmount() {
        this.historyDelayer.stop();
    }

    handleAddFiles(e: SyntheticEvent) {
        dialogOpen(this, VindicationAddFiles.link(this.state.v.id), () => this.refresh());
    }

    handleAddPayment(e: SyntheticEvent) {
        dialogOpen(this, VindicationAddPayment.link(this.state.v.id, this.state.v.currency), () => this.refresh());
    }

    handleSelectDebt = (row: VindicationTableInfo) => {
        this.props.history.push(langLink(VindicationScreen.link(row.id)));
    }

    handleDownloadAsPdf= () =>{
        store.userApi.generateReport(this.state.v.id).then(file => {
            if(file) {
                window.location.href=getDownloadLink(file.safeId, file.name);
            }
        })
    }

    handleRejectGrace= () => {
        store.userApi.rejectGraceVindication(this.state.v.id).then(() => {
            this.refresh();
        });
    }

    handleAcceptGrace= () => {
        store.userApi.acceptGraceVindication(this.state.v.id).then(() => {
            this.refresh();
        });
    }

    render() {
        if(!this.state.v) return null;
        if(!this.state.globalDicts) return null;
        const v:VindicationDetails=this.state.v;
        const edit=store.vindication.write;
        const title=formatString(msgs.gui.titleVindication, v.fullId);
        const changed=parseDate(v.stageChanged);
        const elapsedDays=differenceInCalendarDays(new Date(), changed);

        let files=v.files;
        if(this.state.fileQuery) {
            const fq=this.state.fileQuery.toLocaleLowerCase().trim();
            if(fq) files=v.files.filter((f: VindicationViewFile) => f.name.toLocaleLowerCase().includes(fq));
        }

        return <>
            <Breadcrumb>
                <BreadcrumbItem to={DesktopScreen.url}>{msgs.gui.titleSummary}</BreadcrumbItem>
                <BreadcrumbItem to={VindicationsScreen.url}>{msgs.gui.titleVindications}</BreadcrumbItem>
                <BreadcrumbItem active>{v.fullId}</BreadcrumbItem>
            </Breadcrumb>
            <Helmet>
                <title>{title} - Rozważna Firma</title>
            </Helmet>
            <Row className="pb-3">
                <Col md={8}>
                    <div className="page-header">
                        <h1>{title}</h1>
                    </div>
                    <h3 className="mb-4">{v.debtor}</h3>
                </Col>
                <Col md={4}>
                    <div className="page-header-spacing">
                        <Button variant="link" onClick={this.handleDownloadAsPdf}>
                            <span className="icon gradient-circle mr-2"><span className="icon-download2"/></span> {msgs.gui.labelNewDebtSummaryPDF}
                        </Button>
                        {(v.status!=="Done" && msgs.links.rateUs)?null:<a
                            className="btn btn-link"
                            href={formatString(msgs.links.rateUs,
                                v.ecId, encodeURIComponent(v.fullId),
                                store.user.userId, encodeURIComponent(store.user.firstname), encodeURIComponent(store.user.lastname), encodeURIComponent(store.user.name),
                                store.user.orgId, encodeURIComponent(store.user.org)
                            )}
                        >
                            <span className="icon gradient-circle mr-2"><span className="icon-happy"/></span> {msgs.links.rateUsLabel}
                        </a>}
                    </div>
                    {v.status==="Grace" && <div className="d-flex justify-content-between">
                        <Button
                            variant="link"
                            disabled={!edit}
                            onClick={this.handleRejectGrace}
                        >{msgs.gui.imVindicationCancel}</Button>
                        <Button
                            variant="primary"
                            disabled={!edit}
                            onClick={this.handleAcceptGrace}
                        >{msgs.gui.imVindicationAccept}</Button>
                    </div>}
                </Col>
            </Row>
            <Row>
                <Col md={8}>
                    <Card className="data basic shadow">
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <Row className="basic-header">
                                        <Col>
                                            <label>{msgs.gui.labelDebtor}</label>
                                            <h3>{v.debtor}</h3>
                                            {v.creditor && <h6 className="creditor">{v.creditor}</h6>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={5}>
                                            <label>{msgs.gui.labelNIP}</label>
                                            {formatTax(v.tax)}
                                        </Col>
                                        <Col md={7}>
                                            <label>{msgs.gui.labelAddress}</label>
                                            <span>{v.address}</span><br/><span>{v.postalCode} {v.city}</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6} className="col-splitter">
                                    <Row className="basic-header">
                                        <Col>
                                            <Alert className="text-uppercase">
                                                <DebtStatus status={v.status} stage={v.stage}  display={v.ecStatus} stageNames={this.state.globalDicts.stages}/>
                                            </Alert>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={5}>
                                            <label>{msgs.gui.labelOrdered}</label>
                                            {v.status==="Grace"?"":formatDate(v.added)}
                                        </Col>
                                        <Col md={7} className="text-right">
                                            <label>{msgs.gui.labelThisStageFrom}</label>
                                            {formatDate(v.stageChanged)} {(elapsedDays<0 || v.status==="Done")?null:<span className="elapsed">({elapsedDays===0?msgs.gui.labelToday:(elapsedDays+" "+(elapsedDays>1?msgs.gui.labelDays:msgs.gui.labelDay))})</span>}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="data card-secondary state">
                        <Card.Body className="d-flex flex-column">
                            {v.insurer?<Row className="mb-4">
                                <Col className="d-flex align-items-center insurer-info">
                                    <span className="icon in-circle"><Icon.Info/></span>
                                    <span className="insurer-label">{formatString(msgs.gui.labelInsuredIn, v.insurer)}</span>
                                </Col>
                            </Row>:null}
                            <Row>
                                <Col md={6}>
                                    <label>{msgs.gui.labelRecovered}</label>
                                    <h3>{formatMoney(v.recovered, v.currency)}</h3>
                                </Col>
                                <Col md={6}>
                                    <label>{msgs.gui.labelLeft}</label>
                                    <h3>{formatMoney(v.actual, v.currency)}</h3>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <DebtProgress value={v.recovered} max={sumMoney(v.initial, v.actualInterests, v.recoveredInterests)} label/>
                                </Col>
                            </Row>
                            <Row className="flex-grow-1"/>
                            <Row>
                                <Col md={4}>
                                    <label>{msgs.gui.labelCourtRefNumber}</label>
                                    {v.courtRefNumber}
                                </Col>
                                <Col md={4}>
                                    <label>{msgs.gui.labelBailiffRefNumber}</label>
                                    {v.bailiffRefNumber}
                                </Col>
                                <Col md={4}>
                                    <label>{msgs.gui.labelExecutiveApplicationDate}</label>
                                    {formatDate(v.executiveApplicationDate)}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Tabs className="mt-5" activeKey={this.state.tab} onSelect={(tab) => this.setState({ tab: tab })}>
                <Tab eventKey="summary" title={msgs.gui.tabSummary}>
                    <Row>
                        <Col md={9}>
                            <Timeline>
                                <ForEach
                                    value={v.events}
                                    limit={5}
                                    loadMore={true}
                                >{(e: VindicationEvent) => <TimelineEntry
                                    key={e.id}
                                    title={e.type}
                                    date={e.date}
                                >
                                    <DisplayHTML className="event-content" value={e.message}/>
                                    {/*<p>{e.message}</p>*/}
                                    <DebtEventFile value={e}/>
                                </TimelineEntry>
                                }</ForEach>
                            </Timeline>
                        </Col>
                        <Col md={3}>
                            <Card className="card-last-payments color2">
                                <Card.Body>
                                    <Card.Title><h4>{msgs.gui.titleLastPayments}</h4></Card.Title>
                                    <ForEach value={v.payments} limit={10} loadMore={false}
                                    >{(p: VindicationPayment, index) => <PaymentEntry
                                            key={index} value={p} currency={v.currency}
                                    />}</ForEach>
                                </Card.Body>
                            </Card>
                            <Card className="card-debt-structure color1">
                                <Card.Body>
                                    <Card.Title><h4>{msgs.gui.titleDebtStructure}</h4></Card.Title>
                                    {v.receivables.map((r: VindicationDocument, index) => <ReceivableEntry
                                        key={index} value={r} currency={v.currency}
                                    />)}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <h3 className="section">{msgs.gui.titleOtherDebtorVindications}</h3>
                    <DebtsTable data={v.vindications} onRowClick={this.handleSelectDebt} globalDicts={this.state.globalDicts}/>
                    {this.debtExchange?<>
                        <h3 className="section">{msgs.gui.titleOtherDebts}</h3>
                        <DebtsOtherTable
                            data={v.otherVindications}
                            onRowClick={(row) => {
                                this.props.history.push(langLink(row.id));
                            }}
                        />
                    </>:null}
                </Tab>
                <Tab eventKey="finances" title={msgs.gui.tabFinances}>
                    <DebtSummary value={v}/>
                    <h4 className="section">{msgs.gui.titlePayments}
                        <div className="float-right">
                            <Button
                                onClick={() => this.handleAddPayment()}
                                disabled={v.status !== "InProgress"}
                            >{msgs.gui.vindicationAddPayment}</Button>
                        </div>
                    </h4>
                    <DebtsPaymentsTable data={v.payments} currency={v.currency}/>
                    <h4 className="section">{msgs.gui.titleDebtStructure}</h4>
                    <DebtStructure data={v.receivables} currency={v.currency}/>
                    <h4 className="section">{msgs.gui.titleVindicationCosts}</h4>
                    <VindicationCosts costs={v.costs}/>
                </Tab>
                <Tab eventKey="history" title={msgs.gui.tabHistory}>
                <Card className="search">
                        <Card.Body>
                            <Card.Title><h3>{msgs.gui.titleSearch}</h3></Card.Title>
                            <HistoryFilter
                                value={this.state.historyFilter}
                                onSearchClick={this.historyDelayer.forcePending}
                                onChange={this.historyDelayer.update}/>
                        </Card.Body>
                    </Card>
                    <Timeline>
                        <ForEach
                            value={this.state.filteredHistory?this.state.filteredHistory.data:v.events}
                        >
                            {(e) => <TimelineEntry
                                key={e.id}
                                title={e.type}
                                date={e.date}
                            >
                                <DisplayHTML className="event-content" value={e.message}/>
                                {/*<p>{e.message}</p>*/}
                                <DebtEventFile value={e}/>
                            </TimelineEntry>}
                        </ForEach>
                    </Timeline>
                </Tab>
                <Tab eventKey="files" title={msgs.gui.tabFiles}>
                    <Card>
                        <Card.Body>
                            <Card.Title><h3>{msgs.gui.titleSearch}</h3></Card.Title>
                            <Row>
                                <Col md={9}>
                                    <BForm.Control
                                        type="text"
                                        placeholder={msgs.gui.hintFileSearch}
                                        value={this.state.fileQuery}
                                        onChange={(e) => this.setState({ fileQuery: e.target.value })}
                                    />
                                </Col>
                                <Col md={3}>
                                    <Button
                                        variant="success" size="lg"
                                        disabled={v.status==="Grace"}
                                        onClick={(e) => this.handleAddFiles(e)}
                                    >
                                        <Icon.Add/> {msgs.gui.actionAddFileOrPhoto}
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="files">
                        <Card.Body>
                            <Card.Title><h3>{msgs.gui.tabFiles}</h3></Card.Title>
                            <ImageViewer images={this.state.images}/>
                            <Row className="other-files">
                                {files.filter(f => !hasPreview(f.type)).map((f: VindicationViewFile) => <Col
                                    key={f.dbId}
                                    md={6}
                                ><div className="file-info">
                                    <a target="_blank"
                                       rel="noopener noreferrer"
                                       href={getDownloadLink(f.dbId, f.name)}
                                       onClick={(e) => {
                                           if(f.type==="text/html") {
                                               e.preventDefault();
                                               e.stopPropagation();
                                               this.setState({ htmlView: f });
                                               return;
                                           }
                                       }}
                                    >
                                        <span className="icon"><span className="icon-file-empty"/></span>
                                        <div className="info">
                                            <span className="name">{f.name}</span>
                                            <span className="date">{formatDateTime(f.date)}</span>
                                            {f.description?
                                                (f.description.length>290?
                                                    <ExpandableText text={f.description} className="desc" limit={290}/>
                                                    :
                                                    <span className="desc">{f.description}</span>
                                                )
                                                :null
                                            }
                                        </div>
                                    </a></div>
                                </Col>)}
                            </Row>
                        </Card.Body>
                    </Card>
                </Tab>
            </Tabs>
            <EpuViewer file={this.state.htmlView} onHide={() => this.setState({ htmlView: null })}/>
        </>;
    }
}
VindicationScreen.contextType=LangContext;
VindicationScreen.mainClassName="screen-debtcollection-view";

/**
 * Ekran dodawania windykacji dla zalogowanego użytkownika w oparciu o komponent AddVindication.
 */
export const VindicationNew=() => {
    const history=useHistory();
    const { api, extraProps, dialogProps }=useMemo<VindicationServerAPI>(() => {
        const props=VindicationNew.extraProps;
        VindicationNew.extraProps=null;
        const dialogProps=VindicationNew.dialogProps;
        VindicationNew.dialogProps=null;

        return {
            api: {
                getCountry: store.getCountry.bind(store),
                getDictionaries: store.getDictionaries.bind(store),
                getInsurers: store.getInsurers.bind(store),
                getCompanyInfo: store.publicApi.getCompanyInfo.bind(store.publicApi),
                // To dla trybu zalogowanego użytkownika
                getVindicationsForDebtor: store.userApi.getVindicationsForDebtor.bind(store.userApi),
                registerVindication: store.userApi.registerVindication.bind(store.userApi),
                generateReport: store.userApi.generateReport.bind(store.userApi),
                updateVindicationContacts: store.userApi.updateVindicationContacts.bind(store.userApi),
                registerMultipleVindications: store.userApi.registerMultipleVindications.bind(store.userApi),
                checkDocuments: store.userApi.checkDocuments.bind(store.userApi),
                // To dla trybu windykacja z rejestracją
                getRegisteredReport: null,
                updateRegisterVindicationContacts: null,
            },
            extraProps: {...props},
            dialogProps: {...dialogProps}
        }
    }, []);
    return <AddVindication
        displayInterestRate={store.user.country!=="PL"}
        logged
        service={store.vindication}
        global={store.globalVindication}
        maxSize={store.webSettings.uploadLimit}
        onCancel={() => history.goBack()}
        onSuccess={() => {
            history.goBack();
        }}
        viewLink={VindicationScreen.link}
        serverApi={api}
        eventName={Events.VindicationChange}
        {...extraProps}
    >{(api: AddVindicationAPI) => <Dialog
        title={api.title}
        cancelButton={!api.handleBack?null:api.backLabel || ((api.stage==="tax" || api.stage==="contact")?msgs.gui.buttonCancel:msgs.gui.buttonBack)}
        acceptButton={!api.handleNext?null:api.nextLabel || ((api.stage==="done" || api.stage==="multiple_done" || api.stage==="contact")?msgs.gui.actionFinish:msgs.gui.buttonNext)}
        onAccept={(e) => {
            if(api.stage==="done") history.goBack();
            else api.handleNext(e);
            return false;
        }}
        onCancel={(e) => {
            api.handleBack(e);
            return false;
        }}
        onHide={() => {
            if(api.handleClose) api.handleClose();
        }}
        acceptMessage={api.nextMessage}
        {...dialogProps}
    >{api.render()}</Dialog>
    }</AddVindication>
}
VindicationNew.url="/debtcollection_new";
VindicationNew.extraProps=null;
VindicationNew.dialogProps=null;

type FileInfo = {
    meta: VindicationFile;
    upload: UploadInfo;
}

type VindicationAddFilesState = {
    files: Array<FileInfo>;
}

export class VindicationAddFiles extends React.Component<RouteComponentProps<{ id: string }>, VindicationAddFilesState> {
    static url= "/debtcollection/:id/add_files";
    static link = (id: string) => "/debtcollection/"+id+"/add_files";

    static idGen=0;

    constructor(props) {
        super(props);
        this.state = {
            files: [],
        }
    }

    handleOnDrop = (files: FileList) => {
        for(let i=0;i<files.length;++i) {
            const f=files[i];
            upload(f, String("af_"+(++VindicationAddFiles.idGen)), (info: UploadInfo) => {
                if(info.state==="New") {
                    let vf: VindicationFile = {
                        dbId: null,
                        customName: "",
                    }
                    this.setState({
                        files: [...this.state.files, { upload: info, meta: vf } ],
                    });
                } else {
                    this.setState({
                        files: this.state.files.map((i: FileInfo) => {
                            if(i.upload.id===info.id) {
                                if(info.state==="Done") i.meta.dbId=info.result.id;
                                return { ...i, upload: info };
                            }
                            return i;
                        })
                    });

                }
            }).finally();
        }
    }

    renderFile(f: FileInfo, index: number) {
        return <BForm.Row key={f.upload.id}>
            <Col className="preview">
                <i className={f.upload.result?mimeTypeToIcon(f.upload.result.mimeType):"fas fa-spinner"}/>
            </Col>
            <Col>
                <BForm.Group>
                    <BForm.Label>{f.upload.filename}</BForm.Label>
                    <InputGroup>
                        <BForm.Control
                            type="text"
                            placeholder={msgs.gui.labelNameThisFile}
                            value={f.meta.customName}
                            onChange={(e) => {
                                f.meta.customName=e.target.value;
                                this.forceUpdate();
                            }}
                        />
                        <InputGroup.Append>
                            <DeleteButton
                                disabled={!f.upload.result}
                                onClick={() => {
                                    this.setState({
                                        files: this.state.files.filter((i: FileInfo, mi) => index!==mi),
                                    }, () => {
                                        deleteUpload(f.upload.result).finally();
                                    });
                                }}
                            />
                        </InputGroup.Append>
                    </InputGroup>
                </BForm.Group>
                <ProgressBar
                    variant={!f.upload.result?"primary":"success"}
                    now={f.upload.progress}
                    max={100}
                />
            </Col>
        </BForm.Row>;
    }

    render() {
        return <Dialog
            title={msgs.gui.actionAddFileOrPhoto}
            onCancel={() => {
            }}
            acceptButton={msgs.gui.buttonAdd}
            onAccept={() => {
                if(this.state.files.length===0) return true;
                let files=this.state.files.map((f: FileInfo) => f.meta);

                store.userApi.addVindicationFiles(this.props.match.params.id, files).then(res => {
                    this.props.history.goBack();
                });

                return false;
            }}
        >
            <DefaultDropzone
                onDrop={this.handleOnDrop}
                noClick={true}
                maxSize={store.webSettings.uploadLimit}
            />
            <div className="files w-100">
                {this.state.files.map((f: FileInfo, index) => this.renderFile(f, index))}
            </div>
        </Dialog>
    }

}
VindicationAddFiles.contextType=LangContext;

export const VindicationAddPayment = () => {
    const formikRef=useRef<FormikBag>();
    const msgs=useMsgs();
    const history=useHistory();
    const params=useParams();
    const [dictionaries, setDictionaries ]=useState<Dictionaries|null>();
    const [ processing, setProcessing ] = useState(false);
    useEffect(() => {
        (async () => setDictionaries(await store.getDictionaries()))();
    }, [ setDictionaries ]);
    const closeCallbackRef=useRef();
    if(!closeCallbackRef.current) closeCallbackRef.current=getDialogCloseCallback();
    if(!dictionaries) return null;

    return <Dialog
        title={msgs.gui.vindicationAddPayment}
        cancelButton={msgs.gui.buttonCancel}
        acceptButton={msgs.gui.vindicationAddPayment}
        acceptDisabled={processing}
        onAccept={() => {
            if(formikRef.current) {
                formikRef.current.handleSubmit();
                return false;
            }
        }}
    >
        <AddInvoicePayment
            id={params.id}
            formikRef={formikRef}
            currency={params.currency}
            currencies={dictionaries.currencies}
            onSubmitted={(done) => {
                if(!done) {
                    setProcessing(true);
                } else {
                    window.setTimeout(() => {
                        if (closeCallbackRef.current) window.setTimeout(closeCallbackRef.current, 600);
                        history.goBack();
                    }, 270);
                }
            }}
        />
    </Dialog>
}
VindicationAddPayment.url=[ "/debtcollection/:id/add_payment", "/debtcollection/:id/add_payment/:currency" ];
VindicationAddPayment.link=(id: string, currency?: string) => `/debtcollection/${id}/add_payment`+(currency?"/"+currency:"");