//@flow
import React, {useCallback, useEffect, useState} from 'react';
import {Accordion, Badge, Col, Dropdown, ListGroup, Row} from "react-bootstrap";
import DesktopScreen from "../user/DesktopScreen";
import MonitoredScreen, {AddMonitoredDialog, IMSIGDialog} from "../user/MonitoredScreen";
import {Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";
import {
    currentLang,
    DialogLink,
    getLangValue,
    LangContext,
    langEquals,
    langLink,
    LangLink,
    langPath,
    SwitchLangLink,
    useMsgs
} from "../lib/Language";
import {
    ActiveSwitch,
    contactUrl,
    dialogOpen,
    Footer,
    ForEach,
    Icon,
    NotificationEntry,
    RequestErrorDialog,
    staticPageUrl
} from "../lib/Components";
import {Events, store} from "../application";
import type {ApplicationSettings, BannerInfo, UserWebNotification} from "../api";
import {AccessRight, UserCompanyInfo} from "../api";
import ServicesScreen, { RegisterServiceDialog } from "./ServicesScreen";
import SettingsScreen, {AddCompanyUserDialog, ChangePasswordDialog, EditCompanyDialog, EditCompanyUserDialog} from "./SettingsScreen";
import ContactScreen from "./ContactScreen";
import StaticScreen from "./StaticScreen";
import Error404 from "./Error404";
import AddCompanyDialog from "./AddCompanyDialog";
import VindicationsScreen from "./VindicationsScreen";
import {VindicationAddFiles, VindicationAddPayment, VindicationNew, VindicationScreen} from "./ViewVindicationScreen";
import DifficultDebtsScreen from "./DifficultDebtsScreen";
import DebtExchange, {DebtExchangeBuyDialog, DebtExchangeView} from "./DebtExchange";
import {UserOrganizationConfig} from "../UserConfig";
import AppRouter from "./AppRouter";
import {useEventTrigger} from "../lib/Events";
import {RemoteHelpDialog} from "./RemoteHelpDialog";
import {getDisplayLink} from "../lib/Upload";
import ExternalScreen from "./ExternalScreen";
import InvoiceMonitoringDesktopScreen from "./InvoiceMonitoringDesktopScreen";
import InvoiceMonitoringClientsScreen from "./InvoiceMonitoringClientsScreen";
import InvoiceMonitoringInvoicesScreen, {InvoiceMonitoringImportDialog} from "./InvoiceMonitoringInvoicesScreen";
import InvoiceMonitoringNotificationsScreen from "./InvoiceMonitoringNotificationsScreen";
import InvoiceMonitoringSubscriptionScreen, {
    InvoiceMonitoringSubscriptionDoneScreen
} from "./InvoiceMonitoringSubscriptionScreen";
import InvoiceMonitoringSettingsScreen from "./InvoiceMonitoringSettingsScreen";
import InvoiceMonitoringClientScreen, {InvoiceMonitoringNewVindicationDialog} from "./InvoiceMonitoringClientScreen";
import InvoiceMonitoringSyncHistoryScreen from "./InvoiceMonitoringSyncHistoryScreen";
import InvoiceMonitoringSubscriptionHistoryScreen from "./InvoiceMonitoringSubscriptionHistoryScreen";
import InvoiceMonitoringVindications from "./InvoiceMonitoringVindications";

/**
 * Komponent, który zajmuje się wyświetlaniem oraz odświeżaniem baneru.
 */
class BannerImage extends React.Component<void, { banner: BannerInfo }> {
    timer: number|null;

    constructor() {
        super();
        this.state ={
            banner: null,
        }
    }

    getBanner() {
        store.publicApi.getBanner().then((banner: BannerInfo) => {
            if(banner===null) {
                if(this.state.banner===null) return;    // brak zmiany
            } else {
                if(this.state.banner!==null && langEquals(this.state.banner.img, banner.img) && langEquals(this.state.banner.name, banner.name)) return;    // brak zmian
            }
            this.setState({ banner });  // aktualizacja banera
        })
    }

    componentDidMount() {
        this.timer=window.setInterval(() => this.getBanner(), 15*60*1000);   // 15 minut;
        this.getBanner();
    }

    componentWillUnmount() {
        window.clearInterval(this.timer);
        this.timer=null;
    }

    render() {
        if(this.state.banner===null) return null;
        const b:BannerInfo = this.state.banner;
        return <div className="banner">
            <a
                className="banner-link"
                href={getLangValue(b.url)}
                target={b.blank?"_blank":undefined}
                title={getLangValue(b.name)}
            >
                <img
                    className="banner-image"
                    src={getDisplayLink(getLangValue(b.img), "banner.png")}
                    alt={getLangValue(b.name)}
                />
            </a>
        </div>;
    }
}
BannerImage.contextType=LangContext;

const UserMenu = ({ className }) => {
    const history=useHistory();
    const msgs=useMsgs();
    const location=useLocation();
    useEventTrigger(Events.UserSettings);

    const [visible, setVisible ]=useState(false);
    useEffect(() => {
        setVisible(false);
    }, [ location ]);

    const handleLogout=useCallback((e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        store.logout().then(() => {
            history.push(langLink("/"));
        });
    },[history]);
    const ui: ApplicationSettings=store.user;

    return <div className={"user-menu "+(className||"")}>
        <Dropdown
            className="dropdown-drawer"
            show={visible}
            onToggle={() => setVisible(!visible)}
        >
            <Dropdown.Toggle className="btn-no-transform">
                <div className="info">
                    <span className="name">{ui.name}</span>
                    {ui.orgId?<span className="company">{ui.org}</span>:null}
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="user-companies">
                <ListGroup variant="flush">
                    <ListGroup.Item className="actions">
                        <Row>
                            <Col className="d-flex justify-content-between">
                                {ui.orgId && store.hasRight("Admin")?<LangLink to={SettingsScreen.usersLink}>
                                    <span className="icon-gear"/> {msgs.gui.actionManageCompany}
                                </LangLink>:<span/>}
                                <LangLink to={SettingsScreen.mainLink}>
                                    <span className="icon-user-circle"/> {msgs.gui.labelMyAccount}
                                </LangLink>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                    {ui.organizations.map((i: UserCompanyInfo) => <ListGroup.Item key={i.id} className="user-company">{
                        (i.orgId===ui.orgId || !i.available)?<span className={!i.available?"disabled":undefined}>{i.name}</span>:<LangLink
                            to={"/select/"+i.orgId+"/"+encodeURIComponent(i.name)}  // to jest tylko dla widoku
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                store.userApi.selectCompany(i.id).then((settings: UserOrganizationConfig) => {
                                    console.log("WebSettings: ", settings);
                                    // TODO: Tu można wziąć ekran startowy z ustawień
                                    window.location.pathname="/";
                                    window.location.reload();
                                })
                            }}
                        >{i.name}</LangLink>
                    }</ListGroup.Item>)}
                    <ListGroup.Item className="actions no-border">
                        <DialogLink to={AddCompanyDialog.url}>
                            <span className="icon-add-outline"/> {msgs.gui.actionAddCompany}
                        </DialogLink>
                    </ListGroup.Item>
                    <ListGroup.Item className="actions no-border">
                        <Row>
                            <Col className="d-flex justify-content-between">
                                <SwitchLangLink>
                                    {msgs.gui.langChange}
                                </SwitchLangLink>
                                <LangLink to="/" onClick={handleLogout}>
                                    <span className="icon-log-out"/> {msgs.gui.actionLogout}
                                </LangLink>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </ListGroup>
            </Dropdown.Menu>
        </Dropdown>
    </div>;
}

const MonitoringMenu = ({ menu, setMenu }: {
    menu: string;
    setMenu: (value: string) => void;
}) => {
    const msgs=useMsgs();
    if (!store.invoiceMonitoring) return null;
    const write=store.invoiceMonitoring.write;
    const subscription=store.invoiceMonitoring.settings['im_subscription']===true;
    const read=write || store.invoiceMonitoring.read;
    if(!read && !write && !subscription) return null;   // brak uprawnień

    return <>
        <Accordion.Toggle eventKey="2" className={menu === "2" ? "expanded" : ""}>
            <span className="rf-icon rf-icon-invoice-monitoring"/> {msgs.links.invoiceMonitoringLabel}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="2">
            <div>
                {read && <LangLink to={InvoiceMonitoringDesktopScreen.link()}>{msgs.gui.menuInvoiceMonitoringDesktop}</LangLink>}
                {read && <LangLink to={InvoiceMonitoringClientsScreen.link()}>{msgs.gui.menuInvoiceMonitoringClients}</LangLink>}
                {read && <LangLink to={InvoiceMonitoringInvoicesScreen.link()}>{msgs.gui.menuInvoiceMonitoringInvoices}</LangLink>}
                {read && <LangLink to={InvoiceMonitoringVindications.link()}>{msgs.gui.menuInvoiceMonitoringVindications}</LangLink>}
                {read && <LangLink to={InvoiceMonitoringNotificationsScreen.link()}>{msgs.gui.menuInvoiceMonitoringNotifications}</LangLink>}
                {read && <LangLink to={InvoiceMonitoringSyncHistoryScreen.link()}>{msgs.gui.menuInvoiceMonitoringSyncHistory}</LangLink>}
                {subscription && <LangLink to={InvoiceMonitoringSubscriptionScreen.link()}>{msgs.gui.menuInvoiceMonitoringSubscription}</LangLink>}
                {/*{subscription && <LangLink to={InvoiceMonitoringSubscriptionHistoryScreen.link()}>{msgs.gui.menuInvoiceMonitoringSubscriptionHistory}</LangLink>}*/}
                {write && <LangLink to={InvoiceMonitoringSettingsScreen.link()}>{msgs.gui.menuInvoiceMonitoringSettings}</LangLink>}
            </div>
        </Accordion.Collapse>
    </>
}

/**
 * Punkt startowy dla aplikacji dostępnej po zalogowaniu
 */
const UserApp = () => {
    const [ menu, setMenu ] = useState(null);
    const [ expanded, setExpanded ] = useState(false);
    const [ notifications, setNotifications ] = useState<null|Array<UserWebNotification>>(null);
    const msgs=useMsgs();
    const location=useLocation();
    const history=useHistory();
    useEffect(() => {
        store.userApi.getNotifications().then(notifications => setNotifications(notifications));
    }, [ msgs.gui.language ]);

    const toggleMenu=useCallback(() => {
        if(expanded) document.body.classList.remove("menu-visible");
        else document.body.classList.add("menu-visible");
        setExpanded(!expanded);
    }, [ expanded ]);
    const ui: ApplicationSettings=store.user;

    useEffect(() => {
        if(!ui.orgId) return undefined;
        let checker=window.setInterval(() => {
            let last=null;
            if(Array.isArray(notifications) && notifications.length>0) {
                last=notifications[0].time;
            }
            store.userApi.getNewNotifications(last).then(newNotifications => {
                if(!Array.isArray(newNotifications) || newNotifications.length===0) return; // brak nowych
                console.log("Got "+newNotifications.length+" new notifications");
                if(last===null) setNotifications(newNotifications); // pierwsze powiadomienie
                else setNotifications([...newNotifications, ...notifications]); // uzupełniamy listę
            });
        }, 45*1000);
        return () => window.clearInterval(checker);
    }, [ notifications ]);

    let background = location.state ? location.state.background : null;
    const canAdd=(store.vindication && store.vindication.write) || (store.services && store.services.find((i: AccessRight) => i.write));

    return <>
        <nav id="main-navigation">
            <div className="overflow-y min-h-100">
                <div className="logo">
                    <LangLink to={DesktopScreen.url}>
                        <img src={`/resources/img/logo-color-${currentLang.code}.svg`} alt="RozważnaFirma"/>
                    </LangLink>
                </div>
                <Dropdown className="add-service dropdown-drawer dropdown-primary small-hide">
                    <Dropdown.Toggle block size="lg"
                                     className="btn-primary-dropdown btn-icon text-uppercase d-flex align-items-center"
                                     disabled={!canAdd}
                    >
                        <span className="icon">+</span>
                        <span className="flex-fill">{msgs.gui.actionAddService}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {store.vindication?<Dropdown.Item onClick={() => dialogOpen(history, location, VindicationNew.url)}>
                            {getLangValue(store.vindication.name)}
                        </Dropdown.Item>:null}
                        {store.services?store.services.map((i: AccessRight) => i.write?<Dropdown.Item
                            key={i.id}
                            onClick={() => {
                                if(!i.type) dialogOpen(history, location, RegisterServiceDialog.link(i));
                            }}
                        >{getLangValue(i.name)}</Dropdown.Item>:null):null}
                    </Dropdown.Menu>
                </Dropdown>
                <UserMenu className="full-hide"/>
                <Accordion onSelect={(active) => setMenu(active)}>
                    <LangLink to={DesktopScreen.url}>
                        <span className="rf-icon rf-icon-summary"/> {msgs.gui.titleSummary}
                    </LangLink>

                    <MonitoringMenu menu={menu} setMenu={setMenu}/>

                    {store.vindication?<LangLink to={VindicationsScreen.url}>
                        <span className="rf-icon rf-icon-vindications"/>  {msgs.gui.titleVindications}
                    </LangLink>:null}

                    {store.monitoring?<LangLink to={MonitoredScreen.url}>
                        <span className="rf-icon rf-icon-observation"/> {msgs.gui.titleMonitored}
                    </LangLink>:null}

                    {store.services?<><Accordion.Toggle eventKey="1" className={menu==="1"?"expanded":""}>
                        <span className="rf-icon rf-icon-services"/> {msgs.gui.titleServices}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                        <div>
                            {store.services.filter((i: AccessRight) => i.show[currentLang.code]).map((i: AccessRight) => <LangLink
                                to={ServicesScreen.link(i)}
                                key={i.id}
                            >{getLangValue(i.name)}</LangLink>)}
                        </div>
                    </Accordion.Collapse></>:null}
                    {store.debtExchange?<LangLink to={DebtExchange.url}>
                        <span className="rf-icon rf-icon-debt-exchange"/> {msgs.gui.titleDebtMarket}
                    </LangLink>:null}

                    {store.difficultDebts?<LangLink to={DifficultDebtsScreen.url}>
                        <span className="rf-icon rf-icon-difficult-debts"/> {msgs.gui.titleDifficultDebts}
                    </LangLink>:null}

                    {(msgs.links.knowledgeBase && store.user.viewKnowledgeMenu)?<a href={msgs.links.knowledgeBase} target="_blank" rel="noopener noreferrer">
                        <span className="rf-icon rf-icon-knowledge"/> {msgs.links.knowledgeBaseLabel}
                    </a>:null}
                    {(msgs.links.invoiceMonitoring && store.user.viewMonitoringMenu)?<LangLink to={ExternalScreen.link("invoice_monitoring")}>
                        <span className="rf-icon rf-icon-invoice-monitoring"/> {msgs.links.invoiceMonitoringLabel}
                    </LangLink>:null}
                    {/*{msgs.links.invoiceMonitoring?<a href={msgs.links.invoiceMonitoring} target="_blank" rel="noopener noreferrer">*/}
                    {/*    <span className="rf-icon rf-icon-invoice-monitoring"/> {msgs.links.invoiceMonitoringLabel}*/}
                    {/*</a>:null}*/}
                    {/*{store.user.orgId?<DialogLink to={RemoteHelpDialog.url}>{msgs.gui.titleRemoteHelp}</DialogLink>:null}*/}
                </Accordion>
                <BannerImage/>
            </div>
        </nav>
        <div id="main">
            <nav id="top-navigation">
                <div className="menu-toggle full-hide">
                    <button className={"hamburger hamburger--collapse"+(expanded?" is-active":"")}
                            type="button" aria-label="Menu" aria-controls="navigation"
                            aria-expanded={expanded?"true":"false"}
                            onClick={() => toggleMenu()}
                    >
                                <span className="hamburger-box">
                                  <span className="hamburger-inner"/>
                                </span>
                    </button>
                </div>
                <div className="logo full-hide">
                    <img src={`/resources/img/logo-color-${currentLang.code}.svg`} alt="RozważnaFirma"/>
                </div>
                <div className="technical-message small-hide" dangerouslySetInnerHTML={{ __html: getLangValue(store.user.technicalMessage) }}/>
                <UserMenu className="small-hide"/>
                <div className="small-hide">
                    <div className="vr"/>
                </div>
                <div className="notifications">
                    <Dropdown className="dropdown-drawer" alignRight>
                        <Dropdown.Toggle>
                            <img src="/resources/img/bell.png" srcSet="/resources/img/bell.png 1x, /resources/img/bell@2x.png 2x" alt="Dzwonek"/>
                            <Badge
                                pill
                                variant="danger"
                                className="invisible"
                            >0</Badge>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="user-notifications">
                            <ListGroup variant="flush">
                                {notifications === null ? null : <ForEach
                                    value={notifications}
                                    limit={10}
                                    emptyInfo={<ListGroup.Item className="actions">{msgs.gui.labelNoNotifications}</ListGroup.Item>}
                                    loadMore={false}
                                >
                                    {(n: UserWebNotification) => <ListGroup.Item key={n.uId}>
                                        <NotificationEntry value={n}/>
                                    </ListGroup.Item>}
                                </ForEach>
                                }
                            </ListGroup>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="help-icon">
                    <DialogLink to={RemoteHelpDialog.url} className="btn-icon">
                        <Icon.Help/>
                    </DialogLink>
                </div>
            </nav>
            <main id="content">
                {background ? <Switch>
                    <Route path={langPath(AddMonitoredDialog.url)} component={AddMonitoredDialog}/>
                    <Route path={langPath(ChangePasswordDialog.url)} component={ChangePasswordDialog}/>
                    <Route path={langPath(AddCompanyDialog.url)} component={AddCompanyDialog}/>
                    <Route path={langPath(RegisterServiceDialog.url)} exact component={RegisterServiceDialog}/>
                    <Route path={langPath(VindicationNew.url)} component={VindicationNew}/>
                    <Route path={langPath(DebtExchangeBuyDialog.url)} exact component={DebtExchangeBuyDialog}/>
                    <Route path={langPath(VindicationAddFiles.url)} exact component={VindicationAddFiles}/>
                    <Route path={langPath(VindicationAddPayment.url)} exact component={VindicationAddPayment}/>
                    <Route path={langPath(EditCompanyDialog.url)} exact component={EditCompanyDialog}/>
                    <Route path={langPath(EditCompanyUserDialog.url)} exact component={EditCompanyUserDialog}/>
                    <Route path={langPath(AddCompanyUserDialog.url)} exact component={AddCompanyUserDialog}/>
                    <Route path={langPath(RemoteHelpDialog.url)} exact component={RemoteHelpDialog}/>
                    <Route path={langPath(IMSIGDialog.url)} exact component={IMSIGDialog}/>
                    <Route path={langPath(InvoiceMonitoringImportDialog.url)} exact component={InvoiceMonitoringImportDialog}/>
                    {/*<Route path={langPath(InvoiceMonitoringNewVindicationDialog.url)} exact component={InvoiceMonitoringNewVindicationDialog}/>*/}
                </Switch>:null}
                <ActiveSwitch location={background ? background : location} elementId="content">
                    <Route path={langPath(VindicationsScreen.url)} exact component={VindicationsScreen}/>
                    <Route path={langPath(VindicationScreen.url)} exact component={VindicationScreen}/>
                    <Route path={langPath(MonitoredScreen.url)} component={MonitoredScreen}/>
                    <Route path={langPath(DebtExchange.url)} exact component={DebtExchange}/>
                    <Route path={langPath(DebtExchangeView.url)} exact component={DebtExchangeView}/>
                    <Route path={langPath(DifficultDebtsScreen.url)} component={DifficultDebtsScreen}/>
                    <Route path={langPath(VindicationsScreen.url)} exact component={VindicationsScreen}/>
                    <Route path={langPath(ServicesScreen.url)} exact component={ServicesScreen}/>
                    <Route path={langPath(SettingsScreen.urls)} component={SettingsScreen}/>
                    <Route path={langPath(DesktopScreen.url)} exact component={DesktopScreen}/>
                    <Route path={langPath(ExternalScreen.url)} exact component={ExternalScreen}/>
                    <Route path={langPath(InvoiceMonitoringDesktopScreen.url)} exact component={InvoiceMonitoringDesktopScreen}/>
                    <Route path={langPath(InvoiceMonitoringClientsScreen.url)} exact component={InvoiceMonitoringClientsScreen}/>
                    <Route path={langPath(InvoiceMonitoringClientScreen.url)} exact component={InvoiceMonitoringClientScreen}/>
                    <Route path={langPath(InvoiceMonitoringInvoicesScreen.url)} exact component={InvoiceMonitoringInvoicesScreen}/>
                    <Route path={langPath(InvoiceMonitoringVindications.url)} exact component={InvoiceMonitoringVindications}/>
                    <Route path={langPath(InvoiceMonitoringNotificationsScreen.url)} exact component={InvoiceMonitoringNotificationsScreen}/>
                    <Route path={langPath(InvoiceMonitoringSettingsScreen.url)} exact component={InvoiceMonitoringSettingsScreen}/>
                    <Route path={langPath(InvoiceMonitoringSubscriptionScreen.url)} exact component={InvoiceMonitoringSubscriptionScreen}/>
                    <Route path={langPath(InvoiceMonitoringSubscriptionDoneScreen.url)} exact component={InvoiceMonitoringSubscriptionDoneScreen}/>
                    <Route path={langPath(InvoiceMonitoringSubscriptionHistoryScreen.url)} exact component={InvoiceMonitoringSubscriptionHistoryScreen}/>
                    <Route path={langPath(InvoiceMonitoringSyncHistoryScreen.url)} exact component={InvoiceMonitoringSyncHistoryScreen}/>
                    <Route path={langPath(contactUrl)} component={ContactScreen}/>
                    <Route path={langPath(staticPageUrl)} component={StaticScreen}/>
                    <Route path={langPath("/link/")} component={AppRouter}/>
                    <Route path={langPath("/login")}><Redirect to={langLink(DesktopScreen.url)}/></Route>

                    <Route component={Error404}/>
                </ActiveSwitch>
            </main>
            <Footer/>
        </div>
        <RequestErrorDialog/>
    </>;
}

export default UserApp;