//@flow
import React, {useMemo} from 'react';
import {RouteComponentProps} from "react-router";
import {Alert, Badge, Breadcrumb, Button, Card, Col, InputGroup, Row} from "react-bootstrap";
import msgs, {getLangValue, LangContext, langLink, useMsgs} from "../lib/Language";
import {BreadcrumbItem, DaysAgo, Dialog, dialogOpen, Icon, IconAlert, PageHeader} from "../lib/Components";
import DesktopScreen from "./DesktopScreen";
import type {DataTableColumn, DataTableColumnInstance, DataTableProps} from "../lib/DataTable";
import DataTable, {ClientDataTable, dateCell} from "../lib/DataTable";
import type {
    DebtExchangeDetails,
    DebtExchangeInfo,
    DebtsSummary, Dictionaries,
    LangOption,
    VindicationTableOther,
    VindicationViewReceivable
} from "../api";
import {formatDate} from "../lib/DateTime";
import {Form} from "../lib/Form";
import {store} from "../application";
import type {FilterFieldConfig, FilterQuery} from "../lib/Filter";
import FilterInput, {emptyQuery} from "../lib/Filter";
import {DebtProgress, DebtsOtherTable} from "../lib/VindicationsView";
import {addMoney, formatMoney, formatTax, getLocationState, parseMoney, replaceHistoryState, sumMoney} from "../lib/Utils";
import {ServiceNotAvailable} from "../ClientComponents";
import {Redirect} from "react-router-dom";

type State = {
    filter: FilterQuery;
    summary: DebtsSummary|null;
    currencies: Array<LangOption>;
}

const DebtExchangeTable = ( props: $Diff<DataTableProps, { columns: any }>) => {
    const msgs=useMsgs();
    const columns=useMemo<Array<DataTableColumn<DebtExchangeInfo>>>(() => [
        {
            id: "row-no",
            className: "id",
            Header: msgs.gui.labelRowNumber,
            Cell: ({ row }) => (row.index+1),
        }, {
        //     accessor: "id",
        //     className: "id",
        //     Header: msgs.gui.labelID,
        // }, {
            accessor: "date",
            className: "date",
            Header: msgs.gui.labelPublished,
            Cell: dateCell,
        }, {
            accessor: "name",
            Header: msgs.gui.labelDebtor,
            Cell: ({ value, row }) => <>
                <h1>{value}</h1>
                <span>{row.original.tax}</span>
            </>,
        }, {
            accessor: "city",
            className: "md",
            Header: msgs.gui.labelCity,
        }, {
            accessor: "value",
            className: "money",
            Header: msgs.gui.labelSummaryBalance,
            Cell: ({ value, row }) => <h1>{formatMoney(value, row.original.currency)}</h1>,
        }, {
            accessor: "sellValue",
            className: "money",
            Header: msgs.gui.labelPrice,
            Cell: ({ value, row }) => value?<h1>{formatMoney(value, row.original.currency)}</h1>:msgs.gui.labelDebtExchangePriceNegotiate,
        }, {
            accessor: "risky",
            className: "md",
            Header: msgs.gui.labelRisky,
            Cell: ({ value }) => value?<Badge className="risk-badge" variant="danger">{msgs.gui.labelRisky}</Badge>:"",
        }
        ] , [msgs.gui.language]);
    return <DataTable columns={columns} {...props}/>;
}

const DebtExchangeFilter = ( props: $Diff<FilterInput, { fields: any } > & { currencies: Array<LangOption> } ) => {
    const msgs=useMsgs();
    const fields=useMemo<Array<FilterFieldConfig>>(() => [
        {
            field: "city",
            label: msgs.gui.labelCity,
            type: "string",
        }, {
            field: "date",
            type: "date",
            label: msgs.gui.labelPublished,
        }, {
            field: "risky",
            type: "boolean",
            label: msgs.gui.labelRisky
        }, {
            field: "value",
            type: "money",
            label: msgs.gui.labelActualBalance,
        }, {
            field: "sellValue",
            type: "money",
            label: msgs.gui.labelDebtSellPrice,
        }, {
            field: "currency",
            label: msgs.gui.labelDebtCurrency,
            type: "select",
            options: props.currencies,
            getValue: (i: LangOption) => i.value,
            getLabel: (i: LangOption) => getLangValue(i.label),
        }
    ], [msgs.gui.language, props.currencies]);
    return <FilterInput fields={fields} {...props}/>;
}

const DebtExchangeHeader = () => {
    const msgs=useMsgs();
    return <>
        <Breadcrumb>
            <BreadcrumbItem to={DesktopScreen.url}>{msgs.gui.titleSummary}</BreadcrumbItem>
            <BreadcrumbItem active>{msgs.gui.titleDebtMarket}</BreadcrumbItem>
        </Breadcrumb>
        <PageHeader title={msgs.gui.titleDebtMarket}>
        </PageHeader>
    </>
}

export default class DebtExchange extends React.Component<RouteComponentProps, State> {
    static url="/debt_exchange";

    constructor(props) {
        super(props);
        const filter=getLocationState(this.props.location, 'filter') || emptyQuery();
        this.state = {
            filter: filter,
            summary: null,
            currencies: null,
        }
    }

    componentDidMount() {
        if(!store.debtExchange || !store.debtExchange.read) return;
        store.getDictionaries().then((dicts: Dictionaries) => {
            this.setState({ currencies: dicts.currencies });
        });
        store.userApi.getDebtsSummary().then(summary => {
            if(!summary) {
                this.props.history.replace(langLink(DesktopScreen.url));
                return;
            }
            this.setState({ summary })
        });
    }

    handleRowClick= (row: DebtExchangeInfo) => {
        this.props.history.push(langLink(DebtExchangeView.link(row.id)));
    }

    render() {
        if(!store.debtExchange) return <Redirect to={langLink("/")}/>;
        if(!store.debtExchange.read) {
            return <>
                <DebtExchangeHeader/>
                <ServiceNotAvailable/>
            </>
        }
        if(!this.state.summary || !this.state.currencies) return null;
        return <>
            <DebtExchangeHeader/>
            <Card className="blue">
                <Card.Body>
                    <Row className="align-items-center">
                        <Col md={3}>
                            <label>{msgs.gui.labelAllDebts}</label>
                            {this.state.summary.totalDebtsCount}
                        </Col>
                        <Col md={3}>
                            <label>{msgs.gui.labelRiskDebts}</label>
                            {this.state.summary.riskyDebtsCount}
                        </Col>
                        <Col md={3} className="sep-border">
                            <label>{msgs.gui.labelAllDebtsSum}</label>
                            {formatMoney(this.state.summary.debtsSum, "PLN")}
                        </Col>
                        <Col md={3}>
                            {msgs.links.debtExchange?<a href={msgs.links.debtExchange} target="_blank" rel="noopener noreferrer">
                                {msgs.links.debtExchangeLabel}
                            </a>:null}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card className="mt-5 mb-5 search shadow">
                <Card.Body>
                    <Card.Title>
                        <h3>{msgs.gui.titleSearch}</h3>
                    </Card.Title>
                    <DebtExchangeFilter
                        value={this.state.filter}
                        currencies={this.state.currencies}
                        onChange={(filter) => {
                            this.setState({ filter })
                            replaceHistoryState(this.props.history, this.props.location, 'filter', filter);
                        }}
                        readonly={true}
                    />
                </Card.Body>
            </Card>
            <DebtExchangeTable
                data={store.userApi.getDebts}
                customFilters={this.state.filter}
                onRowClick={this.handleRowClick}
                historyState="table"
                loadMoreDisabled={store.user.demo}
                disableSort={store.user.demo}
            />

        </>;
    }
}
DebtExchange.contextType=LangContext;
DebtExchange.mainClassName="screen-debt-exchange";


/**
 * Komponent wyświetlający strukturę długu na giełdzie długów.
 * Komponent ten jest kopią komponentu z Windykacji, aczkolwiek ze względu na inne wartości (brak odsetek)
 * jest on trochę inny.
 */
const DebtExchangeDebtStructure = ({ data, currency, otherRecovered, otherCosts }: {
    data: Array<VindicationViewReceivable>, currency: string;
    otherRecovered: string; otherCosts: string;
}) => {
    const msgs=useMsgs();
    const columns=useMemo<Array<DataTableColumnInstance<VindicationViewReceivable>>>(() => {
        return [
            {
                accessor: "type",
                className: "type",
                type: "string",
                Header: msgs.gui.labelType,
                Cell: ({ value }) => <Badge className="type-badge" variant="primary">{getLangValue(value)}</Badge>,
            }, {
                accessor: "initial",
                className: "money",
                type: "money",
                Header: msgs.gui.labelInitialValue,
                Cell: ({row}) => formatMoney(sumMoney(row.original.initialCapital, row.original.initialInterests), currency),
            }, {
                accessor: "sellDate",
                className: "date",
                type: "date",
                Header: msgs.gui.labelPublished,
                Cell: dateCell,
            }, {
                accessor: "paymentDate",
                className: "date",
                type: "date2",
                Header: msgs.gui.labelDueTo,
                Cell: ({ value }) => <>
                    {formatDate(value)}<br/>
                    <DaysAgo value={value}/>
                </>,
            }, {
                accessor: "actualCapital",
                className: "money",
                type: "money",
                Header: msgs.gui.labelActualCapital,
                Cell: ({value}) => formatMoney(value, currency),
            }, {
                accessor: "recovered",
                className: "money",
                type: "money",
                Header: msgs.gui.labelRecovered,
                Cell: ({ value, row }) => <>
                    <h1>{formatMoney(sumMoney(row.original.recoveredCapital, row.original.recoveredInterests), currency)}</h1>
                    <DebtProgress value={value} max={sumMoney(row.original.initialCapital, row.original.initialInterests, row.original.actualInterests, row.original.recoveredInterests)}/>
                </>
            }, {
                accessor: "actual",
                className: "money",
                type: "money",
                Header: msgs.gui.labelActualBalance,
                Cell: ({ row }) => <h1>{formatMoney(sumMoney(row.original.actualCapital, row.original.actualInterests), currency)}</h1>,
            }
        ]
    }, [msgs.gui.language ]);
    const sums = useMemo(() => {
        if(!Array.isArray(data) || data.length===0) return null;
        let recovered=null, balance=null;
        data.forEach((d: VindicationViewReceivable) => {
            recovered=addMoney(recovered, sumMoney(d.recoveredCapital, d.recoveredInterests));
            balance=addMoney(balance, sumMoney(d.actualCapital, d.actualInterests));
        })
        return [ recovered, balance ];
    }, [data]);

    if(!Array.isArray(data)) return null;
    return <ClientDataTable
        className="footer-header-bg"
        columns={columns}
        data={data}
        summaryRow={!sums?null: [<tr className="summary" key="sum1">
            <td colSpan={columns.length-2}>{msgs.gui.labelTotalDebtExchange}</td>
            <td className="money">{formatMoney(sums[0], currency)}</td>
            <td className="money">{formatMoney(sums[1], currency)}</td>
        </tr>, <tr className="summary header-bg" key="sum2">
            <td colSpan={columns.length-2}>{msgs.gui.labelOtherCosts}</td>
            <td className="money">{formatMoney(otherRecovered, currency)}</td>
            <td className="money">{formatMoney(otherCosts, currency)}</td>
        </tr>]}
    />
}

type ViewState = {
    d: DebtExchangeDetails|null;
}

export class DebtExchangeView extends React.Component<RouteComponentProps<{ id: string }>, ViewState> {
    static url = "/debt_exchange/:id";
    static link = (id: string) => "/debt_exchange/" + id;

    constructor(props) {
        super(props);
        this.state = {
            d: null,
        }
    }

    refresh() {
        store.userApi.getDebt(this.props.match.params.id).then(d => {
            if(!d) {
                this.props.history.replace(langLink(DesktopScreen.url));
                return;
            }
            this.setState({d})
        });
    }

    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps: $ReadOnly<RouteComponentProps<{ id: string}>>, prevState: $ReadOnly<ViewState>, snapshot: any) {
        if(this.props.match.params.id!==prevProps.match.params.id) {
            this.setState({
                d: null
            }, () => {
                this.refresh();
            });
            window.scrollTo(0, 0);
        }
    }

    handleBuy(debt?: DebtExchangeInfo) {
        if(debt) {
            dialogOpen(this, DebtExchangeBuyDialog.link(debt.id));
        } else {
            dialogOpen(this, DebtExchangeBuyDialog.link(this.state.d.id));
        }
    }

    render() {
        if (!this.state.d) return null;
        const d = this.state.d;
        const recoveredPercent=parseMoney(d.debtorRecovered).multipliedBy(100).div(d.debtorTotal).toFixed(0);
        const CUR="PLN";

        return <>
            <Breadcrumb>
                <BreadcrumbItem to={DesktopScreen.url}>{msgs.gui.titleSummary}</BreadcrumbItem>
                <BreadcrumbItem to={DebtExchange.url}>{msgs.gui.titleDebtMarket}</BreadcrumbItem>
                <BreadcrumbItem active>{d.name}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={d.name}>
            </PageHeader>
            <Card className="blue">
                <Card.Body>
                    <Row className="align-items-center">
                        <Col md={9} className="sep-border-white">
                            <Row>
                                <Col md={4}>
                                    <label>{msgs.gui.labelDebtValue}</label>
                                    {formatMoney(d.value, d.currency)}
                                </Col>
                                <Col md={4}>
                                    <label>{msgs.gui.labelDebtSellPrice}</label>
                                    {d.sellValue?formatMoney(d.sellValue, d.currency):msgs.gui.labelDebtExchangePriceNegotiate}
                                </Col>
                                <Col md={4}>
                                    <label>{msgs.gui.labelExchangePublished}</label>
                                    {formatDate(d.date)}
                                </Col>
                            </Row>
                            <Row className="mt-5">
                                <Col md={2}>
                                    <label>{msgs.gui.labelDebtCapital}</label>
                                    {formatMoney(d.actualCapital, d.currency)}
                                </Col>
                                <Col md={2}>
                                    <label>{msgs.gui.labelDebtInterests}</label>
                                    {formatMoney(d.actualInterests, d.currency)}
                                </Col>
                                <Col md={4}>
                                    <label>{msgs.gui.labelDebtCosts}</label>
                                    {formatMoney(d.actualCosts, d.currency)}
                                </Col>
                                <Col md={4}>
                                    <label>{msgs.gui.labelDebtRecovered}</label>
                                    {formatMoney(d.recovered, d.currency)}
                                </Col>
                            </Row>
                        </Col>
                        <Col md={3} className="text-center">
                            <Button
                                variant="success"
                                onClick={() => this.handleBuy()}>{msgs.gui.actionBuyDebt}</Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <h3 className="mt-3 mb-3">{msgs.gui.titleDebtStructure}</h3>
            <DebtExchangeDebtStructure data={d.docs} currency={d.currency} otherRecovered={d.recoveredCosts} otherCosts={d.actualCosts}/>
            <Row>
                <Col md={6}>
                    <Card className="mt-5 shadow properties">
                        <Card.Body>
                            <Card.Title><h3>{msgs.gui.labelInformation}</h3></Card.Title>
                            <Row>
                                <Col sm={4} className="label">{msgs.gui.labelDebtor}</Col>
                                <Col sm={8} className="value">{d.name}</Col>
                            </Row>
                            <Row>
                                <Col sm={4} className="label">{msgs.gui.labelNIP}</Col>
                                <Col sm={8} className="value">{formatTax(d.tax)}</Col>
                            </Row>
                            <Row>
                                <Col sm={4} className="label">{msgs.gui.labelAddress}</Col>
                                <Col sm={8} className="value">
                                    {d.address}<br/>
                                    {d.postalCode} {d.city}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4} className="label">{msgs.gui.labelCommentsToDebt}</Col>
                                <Col sm={8} className="value">
                                    {d.comment}
                                    {d.risky?<Badge variant="danger">{msgs.gui.labelRisky}</Badge>:null}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="mt-5 shadow debtor-summary">
                        <Card.Body>
                            <Card.Title><h3>{msgs.gui.titleDebtorExperience}</h3></Card.Title>
                            <IconAlert className="debtor-claims" icon={<span className="icon icon-edit size-lg"/>} iconClassName="icon">
                                <div className="left-right">
                                    <div>
                                        <label className="d-b">{msgs.gui.labelDebtorProcedures}</label>
                                        <h4>{d.debtorClaims}</h4>
                                    </div>
                                    <div>
                                        <label>{msgs.gui.labelDebtorVindications}</label>: <b>{d.debtorAmicable}</b><br/>
                                        <label>{msgs.gui.labelDebtorJudicial}</label>: <b>{d.debtorJudicial}</b>
                                    </div>
                                </div>
                            </IconAlert>
                            <Row>
                                <Col md={6} className="summary-values">
                                    <div className="summary-total">
                                        <span className="icon icon-edit size-lg"/>
                                        <div className="debtor-main">
                                            <label>{msgs.gui.labelDebtorVindicationsSum}</label>
                                            <span>{formatMoney(d.debtorTotal, CUR)}</span>
                                        </div>
                                    </div>
                                    <div className="left-right">
                                        <label>{msgs.gui.labelDebtorMainValue}</label>
                                        <span>{formatMoney(d.debtorInitial, CUR)}</span>
                                    </div>
                                    <div className="left-right">
                                        <label>{msgs.gui.labelDebtorInterests}</label>
                                        <span>{formatMoney(d.debtorInterests, CUR)}</span>
                                    </div>
                                    <div className="left-right">
                                        <label>{msgs.gui.labelDebtorCosts}</label>
                                        <span>{formatMoney(d.debtorCosts, CUR)}</span>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <Alert variant="danger" className="debtor-recovered">
                                        <label>{msgs.gui.labelDebtorRecoveredSum}</label>
                                        <h1>{recoveredPercent}%</h1>
                                        <h3>{formatMoney(d.debtorRecovered, CUR)}</h3>
                                    </Alert>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <h3 className="section">{msgs.gui.labelOtherDebtsForSale}</h3>
            <DebtsOtherTable
                data={d.other}
                actionCell={{
                    id: "action",
                    className: "actions",
                    Cell: ({ row }) => <Button
                        variant="success"
                        onClick={(e) => {
                            e.preventDefault(); e.stopPropagation();
                            this.handleBuy(row.original);
                        }}
                    >{msgs.gui.actionBuy}</Button>
                }}
                onRowClick={(debt: VindicationTableOther) => {
                    this.props.history.push(langLink(DebtExchangeView.link(debt.id)));
                }}
            />
        </>
    }
}
DebtExchangeView.contextType=LangContext;
DebtExchangeView.mainClassName="screen-debt-exchange-view";

export class DebtExchangeBuyDialog extends React.Component<RouteComponentProps<{ id: string }>, { send: boolean }> {
    static url = "/debt_exchange/:id/buy";
    static link = (id: string) => "/debt_exchange/" + id + "/buy";

    constructor(props) {
        super(props);
        this.state = {
            send: false
        }
    }

    render() {
        return <Form
            initialValues={{
                price: "",
                message: "",
            }}
            validate={{
                price: {notEmpty: true,},
            }}
            onSubmit={async (values, helpers) => {
                let res = store.userApi.buyDebt(this.props.match.params.id,
                    values.price, values.message);
                if (!Form.setError(helpers, res)) {
                    this.setState({send: true});
                }
            }}
        >{(formik) => <Dialog
            title={this.state.send?msgs.gui.titleDebtOfferSend:msgs.gui.titleDebtBuy}
            acceptButton={this.state.send?msgs.gui.buttonClose:msgs.gui.actionBuyDebt}
            onAccept={() => {
                if (this.state.send) return true;
                formik.handleSubmit();
                return false;
            }}
            cancelButton={this.state.send?null:undefined}
        >
            {this.state.send ? <IconAlert
                icon={<Icon.Plane/>}
                iconClassName="icon"
            >{msgs.gui.hintDebtOfferSend}</IconAlert> : <><Form.RowGroup name="price">
                <Form.Label>{msgs.gui.labelDebtBuyPrice}</Form.Label>
                <InputGroup>
                    <Form.Number noError/>
                    <InputGroup.Append>
                        <InputGroup.Text>{msgs.gui.labelDebtPercentage}</InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>
                <Form.Error/>
            </Form.RowGroup>
                <Form.RowGroup name="message">
                    <Form.Label>{msgs.gui.labelComments}</Form.Label>
                    <Form.TextArea/>
                </Form.RowGroup>
            </>}</Dialog>}</Form>
    }
}