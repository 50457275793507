//@flow
import React, {Ref, useCallback, useEffect, useMemo, useState} from 'react';
import {store} from "../application";
import {Breadcrumb, Button, Card, Dropdown} from "react-bootstrap";
import msgs, {LangContext, langLink, useMsgs} from "../lib/Language";
import {BreadcrumbItem, dialogOpen, IconAlert, InlineDialog, PageHeader, waitGlass} from "../lib/Components";
import {RouteComponentProps} from "react-router";
import type {
    AccessRight,
    Dictionaries,
    FileInfo,
    TableQuery,
    VindicationDictionaries,
    VindicationTableInfo
} from "../api";
import type {DataTableColumn, DataTableColumnInstance, DataTableProps} from "../lib/DataTable";
import DataTable, {dateCell} from "../lib/DataTable";
import {formatDate} from "../lib/DateTime";
import {formatMoney, getLocationState, PropertyDelayer, replaceHistoryState, sumMoney} from "../lib/Utils";
import DesktopScreen from "./DesktopScreen";
import type {FilterQuery} from "../lib/Filter";
import {cloneFilter, emptyQuery} from "../lib/Filter";
import {StoredFilter} from "../UserConfig";
import {VindicationNew, VindicationScreen} from "./ViewVindicationScreen";
import {BForm} from "../lib/Form";
import {AddVindication} from "../lib/AddVindication";
import {debtInitialCell, DebtProgress, DebtStatus, VindicationFilter} from "../lib/VindicationsView";
import {getDownloadLink} from "../lib/Upload";
import {ServiceNotAvailable} from "../ClientComponents";
import {Redirect, useHistory} from "react-router-dom";

export const VindicationsTable = ( props: $Diff<DataTableProps, { columns: any } > & { hideStatus?: boolean } ) => {
    const msgs=useMsgs();
    const [ globalDicts, setGlobalDicts ] = useState<Dictionaries|null>(null);
    const history=useHistory();
    const onRowClick=useCallback((v: VindicationTableInfo) => {
        if(props.onRowClick) return props.onRowClick(v);
        history.push(langLink(VindicationScreen.link(v.id)));
    }, [ props.onRowClick, history ]);

    const columns=useMemo<Array<DataTableColumnInstance<VindicationTableInfo>>>(() => {
        if(!globalDicts) return null;
        let columns: Array<DataTableColumn<VindicationTableInfo> >= [
            {
                accessor: "added",
                Header: msgs.gui.labelDateAndNumber,
                className: "date text-break",
                Cell: ({ row }) => <>
                    {formatDate(row.original.added)}<br/>
                    <h1>{row.original.fullId}</h1>
                </>,
            }, {
            accessor: "debtor",
            Header: msgs.gui.labelDebtorWithId,
            Cell: ({ row }) => <>
                <h1>{row.original.debtor}</h1>
                {row.original.extId}
                {row.original.creditor && <span className="creditor">{row.original.creditor}</span>}
            </>,
        }, {
            accessor: "initial",
            Header: msgs.gui.labelInitialValue,
            className: "md",
            Cell: debtInitialCell
        }, {
            accessor: "changed",
            Header: msgs.gui.labelActivity,
            className: "date",
            Cell: dateCell,
        }];
        if(props.hideStatus!==true) columns.push({
            accessor: "status",
            Header: msgs.gui.labelStatusStage,
            className: "status text-uppercase",
            Cell: ({ row, value }) => <DebtStatus variant="small" status={value} stage={row.original.stage} display={row.original.ecStatus} stageNames={globalDicts.stages}/>,
        });
        columns.push({
            accessor: "recovered",
            Header: msgs.gui.labelRecovered,
            className: "money",
            Cell: ({ row, value }) => <>
                <h1>{formatMoney(value, row.original.currency, true)}</h1>
                <DebtProgress value={row.original.recovered} max={sumMoney(row.original.initial, row.original.actualInterests, row.original.recoveredInterests)} label={1}/>
            </>,
        }, {
            accessor: "actual",
            Header: msgs.gui.labelCurrentBalance,
            className: "money",
            Cell: ({ row, value }) => <h1>{formatMoney(value, row.original.currency)}</h1>
        });
        return columns;
    }, [ msgs.gui.language, globalDicts, props.hideStatus ]);
    useEffect(() => {
        store.getDictionaries().then(d => setGlobalDicts(d));
    }, [])
    if(!globalDicts) return null;
    return <DataTable columns={columns} {...props} onRowClick={onRowClick}/>
}

type State = {
    filters: Array<StoredFilter>;
    dataTimestamp: number;
    /** Aktualny filtr */
    filter: FilterQuery;
    /** Filtr tabeli - opóźniony */
    tableFilter: FilterQuery;
    /** Filtr do usunięcia */
    toDelete: number|null;
    /** Czy okno zapisania widoczne */
    saveDialog: boolean;
    /** Czy są jakieś windykacje */
    hasAnyVindications: boolean|null;
    /** Słowniki dla windykacji (dla filtrów) */
    dicts: VindicationDictionaries|null;
    globalDicts: Dictionaries|null;
}

const VindicationsHeader = ({ children, ...props }) => {
    const msgs=useMsgs();
    return <>
        <Breadcrumb>
            <BreadcrumbItem to={DesktopScreen.url}>{msgs.gui.titleSummary}</BreadcrumbItem>
            <BreadcrumbItem active>{msgs.gui.titleVindications}</BreadcrumbItem>
        </Breadcrumb>
        <PageHeader title={msgs.gui.titleVindications} {...props}>{children}</PageHeader>
    </>
}

/**
 * Klasa dla ekranu widoku listy windykacji - ekran 300.
 */
export default class VindicationsScreen extends React.Component<RouteComponentProps, State> {
    static url = "/debtcollection";

    edit: boolean;
    delayer: PropertyDelayer<FilterQuery>;
    queryRef: Ref<TableQuery>;
    /**
     * Narzucone ograniczenia na filtrowanie z poziomu uprawnień użytkownika. Przykład:\
     * `[ { "field": "status", "value": null }, { "field": "stage", "value": null } ]`
     * @type {Array<AccessRight>|null}
     */
    filterLimit: Array<AccessRight>|null;

    constructor(props) {
        super(props);
        this.edit = store.vindication && store.vindication.write;
        this.filterLimit=store.vindication.filter;
        const filter=getLocationState(this.props.location, 'filter') || emptyQuery();
        this.state = {
            filters: store.user.settings.filters,
            dataTimestamp: Date.now(),
            filter: filter,
            tableFilter: filter,
            toDelete: null,
            saveDialog: false,
            hasAnyVindications: null,
            dicts: null,
            globalDicts: null,
        }
        this.queryRef=React.createRef(null);
        this.delayer=new PropertyDelayer<FilterQuery>(this, this.setFilter, 'tableFilter');
    }

    setFilter = (filter: FilterQuery) => {
        this.setState({ filter: filter });
        replaceHistoryState(this.props.history, this.props.location, 'filter', filter);
    }

    componentDidMount() {
        if(!store.vindication || !store.vindication.read) return;

        store.userApi.hasAnyVindications().then(hasAnyVindications => this.setState({hasAnyVindications}));
        store.userApi.getVindicationDictionaries().then((dicts) => this.setState({ dicts }))
        store.getDictionaries().then(globalDicts => this.setState({ globalDicts }));
        AddVindication.background=this.refresh;
    }

    componentWillUnmount() {
        this.delayer.stop();
        AddVindication.background=null;
    }


    refresh = () => {
        if(!store.vindication || !store.vindication.read) return;
        if(!this.state.hasAnyVindications) {
            store.userApi.hasAnyVindications().then(hasAnyVindications => this.setState({hasAnyVindications, dataTimestamp: Date.now() }));
            return;
        }
        this.setState({ dataTimestamp: Date.now() });
    }


    onAdd(e: MouseEvent) {
        dialogOpen(this, VindicationNew.url);
    }

    onSelectFilter(e: MouseEvent, f: StoredFilter) {
        e.preventDefault(); e.stopPropagation();
        // console.log("Select filter: ", f);
        this.delayer.update(cloneFilter(f.filter), true);
    }

    handleSaveFilterClick = (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ saveDialog: true });
    }

    handleSaveFilter = (name: string) => {
        // console.log("Save: ", name, this.state.filter);
        const filters=[...store.user.settings.filters, {
            filter: cloneFilter(this.state.filter),
            name: name,
        }];
        store.updateSettings({ filters }).then((us) => {
            this.setState({
                saveDialog: false,
                filters: [...us.filters],
            });
        })
    }

    handleExportResults = (e: SyntheticEvent) => {
        console.log("Export results: ", this.queryRef.current);
        if(!this.queryRef.current) return null;

        store.userApi.exportVindications(this.queryRef.current).then((file: FileInfo) => {
            if(file) {
                window.location.href=getDownloadLink(file.safeId, file.name);
            }
        }).finally(waitGlass());
    }

    handleDeleteFilterClick = (e: SyntheticEvent, f: StoredFilter, index: number) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            toDelete: index,
        })
    }

    handleDeleteFilter = () => {
        const index=this.state.toDelete;
        const f=this.state.filters[index];
        // console.log("Delete filter: ", f);
        const filters=store.user.settings.filters.filter((item, i) => i!==index);

        store.updateSettings({ filters }).then((ui) => {
            this.setState({
                toDelete: null,
                filters: [...ui.filters],
            });
        })
    }

    handleRowClick=(v: VindicationTableInfo) => {
        this.props.history.push(langLink(VindicationScreen.link(v.id)));
    }

    renderEmptyScreen() {
        return <>
            <VindicationsHeader custom/>
            <Card>
                <Card.Body className="text-center">
                    <img src="/resources/img/empty/vindication.png" alt="" className="d-block m-auto img-fluid pt-5 pb-3"/>
                    <h1>{msgs.gui.titleNoVindications}</h1>
                    <h4 className="mt-3 mb-5">{msgs.gui.hintNoVindications}</h4>
                    <Button
                        size="xl" className="btn-icon mb-5 mx-auto" variant="success"
                        onClick={(e) => this.onAdd(e)}
                    ><span className="outline-round"><div>+</div></span> {msgs.gui.actionNewVindication}</Button>
                </Card.Body>
            </Card>
        </>;
    }

    render() {
        if(!store.vindication) return <Redirect to={langLink("/")}/>;
        if(!store.vindication.read) {
            return <>
                <VindicationsHeader/>
                <ServiceNotAvailable/>
            </>
        }

        if(this.state.hasAnyVindications===null) return null;
        if(!this.state.hasAnyVindications) return this.renderEmptyScreen();
        if(!this.state.dicts || !this.state.globalDicts) return null;

        return <>
            <VindicationsHeader custom>
                <h1 className="flex-grow-0">{msgs.gui.titleVindications}</h1>
                <div>
                    <Dropdown id="filter-template" className="filter-template dropdown-drawer">
                        <Dropdown.Toggle variant="info" className="">
                            <span className="icon gradient-circle mr-3"><span className="icon-list2"/></span>
                            {msgs.gui.labelMyTemplates}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {this.state.filters.map((o: StoredFilter, index) => <Dropdown.Item
                                key={index}
                                href={"#"+o.name}
                                onClick={(e) => this.onSelectFilter(e, o)}
                            >
                                <span>{o.name}</span><span className="delete" onClick={(e) => this.handleDeleteFilterClick(e, o, index)}>X</span>
                            </Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="flex-grow-1"/>
                <Button
                    size="xl" className="btn-icon" disabled={!this.edit}
                    onClick={(e) => this.onAdd(e)}
                >{msgs.gui.actionNewVindication}</Button>
            </VindicationsHeader>
            <Card className="shadow">
                <Card.Body>
                    <div className="card-title actions-title">
                        <h2>{msgs.gui.titleSearch}</h2>
                        <Button variant="link" disabled={store.user.demo} onClick={this.handleSaveFilterClick}>
                            <span className="icon gradient-circle mr-2"><span className="icon-floppy-disk"/></span> {msgs.gui.actionSaveTemplate}
                        </Button>
                        <Button variant="link" onClick={this.handleExportResults}>
                            <span className="icon gradient-circle mr-2"><span className="icon-download2"/></span> {msgs.gui.actionExportResults}
                        </Button>
                    </div>
                    <VindicationFilter
                        globalDicts={this.state.globalDicts}
                        dicts={this.state.dicts}
                        value={this.state.filter}
                        insurer={store.user.orgInsurer===true}
                        onSearchClick={this.delayer.forcePending}
                        onChange={this.delayer.update}
                        preset={store.vindication.filter}
                    />
                </Card.Body>
            </Card>
            <DeleteFilterDialog
                visible={typeof(this.state.toDelete)==='number'}
                onCancel={() => this.setState({ toDelete: null })}
                onDelete={this.handleDeleteFilter}
            />
            <SaveFilterDialog
                visible={this.state.saveDialog}
                onCancel={() => this.setState({ saveDialog: false })}
                onSave={this.handleSaveFilter}
            />
            <h3 className="mt-3 mb-4">{msgs.gui.titleYourVindications}</h3>
            <VindicationsTable
                data={store.userApi.queryVindications}
                dataTimestamp={this.state.dataTimestamp}
                onRowClick={this.handleRowClick}
                customFilters={this.state.tableFilter}
                delayFilter={null}
                queryRef={this.queryRef}
                historyState="table"
            />
        </>;
    }
}
VindicationsScreen.contextType=LangContext;
VindicationsScreen.mainClassName="screen-debtcollection-list";

const SaveFilterDialog = ({ visible, loaded, onCancel, onSave }: {
    visible: boolean;
    loaded?: StoredFilter;
    onCancel: () => void;
    onSave: (name: string) => void;
}) => {
    const [ name, setName ] = useState("");
    const msgs=useMsgs();
    useEffect(() => setName(""), [ visible ]);
    if(!visible) return null;
    return (
        <InlineDialog
            title={msgs.gui.titleFilterSave}
            cancelButton={msgs.gui.buttonCancel}
            onCancel={onCancel}
            acceptButton={msgs.gui.actionSave}
            onAccept={name.length===0?true:() =>onSave(name)}
        >
            <IconAlert>{msgs.gui.hintFilterSave}</IconAlert>
            <BForm.Group>
                <BForm.Label>{msgs.gui.labelTemplateName}</BForm.Label>
                <BForm.Control
                    value={name}
                    onChange={e => setName(e.target.value )}
                />
            </BForm.Group>
        </InlineDialog>
    );
}

const DeleteFilterDialog = ({ visible, onCancel, onDelete }: {
    onCancel: () => void;
    onDelete: () => void;
    visible: boolean;
}) => {
    const msgs=useMsgs();
    if(!visible) return null;
    return (
        <InlineDialog
            title={msgs.gui.titleFilterDelete}
            cancelButton={msgs.gui.actionLeave}
            onCancel={onCancel}
            acceptButton={msgs.gui.actionDelete}
            onAccept={onDelete}
        >
            <IconAlert>{msgs.gui.hintFilterDelete}</IconAlert>
        </InlineDialog>
    );
}

