//@flow
// Plik z komponentami dla monitoringu faktur
import React, {useCallback, useContext, useMemo, useState} from 'react';
import {BForm, Form, FormContext, FormContextType, GroupContext, GroupContextType} from "./Form";
import {formatString, getLangValue, getMessage, useMsgs} from "./Language";
import {FormikProps, useField} from "formik";
import type {DataTableColumn} from "./DataTable";
import {ClientDataTable, dateCell, dateTimeCell, QueryDataTable, QueryDataTableProps} from "./DataTable";
import {QueryLoader, useRpcQuery, useRpcStaticQuery} from "./QueryUtils";
import type {
    InvoiceMonitoringIntegrationSettings,
    LangOption,
    MonitoredClient,
    MonitoredCounter,
    MonitoredNotification,
    MonitoringScenarioDetails,
    MonitoringScenarioStepSetup,
    UserAPIQuery
} from "../api";
import {InvoiceSynchronizationType, MonitoredInvoice, MonitoringStage} from "../api";
import type {FilterFieldConfig} from "./Filter";
import FilterInput, {FilterInputProps} from "./Filter";
import {Hint, Icon, IconAlert, InlineDialog} from "./Components";
import {Accordion, Alert, Button, Card, Col, FormControl, FormGroup, FormLabel, InputGroup, Row} from "react-bootstrap";
import {formatMoney, stringJoin} from "./Utils";
import {DateInput} from "./DateTime";
import {store} from "../application";
import {useQueryClient} from "@tanstack/react-query";
import {addDays, format} from "date-fns";
import cn from 'classnames'
import type {PreviewMode} from "./InvoiceMonitoringPreview";
import {InvoiceMonitoringPreviewDialog} from "./InvoiceMonitoringPreview";
import type {LanguageData} from "../LangType";

/**
 * Uporządkowane etapy w postaci listy.
 * @type {Array<MonitoringStage>}
 */
export const OrderedStages: Array<MonitoringStage> = [ "BeforePayment", "SlightlyOutOfDate", "Expired", "HeavilyOverdue", "ExceedingScenario" ];

/**
 * Kolory do etapów według indeksu
 * @type {Array<string>}
 */
export const StageColors: Array<string> = [
    '#468748', '#f6d013', '#fa9306',
    '#bf4748', '#941a1b'
];

/**
 * Funkcja zwraca numer etapu; patrz OrderedStages.
 * @param {MonitoringStage} stage etap, dla którego sprawdzić numer etapu
 * @return {number} numer etapu 0..4 lub -1
 */
export function indexOfStage(stage: MonitoringStage): number {
    if(typeof(stage)!=='string') return -1;
    return OrderedStages.indexOf(stage);
}



/**
 * Komponent wyboru scenariusza.
 */
export const ScenarioSelect = ({ value, ...props }: {
    value: string;
    onChange: (e: SyntheticEvent) => void;
}) => {
    const msgs=useMsgs();
    const scenarios=useRpcStaticQuery<UserAPIQuery, Array<LangOption>>("/user", "getMonitoringScenarios");
    if(!scenarios.isSuccess) return <BForm.Control as="select"/>; // placeholder

    return <BForm.Control as="select" value={typeof(value)!=='string'?"":value} {...props}>
        <option value="">{msgs.gui.selectScenario}</option>
        {scenarios.data.map(o => <option key={o.value} value={o.value}>{getLangValue(o.label)}</option>)}
    </BForm.Control>
}

export const FormScenarioSelect = ({ fieldName, noError, ...props }) => {
    const fc=useContext<FormContextType>(FormContext);
    const context=useContext<GroupContextType>(GroupContext);
    const [ field, meta, helpers ] = useField(fieldName || context.name);
    const error=meta.error && meta.touched;
    return <>
        <ScenarioSelect
            readOnly={fc.readonly}
            className={error?"is-invalid ":""+(props.className || "")}
            {...field}
            {...props}/>
        {(!noError && error)?<BForm.Control.Feedback type="invalid">{getMessage(meta.error)}</BForm.Control.Feedback>:null}
    </>
}

export const InvoicesFilter = ({ ...props }: $Diff<FilterInputProps, { fields: any }> ) => {
    const msgs=useMsgs();
    const fields=useMemo<Array<FilterFieldConfig>>(() => [
        {
            permanent: true,
            field: 'stage',
            label: msgs.gui.labelState,
            type: 'select',
            options: [
                { label: msgs.gui.monitoringInvoiceStageBeforePayment, value: 'BeforePayment' },
                { label: msgs.gui.monitoringInvoiceStageSlightlyOutOfDate, value: 'SlightlyOutOfDate' },
                { label: msgs.gui.monitoringInvoiceStageExpired, value: 'Expired' },
                { label: msgs.gui.monitoringInvoiceStageHeavilyOverdue, value: 'HeavilyOverdue' },
                { label: msgs.gui.monitoringInvoiceStageExceedingScenario, value: 'ExceedingScenario' },
            ]
        },
        {
            permanent: true,
            field: "overdue",
            label: msgs.gui.labelOverdue,
            type: "activity",
        }, {
            permanent: true,
            field: 'left',
            label: msgs.gui.labelLeft,
            type: "money"
        }, {
            field: 'sellDate',
            label: msgs.gui.labelSellDate,
            type: 'date',
        }, {
            field: 'payDate',
            label: msgs.gui.labelPayDate,
            type: 'date',
        }, {
            field: 'disabled',
            label: msgs.gui.imFilterDisabled,
            type: 'boolean'
        }
        ], [ msgs ]);
    return <FilterInput fields={fields} {...props}/>
}

/**
 * Okno z możliwością wyboru daty.
 */
export const InvoicePaymentDayDialog = ({ invoice, onMarkAsPayed }: {
    invoice: string;
    onMarkAsPayed: (invoice: string|null, date: string) => void;
}) => {
    const msgs= useMsgs();
    const [ date, setDate ] = useState(null);
    return <InlineDialog
        title={msgs.gui.imMarkAsPayed} onHide={() => onMarkAsPayed(null, null)}
        onAccept={date?() => onMarkAsPayed(invoice, date):true}
        acceptButton={msgs.gui.imMarkAsPayed}
        acceptVariant="success"
        onCancel={() => onMarkAsPayed(null, null)}
    >
        <BForm.Group>
            <BForm.Label>{msgs.gui.labelPayDate}</BForm.Label>
            <DateInput value={date} onChange={setDate}/>
        </BForm.Group>
    </InlineDialog>
}

const InvoiceAction = ({ msgs, row, onConfirm, onToggle }) => {
    const invoice: MonitoredInvoice=row.original;
    const paused=invoice.status==="Disabled";
    const payed=invoice.status==="Paid";

    return <>
        <Button
            variant={paused?"success":"danger"}
            onClick={() => onToggle(invoice)}
            title={paused?msgs.gui.imInvoiceResume:msgs.gui.imInvoicePause}
        >
            {paused?<Icon.Play/>:<Icon.Pause/>}
        </Button> {!payed && <Button
            onClick={() => onConfirm(invoice)}
            title={msgs.gui.imMarkAsPayed}
            variant="secondary"
        >
            <Icon.MoneyBill/>
        </Button>}
    </>
}

/**
 * Komponent listy faktur z filtrowaniem, sortowaniem itd.
 */
export const InvoicesList = ({ displayClient, actions, ...props }: {
    displayClient?: boolean;
    actions?: boolean;
} & QueryDataTableProps<MonitoredInvoice>) => {
    const msgs = useMsgs();
    const query= useQueryClient();
    const [ confirm, setConfirm ] =useState<MonitoredInvoice>(null);
    const [ toggle, setToggle ] = useState<MonitoredInvoice>(null);
    const [ ts, setTs ] = useState(performance.now());

    const handleToggle = useCallback(async (invoice: MonitoredInvoice) => {
        const res=await store.userApi.disableInvoice(invoice.id, invoice.status!=="Disabled");
        if(res) {
            await query.invalidateQueries([ "rpc", "/user", "queryMonitoredInvoices" ]);
            await query.invalidateQueries([ "rpc", "/user", "getMonitoredClient" ]);
            await query.invalidateQueries([ "rpc", "/user", "queryMonitoredClient" ]);

            setTs(performance.now());
        }
    }, []);
    const handleConfirm=useCallback((invoice: MonitoredInvoice) => {
        setConfirm(invoice);
    }, []);
    const handleConfirmDialog = useCallback(async (invoice: string|null, date: string|null) => {
        if(invoice && date) {
            const res=await store.userApi.markAsPayedInvoice(invoice, date);
            if(res) {
                await query.invalidateQueries([ "rpc", "/user", "queryMonitoredInvoices" ]);
                await query.invalidateQueries([ "rpc", "/user", "getMonitoredClient" ]);
                await query.invalidateQueries([ "rpc", "/user", "queryMonitoredClient" ]);
                setTs(performance.now());
            }
        }
        setConfirm(null);
    }, [ confirm ]);

    const columns = useMemo<Array<DataTableColumn<MonitoredInvoice>>>(() => {
        let res=[
        {
            accessor: "number",
            Header: msgs.gui.labelDocumentNr,
            className: displayClient!==false?"nd":null,
        }];
        if(displayClient!==false) res.push({
            accessor: 'client',
            Header: msgs.gui.labelClient,
            Cell: ({ value, row }) => <>
                <h1>{value}</h1>
                {row.original.tax}
                </>,
        });
        res.push({
            accessor: 'sellDate',
            Header: msgs.gui.labelSellDate,
            Cell: dateCell,
            className: "date",
        }, {
            accessor: 'payDate',
            Header: msgs.gui.labelPayDate,
            Cell: dateCell,
            className: "date",
        }, {
            accessor: 'left',
            className: "money",
            Header: msgs.gui.labelLeft,
            Cell: ({ value, row }) => formatMoney(value, row.original.currency)
        }, {
            accessor: 'overdue',
            Header: msgs.gui.labelOverdue,
            className: "md",
        }, {
            accessor: 'stage',
            Header: msgs.gui.labelState,
            Cell: ({ value, row }) => {
                const i:MonitoredInvoice=row.original;
                if(i.status==="Vindication") return msgs.gui.monitoringInvoiceVindication;
                if(!value) return null;
                return <div className={`stage-${value}`}>{msgs.gui['monitoringInvoiceStage'+value] || ""}</div>
            },
        });
        if(actions) {
            res.push({
                accessor: 'id',
                className: "actions",
                Header: "",
                Cell: ({ row }) => <InvoiceAction msgs={msgs} row={row} onConfirm={handleConfirm} onToggle={handleToggle}/>,
            })
        }
        return res;
    }, [ msgs, displayClient, actions, handleConfirm, handleToggle ]);
    return <>
        <QueryDataTable columns={columns} path="/user" func="queryMonitoredInvoices" initialSortBy={"-stage"} dataTimestamp={ts} {...props}/>
        {confirm && <InvoicePaymentDayDialog invoice={confirm.id} onMarkAsPayed={handleConfirmDialog}/>}
        </>;
}

export const ClientInvoicesList = ({ data, ...props }: {
    data: Array<MonitoredInvoice>;
}) => {
    const msgs = useMsgs();
    const columns = useMemo<Array<DataTableColumn<MonitoredInvoice>>>(() => [
            {
                accessor: "number",
                Header: msgs.gui.labelDocumentNr,
            }, {
            accessor: 'sellDate',
            Header: msgs.gui.labelSellDate,
            Cell: dateCell,
            className: "date",
        }, {
            accessor: 'payDate',
            Header: msgs.gui.labelPayDate,
            Cell: dateCell,
            className: "date",
        }, {
            accessor: 'left',
            className: "money",
            Header: msgs.gui.labelLeft,
            Cell: ({ value, row }) => formatMoney(value, row.original.currency)
        }, {
            accessor: 'overdue',
            Header: msgs.gui.labelOverdue,
            className: "md",
        }, {
            accessor: 'stage',
            Header: msgs.gui.labelState,
            Cell: ({ value, row }) => !value?null:<div className={`stage-${value}`}>{msgs.gui['monitoringInvoiceStage'+value] || ""}</div>,
        }], [ msgs ]);
    return <ClientDataTable columns={columns} initialSortBy={"-finalStage"} data={data} {...props}/>
}

export const ClientsFilter = ({ ...props }: $Diff<FilterInputProps, { fields: any }> ) => {
    const msgs=useMsgs();
    const fields=useMemo<Array<FilterFieldConfig>>(() => [
        {
            permanent: true,
            field: "tax",
            label: msgs.gui.labelNIP,
            type: "string",
        },
        {
            permanent: true,
            field: 'finalStage',
            label: msgs.gui.labelState,
            type: 'select',
            options: [
                { label: msgs.gui.monitoringStageBeforePayment, value: 'BeforePayment' },
                { label: msgs.gui.monitoringStageSlightlyOutOfDate, value: 'SlightlyOutOfDate' },
                { label: msgs.gui.monitoringStageExpired, value: 'Expired' },
                { label: msgs.gui.monitoringStageHeavilyOverdue, value: 'HeavilyOverdue' },
                { label: msgs.gui.monitoringStageExceedingScenario, value: 'ExceedingScenario' },
            ]
        },
        {
            permanent: true,
            field: 'overdue',
            label: msgs.gui.labelOverdue,
            type: "activity",
        }, {
            field: 'balance',
            label: msgs.gui.labelBalance,
            type: 'money',
        }, {
            field: 'noEmail',
            label: msgs.gui.imMissingEmail,
            type: "boolean"
        }, {
            field: 'noPhone',
            label: msgs.gui.imMissingPhone,
            type: "boolean"
        }, {
            field: 'disabled',
            label: msgs.gui.imFilterDisabled,
            type: 'boolean'
        }, {
            field: 'vindicationRejected',
            label: msgs.gui.imVindicationRejectedFilter,
            type: 'boolean'
        }
    ], [ msgs ]);
    return <FilterInput fields={fields} {...props}/>
}

const BalanceCell = ({ row }: { }) => {
    const balance: { [string]: string }=row.original.balance;
    if(!balance) return null;
    let res=[];
    if(balance['PLN']) res.push(<div className="balance-row" key="PLN">{formatMoney(balance['PLN'], 'PLN')}</div>)
    for(const [ c, v ] of Object.entries(balance)) {
        if(c==='PLN') continue;
        res.push(<div className="balance-row" key={c}>{formatMoney(v, c)}</div>);
    }
    return res;
}

const OverdueCell = ({ value }) => {
    if(typeof(value)!=='number') return null;
    if(value>0) return <span className="overdue">{value}</span>
    return <span className="early">{value}</span>
}

const ClientStatusCell = ({ row }) => {
    const msgs = useMsgs();

    const c: MonitoredClient=row.original;
    let hints=[];
    if(!c.phone) hints.push(<p key="pi">{msgs.gui.imMissingPhone}</p>);
    else if(!c.validPhone) hints.push(<p key="pi">{msgs.gui.imInvalidPhone}</p>);

    if(!c.email) hints.push(<p key="em">{msgs.gui.imMissingEmail}</p>);
    else if(!c.validEmail) hints.push(<p key="em">{msgs.gui.imInvalidEmail}</p>);

    if(hints.length===0) return <Icon.Check className="text-success"/>;   // brak uwag
    return <Hint buttonTextClass="text-danger">{hints}</Hint>;
}


export function getMonitoredClientStageName(msgs: LanguageData, i: MonitoredClient): string {
    if(!i || !i.stage) return "";
    if(!i.grace && i.vindication) {
        if(i.monitoredInvoices>0) return msgs.gui.monitoringClientVindicationWithInvoices;
        return msgs.gui.monitoringClientVindication;
    }
    return msgs.gui['monitoringStage'+i.finalStage] || "";
}

/**
 * Komponent listy kontrahentów z filtrowaniem, sortowaniem itd.
 */
export const ClientsList = (props: QueryDataTableProps) => {
    const msgs = useMsgs();
    const columns = useMemo<Array<DataTableColumn<MonitoredClient>>>(() => [
        {
            accessor: 'name',
            Header: msgs.gui.labelContractor,
        }, {
            accessor: 'tax',
            Header: msgs.gui.labelNIP,
            className: "nip",
        }, {
            accessor: 'balance',
            Header: msgs.gui.labelBalance,
            className: "money",
            Cell: BalanceCell,
        }, {
            accessor: 'scenario',
            Header: msgs.gui.labelMonitoringScenario,
            className: "md",
            Cell: ({row}) => getLangValue(row.original.scenarioLabel),
        }, {
            accessor: 'finalStage',
            Header: msgs.gui.labelMonitoringState,
            className: 'mnd',
            Cell: ({ value, row }) => !value?null:<div className={`stage-${value}`}
            >
                {getMonitoredClientStageName(msgs, row.original)}
                {row.original.paused && <><br/><i>{msgs.gui.imClientPaused}</i></>}
                {row.original.excluded && <><br/><i>{msgs.gui.imClientDisabled}</i></>}
            </div>,
        }, {
            accessor: 'overdue',
            Header: msgs.gui.labelOverdue,
            className: "mmd col-right",
            Cell: OverdueCell,
        }, {
            id: 'comments',
            Header: msgs.gui.labelComments,
            className: "icon col-center",
            Cell: ClientStatusCell,
        }
    ], [ msgs ]);
    return <QueryDataTable columns={columns} path="/user" func="queryMonitoredClient" initialSortBy="-balance" {...props}/>;
}

export const NotificationFilter = (props: $Diff<FilterInputProps, { fields: any }> & { client?: string } ) => {
    const msgs = useMsgs();
    const fields = useMemo<Array<FilterFieldConfig>>(() => {
        let res = [
            {
                permanent: true,
                field: "sendDate",
                label: msgs.gui.labelSendDate,
                type: "date",
            }, {
                permanent: true,
                field: 'status',
                label: msgs.gui.labelStatus,
                type: "string",
            }];
        if (!props.client) res.push({
            field: 'client',
            label: msgs.gui.labelContractor,
            type: 'string',
        });
        res.push({
            field: 'stage',
            label: msgs.gui.labelStage,
            type: "select",
            options: [
                {label: msgs.gui.monitoringStageBeforePayment, value: 'BeforePayment'},
                {label: msgs.gui.monitoringStageSlightlyOutOfDate, value: 'SlightlyOutOfDate'},
                {label: msgs.gui.monitoringStageExpired, value: 'Expired'},
                {label: msgs.gui.monitoringStageHeavilyOverdue, value: 'HeavilyOverdue'},
                {label: msgs.gui.monitoringStageExceedingScenario, value: 'ExceedingScenario'},
            ]
        });
        return res;
    }, [msgs, props.client]);
    return <FilterInput fields={fields} {...props}/>
}

type NotificationPreviewState = {
    show: boolean;
    mode: "sms"|"email";
    nid: string;
}

/**
 * Komponent z listą wysłanych powiadomień i planowaną wysyłką.
  */
export const NotificationsList = (props: QueryDataTableProps & { clientId?: string }) => {
    const msgs = useMsgs();
    const [preview, setPreview] = useState<NotificationPreviewState | null>(null);

    const columns = useMemo<Array<DataTableColumn<MonitoredNotification>>>(() => {
        let columns = [
            {
                accessor: 'sendDate',
                Header: msgs.gui.labelSendDate,
                Cell: dateTimeCell,
            }];
        if (!props.clientId) columns.push({
            accessor: 'client',
            Header: msgs.gui.labelContractor,
        });
        columns.push({
            id: 'channel',
            Header: msgs.gui.labelSendChannel,
            Cell: ({row}) => [
                row.original.emailQueued && <span key="em"
                >{msgs.gui.channelEmail} <MonitoringTemplatePreview
                    onClick={() => setPreview({show: true, mode: "email", nid: row.original.id})}
                /></span>, " ",
                row.original.smsQueued && <span key="sm"
                >{msgs.gui.channelSMS} <MonitoringTemplatePreview
                    onClick={() => setPreview({show: true, mode: "sms", nid: row.original.id})}
                /></span>
            ],

            // Cell: ({row}) => stringJoin(" ",
            //     row.original.emailQueued && msgs.gui.channelEmail,
            //     row.original.smsQueued && msgs.gui.channelSMS
            // )
        }, {
            id: 'sendTo',
            Header: msgs.gui.labelSendTo,
            Cell: ({row}) => stringJoin(" ",
                row.original.emailQueued && row.original.email,
                row.original.smsQueued && row.original.phone
            )
        }, {
            accessor: 'status',
            Header: msgs.gui.labelStatus,
        }, {
            accessor: 'stage',
            Header: msgs.gui.labelCommunicationStyle,
            Cell: ({value}) => msgs.gui['imStage' + value]
        });
        return columns;
    }, [msgs, props.clientId ]);
    const filter= useMemo(() => {
        if(!props.clientId) return null;
        return [{
                field: "client",
                value: props.clientId
            }];
    }, [ props.clientId ])
    return <>
        {preview && <InvoiceMonitoringPreviewDialog
            mode={preview.mode}
            show={preview.show}
            org={store.user.orgId}
            nid={preview.nid}
            onHide={() => {
                setPreview(state => {
                    return {
                        ...state,
                        show: false,
                    }
                })
                window.setTimeout(() => {
                    setPreview(null);
                }, 350);
            }}
        />}
        <QueryDataTable
            columns={columns}
            path="/user"
            func="queryMonitoredNotification"
            customFilters={props.customFilters || filter}
            initialSortBy="-sendDate"
            {...props}
        />
    </>
}


/**
 * Komponent wyświetlający dane w tabelce z informacją o wysłanych powiadomieniach
 * oraz planowanych do wysyłki.
 */
export const NotificationCountersTable = ({ days, sent, projected, singleMode, labelRow, dateLabel }: {
    days: number;
    sent: null|Array<MonitoredCounter|null>;
    projected: null|Array<MonitoredCounter|null>;
    /** Czy tryb widoku dla jednego kontrahenta, ergo liczba SMS/e-mail jest 0 lub 1 */
    singleMode: boolean;
    labelRow?: boolean;
    dateLabel?: boolean;
}) => {
    const msgs = useMsgs();
    if(labelRow===undefined) labelRow=true;
    else labelRow=!!labelRow;

    const items:Array<MonitoredCounter|null>=useMemo(() => {
        let items=[];
        for(let i=days-1;i>=0;--i) {
            if(Array.isArray(sent) && i<sent.length) items.push(sent[i]);
            else items.push(null);
        }
        for(let i=0;i<days;++i) {
            if(Array.isArray(projected) && i<projected.length) items.push(projected[i]);
            else items.push(null);
        }
        return items;
    }, [ days, sent, projected ]);
    let today=new Date();

    const cellStyle=(i) => cn(i<days && "sent", i===days && "now", i>days && "plan");
    const fn=(v: number, sms: boolean) => {
        if(!v) return null;
        if(labelRow) {
            if(singleMode) return '■';
            else return '■ '+v;
        } else {
            if (singleMode) return sms ? msgs.gui.notificationSMS : msgs.gui.notificationEmail;
            return v + " " + (sms ? msgs.gui.notificationSMS : msgs.gui.notificationEmail);
        }
    }
    const t=(v: number, sms: boolean, day: number) => {
        if(!v) return null;
        const date=format(addDays(today, day-days), msgs.gui.notificationTitleDate);
        if(singleMode) return date+" "+(sms?msgs.gui.notificationSMS:msgs.gui.notificationEmail);
        return date+"\n"+(sms?msgs.gui.notificationSMS:msgs.gui.notificationEmail)+": "+v;
    }

    return <table className="monitored-counter">
        <tbody>
        <tr className="sms-row">
            {labelRow && <td key="label" className="label-cell">{msgs.gui.labelSMS}</td>}
            {items.map((c, i) => <td
                key={i}
                className={cellStyle(i)}
                title={t(c && c.sms, true, i)}
            >{fn(c && c.sms, true)}</td>)}
        </tr>
        <tr className="email-row">
            {labelRow && <td key="label" className="label-cell">{msgs.gui.labelEmailOnly}</td>}
            {items.map((c, i) => <td
                key={i}
                className={cellStyle(i)}
                title={t(c && c.email, false, i)}
            >{fn(c && c.email, false)}</td>)}
        </tr>
        <tr className="date-row">
            {labelRow && <td key="label" className="label-cell">{dateLabel && msgs.gui.labelDate}</td>}
            {items.map((c, i) => <td
                key={i}
                className={cellStyle(i)}
            >
                {(i===0 || i===(items.length-1) || ((i-days)%7)===0)?format(addDays(today, i-days), msgs.gui.notificationLabelDate):""}
            </td>)}
        </tr>
        </tbody>
    </table>
}


/**
 * Funkcja tworzenia domyślnych kroków dla scenariusza monitoringu.
 * @param scenario scenariusz monitoringu
 * @return {Array<MonitoringScenarioStepSetup>} kroki, domyślnie true
 */
function createDefaultSteps(scenario: MonitoringScenarioDetails): Array<MonitoringScenarioStepSetup> {
    let res:Array<MonitoringScenarioStepSetup>=new Array(scenario.steps.length);
    for(let i=0;i<res.length;++i) res[i]={ sms: true, email: true }
    return res;
}

const MonitoringTemplatePreview=({ onClick }) => {
    return <span onClick={onClick} className="icon-search c-pointer"/>;
}

type PreviewState = {
    show: boolean;
    step: string;
    mode: PreviewMode;
}

export const MonitoringScenarioStepsForm = ({
    scenario, data, value, onChange, readonly, collapsible,
    previewOrg, previewClient
}: {
    scenario?: string;
    data: MonitoringScenarioDetails;
    value: Array<MonitoringScenarioStepSetup>|null;
    onChange?: (value: Array<MonitoringScenarioStepSetup>|null) => void;
    readonly?: boolean;
    /** Jeżeli podane to opakowane w akordeon, który ma domyślny podany stan */
    collapsible?: boolean;
    previewOrg?: string;
    previewClient?: string;
}) => {
    const msgs = useMsgs();
    const [ preview, setPreview ] = useState<PreviewState|null>(null);

    if(!data) return null;
    if(!Array.isArray(value) || value.length!==data.steps.length) {
        if(readonly || typeof(onChange)!=='function') {
            value=createDefaultSteps(data);
        } else {
            onChange(createDefaultSteps(data));
            return null; // za chwile zostanie ponownie wywołane z krokami
        }
    }

    let res=[];
    let stage:MonitoringStage|null=null;
    for(let i=0;i<data.steps.length;++i) {
        const s=data.steps[i];
        if(s.stage!==stage) {
            stage=s.stage;
            res.push(<h5
                key={stage}
                className="scenario-stage-header"
                style={{ borderColor: StageColors[indexOfStage(stage)] }}
            >{msgs.gui[`imStage${stage}`]} <Hint>{msgs.gui[`imStage${stage}Info`]}</Hint>
            </h5>)
        }
        res.push(<Row key={s.days} className="scenario-view-step">
            <Col md={2}>{s.days} {msgs.gui.labelDays}</Col>
            <Col md={4} className="text-right">
                {msgs.gui.labelStepSetting}
            </Col>
            <Col md={3}>
                <BForm.Check
                    inline
                    readOnly={readonly}
                    // disabled={readonly}
                    checked={value[i].email}
                    id={`step-mail-${i}`}
                    label={msgs.gui.labelStepEmail}
                    onChange={(readonly!==true && onChange)?(e => onChange(value.map(
                        (v, j) => j!==i?v:{ email: e.target.checked, sms: v.sms }
                    ))):undefined}
                />
                <MonitoringTemplatePreview onClick={() => setPreview({ show: true, mode: "email", step: i+1 })}/>
            </Col>
            <Col md={3}>
                <BForm.Check
                    inline
                    readOnly={readonly}
                    // disabled={readonly}
                    checked={value[i].sms}
                    id={`step-sms-${i}`}
                    label={msgs.gui.labelStepSMS}
                    onChange={(readonly!==true && onChange)?(e => onChange(value.map(
                        (v, j) => j!==i?v:{ sms: e.target.checked, email: v.email }
                    ))):undefined}
                />
                <MonitoringTemplatePreview onClick={() => setPreview({ show: true, mode: "sms", step: i+1 })}/>
            </Col>
        </Row>)
    }
    if(preview!==null) {
        res.push(<InvoiceMonitoringPreviewDialog
            mode={preview.mode}
            scenario={scenario}
            step={preview.step}
            show={preview.show}
            org={previewOrg}
            client={previewClient}
            onHide={() => {
                setPreview(state => {
                    return {
                        ...state,
                        show: false,
                    }
                })
                window.setTimeout(() => {
                    setPreview(null);
                }, 350);
            }}
        />);
    }
    
    if(typeof(collapsible)==='boolean') {
        return <Accordion className="mb-3" defaultActiveKey={collapsible===true?"0":null}>
            <Card>
                <Accordion.Toggle as={Card.Header} className="clickable" variant="link" eventKey="0">
                    <h5>{msgs.gui.imScenarioStepsShow}</h5>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        {res}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    }
    return res;
}

/**
 * Komponent/formularz do ustawiani scenariusza monitoringu oraz wyświetlenia jego etapów.
 */
export const MonitoringScenarioController = ({ scenario, readonly, value, onChange, previewOrg, previewClient, onHide }: {
    scenario: string;
    readonly: boolean;
    value: Array<MonitoringScenarioStepSetup>|null;
    onChange: (value: Array<MonitoringScenarioStepSetup>|null) => void;
    onHide: () => void;
}) => {
    const msgs=useMsgs();
    const details=useRpcStaticQuery("/user", "getMonitoringScenario", scenario);
    const data: MonitoringScenarioDetails|null=details.data;
    const [ stepValues, setStepValues ] = useState<Array<MonitoringScenarioStepSetup>>(value);

    if(!data) return null;
    const desc=getLangValue(data.desc);

    return <InlineDialog
        title={msgs.gui.labelEditTitle} onHide={onHide}
        onAccept={() => onChange(stepValues)} acceptButton={msgs.gui.buttonSave}
        acceptVariant="success"
        onCancel={onHide}
        scrollable={true}
    >
        <h3>{getLangValue(data.title)}</h3>
        {desc && <IconAlert>{desc}</IconAlert>}
        <MonitoringScenarioStepsForm
            previewClient={previewClient} previewOrg={previewOrg}
            scenario={scenario}
            data={data} value={stepValues} onChange={setStepValues} readonly={readonly}/>
    </InlineDialog>
}

export const MonitoringScenarioSettingsInput = ({ scenario, value, onChange, readonly, ...props }: {
    scenario: string;
    readonly?: boolean;
    value: Array<MonitoringScenarioStepSetup>|null;
    onChange: (value: Array<MonitoringScenarioStepSetup>|null) => void;
}) => {
    const [ visible, setVisible ] = useState(false);
    const msgs = useMsgs();

    return <>
        <Button
            onClick={() => setVisible(true)}
            variant="outline-secondary"
            disabled={!scenario}
            {...props}
        >{msgs.gui.labelScenarioSettings}</Button>
        {visible && <MonitoringScenarioController
            scenario={scenario}
            readonly={readonly}
            onHide={() => setVisible(false)}
            value={value}
            onChange={(value) =>{
                // console.log("OnChange:", value);
                onChange(value);
                setVisible(false);
            }}
        />}
    </>
}


export const ChangeScenarioDialog = ({ value, onChange, onHide }: {
    value: string|null;
    onChange: (value: string|null) => void;
    onHide: () => void;
}) => {
    const msgs=useMsgs();
    const [edit, setEdit] = useState(value);
    const details = useRpcStaticQuery<MonitoringScenarioDetails>("/user", "getMonitoringScenario", edit || "");
    // console.log("ChnageScenario: ", value, edit, details);

    return <InlineDialog
        title={msgs.gui.changeScenarioTitle}
        acceptButton={msgs.gui.actionChange}
        onAccept={(edit===value)?true:(() => onChange(edit))}
        onHide={onHide}
        onCancel={onHide}
    >
        <ScenarioSelect value={edit} onChange={e => setEdit(e.target.value)}/>
        <div className="monitoring-scenario-info">
            <QueryLoader query={details}>
                {details.data && <Alert>
                    <h3>{getLangValue(details.data.title)}</h3>
                    <div dangerouslySetInnerHTML={{__html: getLangValue(details.data.desc)}}/>
                </Alert>}
            </QueryLoader>
        </div>
    </InlineDialog>
}

/**
 * Integracje wywoływane z poziomu serwera.
 */
const internalIntegrations: Array<InvoiceSynchronizationType>=[ "wFirma", "iFirma", "inFakt", "Fakturownia", "SaldeoSmart" ];

/**
 * Informacja o aktualnych ustawieniach integracji monitoringu faktur
 */
export const IntegrationSettings = () => {
    const query=useRpcQuery<InvoiceMonitoringIntegrationSettings>('/user', 'getInvoiceMonitoringIntegrationSettings');
    const msgs=useMsgs();

    if(!query.isSuccess || !query.data) return null;
    const settings:InvoiceMonitoringIntegrationSettings=query.data;

    if(!settings.type) return <h4>{msgs.gui.imNoIntegration}</h4>;
    if(settings.type==="Import") return <h4>{msgs.gui.imManualImport}</h4>;

    const key=<FormGroup key="key" className="mt-3">
        <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text>{msgs.gui.labelInvoiceMonitoringKey}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl readOnly value={settings.key}/>
            {settings.type==="DataIntegrator" && <InputGroup.Append>
                <Button variant="outline-secondary" onClick={() => {
                    navigator.clipboard.writeText(settings.key);
                }}>{msgs.gui.actionCopy}</Button>
            </InputGroup.Append>}
        </InputGroup>
    </FormGroup>;

    if(settings.type==="DataIntegrator") {   // DataIntegrator
        return [<h4 key="t">{msgs.gui.imIntegrationDataIntegrator}</h4>, key];
    } else {    // zewnętrzny system wFirma/Fakturownia itd
        return [ <h4 key="t">{formatString(msgs.gui.imIntegrationWith, msgs.gui['imIntegration-'+settings.type] )}</h4>,
            key, <p className="text-info">{msgs.gui.imIntegrationSyncInfo}</p> ];
    }
}

type IntegrationFormSettings = {
    type: InvoiceSynchronizationType;
    key: string;

    wFirmaKey: string;
    wFirmaKey2: string;
    wFirmaKey3: string;

    iFirmaKey: string;
    iFirmaKey2: string;

    inFaktKey: string;
    inFaktKey2: string;

    FakturowniaKey: string;
    FakturowniaKey2: string;

    SaldeoSmartKey: string;
}

const integrationDefaults: IntegrationFormSettings={
    type: "",
    key: "",

    wFirmaKey: "",
    wFirmaKey2: "",
    wFirmaKey3: "",
    iFirmaKey: "",
    iFirmaKey2: "",
    inFaktKey: "",
    inFaktKey2: "",
    FakturowniaKey: "",
    FakturowniaKey2: "",

    SaldeoSmartKey: '',
}

export function integrationSettingsToFormSettings(is: InvoiceMonitoringIntegrationSettings): IntegrationFormSettings {
    let res: IntegrationFormSettings={ ...integrationDefaults };
    res.type=is.type;
    if(res.type) {
        if(typeof(res[res.type+"Key"])!=='undefined') res[res.type+"Key"]=is.key;
        if(typeof(res[res.type+"Key2"])!=='undefined') res[res.type+"Key2"]=is.key2;
        if(typeof(res[res.type+"Key3"])!=='undefined') res[res.type+"Key3"]=is.key3;
    }
    return res;
}

export function integrationFormSettingsToSettings(is: IntegrationFormSettings): InvoiceMonitoringIntegrationSettings {
    let res: InvoiceMonitoringIntegrationSettings={ type: is.type };
    if(is.type) {
        if(typeof(integrationDefaults[is.type+"Key"])!=='undefined') res.key=is[is.type+"Key"];
        if(typeof(integrationDefaults[is.type+"Key2"])!=='undefined') res.key2=is[is.type+"Key2"];
        if(typeof(integrationDefaults[is.type+"Key3"])!=='undefined') res.key3=is[is.type+"Key3"];
    }
    return res;
}

/**
 * Formularz edycji ustawień integracji dla monitoringu faktur.
 */
export const IntegrationSettingsEditForm=({ value, onSubmit }: {
    value: IntegrationFormSettings;
    onSubmit: (type: InvoiceSynchronizationType, value: IntegrationFormSettings) => Promise<void>;
}) => {
    const msgs = useMsgs();
    return <>
        <Form
            initialValues={value}
            validate={(values) => {
                let err = {};
                if (!values.type) {
                    err.type = 'validation.required';
                } else {
                    switch (values.type) {
                        case "wFirma":
                            if (values.wFirmaKey.trim().length === 0) err.wFirmaKey = 'validation.required';
                            if (values.wFirmaKey2.trim().length === 0) err.wFirmaKey2 = 'validation.required';
                            break;
                        case "iFirma":
                            if (values.iFirmaKey.trim().length === 0) err.iFirmaKey = 'validation.required';
                            if (values.iFirmaKey2.trim().length === 0) err.iFirmaKey2 = 'validation.required';
                            break;
                        case "inFakt":
                            if (values.inFaktKey.trim().length === 0) err.inFaktKey = 'validation.required';
                            break;
                        case "Fakturownia":
                            if (values.FakturowniaKey.trim().length === 0) err.FakturowniaKey = 'validation.required';
                            if (values.FakturowniaKey2.trim().length === 0) err.FakturowniaKey2 = 'validation.required';
                            break;
                        case "SaldeoSmart":
                            if (values.SaldeoSmartKey.trim().length === 0) err.SaldeoSmartKey = 'validation.required';
                            break;
                    }
                }

                return err;
            }}
            onSubmit={async (values, helpers) => {
                helpers.setSubmitting(true);
                try {
                    await onSubmit(values.type, values);
                } finally {
                    helpers.setSubmitting(false);
                }
            }}
        >{(formik: FormikProps) => {
            const v = formik.values;
            const isExt = internalIntegrations.includes(v.type);

            return <>
                <Form.Group name="type">
                    <FormLabel>{msgs.gui.labelInvoiceMonitoringIntegrationType}</FormLabel>
                    <Form.Select fieldName="type">
                        <option value="">{msgs.gui.labelSelectOption}</option>
                        <option value="DataIntegrator">{msgs.gui['imIntegration-DataIntegrator']}</option>
                        <option value="wFirma">{msgs.gui['imIntegration-wFirma']}</option>
                        <option value="iFirma">{msgs.gui['imIntegration-iFirma']}</option>
                        <option value="Fakturownia">{msgs.gui['imIntegration-Fakturownia']}</option>
                        <option value="inFakt">{msgs.gui['imIntegration-inFakt']}</option>
                        <option value="SaldeoSmart">{msgs.gui['imIntegration-SaldeoSmart']}</option>
                    </Form.Select>
                </Form.Group>
                {msgs.gui['imKey-' + v.type] && <Form.Group name={v.type+"Key"}>
                    <FormLabel>{msgs.gui['imKey-' + v.type]}</FormLabel>
                    <Form.Control disabled={!isExt}/>
                </Form.Group>}
                {msgs.gui['imKey2-' + v.type] && <Form.Group name={v.type+"Key2"}>
                    <FormLabel>{msgs.gui['imKey2-' + v.type]}</FormLabel>
                    <Form.Control disabled={!isExt}/>
                </Form.Group>}
                {msgs.gui['imKey3-' + v.type] && <Form.Group name={v.type+"Key3"}>
                    <FormLabel>{msgs.gui['imKey3-' + v.type]}</FormLabel>
                    <Form.Control disabled={!isExt}/>
                </Form.Group>}
                {msgs.gui['imIntegrationInfo-' + v.type] && <IconAlert>
                    <div dangerouslySetInnerHTML={{ __html: formatString(msgs.gui.imIntegrationType, msgs.gui['imIntegration-'+v.type]) }}></div>
                    <div dangerouslySetInnerHTML={{ __html: msgs.gui['imIntegrationInfo-' + v.type] }}/>
                </IconAlert>}
                {internalIntegrations.includes(v.type) && <p className="text-info">{msgs.gui.imIntegrationSyncInfo}</p>}

                <Button type="submit" disabled={formik.isSubmitting}>{msgs.gui.actionChange}</Button>
            </>
        }}
        </Form>
    </>
}
