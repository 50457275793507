//@flow
/* eslint-disable no-use-before-define */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {RouteComponentProps, useRouteMatch} from "react-router";
import type {LangInfo} from "../lib/Language";
import msgs, {currentLang, dialogLink, getLangValue, LangContext, langLink, LanguagesOrdered, useMsgs} from "../lib/Language";
import {Breadcrumb, Button, Card, Col, Container, Form as BForm, Row, Tab, Table, Tabs} from "react-bootstrap";
import {
    BreadcrumbItem,
    ConfirmDialog,
    Dialog,
    dialogOpen,
    ForEach,
    NotificationEntry,
    PageHeader
} from "../lib/Components";
import {Events, store} from "../application";
import Notifications from "../lib/Notifications";
import type {
    CompanyApiKey,
    CompanyEdit,
    CompanyInfo,
    CompanyService,
    CompanyUser,
    CompanyUserMore, Dictionaries,
    LangOption,
    OrderInfo,
    UserAgreementInfo,
    UserCompany,
    UserCompanyInfo,
    UserEdit,
    UserPassword,
    UserSettings,
    UserWebNotification,
    VindicationTableInfo
} from "../api";
import {CompanyEditValidation, UserEditInitial, UserEditValidation, UserSettingsValidation} from "../api";
import {Form} from "../lib/Form";
import AddCompanyDialog from "./AddCompanyDialog";
import DesktopScreen from "./DesktopScreen";
import type {DataTableColumnInstance} from "../lib/DataTable";
import DataTable, {ClientDataTable} from "../lib/DataTable";
import {formatMoney, formatTax} from "../lib/Utils";
import {formatDate, formatDateTime} from "../lib/DateTime";
import {getDownloadLink} from "../lib/Upload";
import {useHistory, useLocation} from "react-router-dom";
import {debtInitialCell, DebtProgress, DebtStatus} from "../lib/VindicationsView";
import {UserCompanySettings} from "../lib/UserCompanySettings";
import {emitEvent} from "../lib/Events";
import {agreementComparator, getAgreementGroupLabel} from "../ClientComponents";
import {useRpcQuery} from "../lib/QueryUtils";
import ApiKeyComponent from "../lib/ApiKeyComponent";
import {useQueryClient} from "@tanstack/react-query";


const CompanyServicesTable = ({value}: { value: string}) => {
    const msgs=useMsgs();
    const columns=useMemo<Array<DataTableColumnInstance<OrderInfo>>>(() => {
        return [ {
            accessor: "id",
            className: "md",
            Header: msgs.gui.labelOrderId,
        }, {
            accessor: "type",
            className: "lg",
            Header: msgs.gui.labelService,
            Cell: ({ value }) => value[currentLang.code],
        }, {
            accessor: "target",
            Header: msgs.gui.labelOrderTarget,
            Cell: ({ row }) => <>
                <h1>{row.original.name}</h1>
                {row.original.nip}
            </>,
        }, {
            accessor: "added",
            className: "datetime",
            Header: msgs.gui.labelOrderDate,
            Cell: ({ value }) => formatDate(value),
        }, {
            accessor: "status",
            className: "status",
            Header: msgs.gui.labelStatus,
            Cell: ({ value }) => msgs.gui["status"+value],
        }];
    }, [ msgs.gui.labelFullName ]);
    const queryFunc=useCallback((query) => {
        return store.userApi.getCompanyOrders(value, query);
    }, [ value ]);
    return <DataTable
        columns={columns}
        data={queryFunc}
        initialSortBy="-date"
        defaultLimit={10}
    />

}

const CompanyDebtsTable = ( { value }: { value: string }) => {
    const msgs=useMsgs();
    const [ globalDicts, setGlobalDicts ] = useState<Dictionaries|null>(null);
    const columns=useMemo<Array<DataTableColumnInstance<VindicationTableInfo>>>(() => {
        if(!globalDicts) return null;
        return [{
            accessor: "id",
            Header: msgs.gui.labelDateAndNumber,
            className: "date",
            Cell: ({ row }) => <>
                {formatDate(row.original.date)}<br/>
                {row.original.ecId || row.original.id}
            </>,
        }, {
            accessor: "debtor",
            Header: msgs.gui.labelDebtorWithId,
            Cell: ({ row }) => <>
                <h1>{row.original.debtor}</h1>
                {row.original.extId}
            </>,
        }, {
            accessor: "initial",
            className: "md",
            Header: msgs.gui.labelInitialValue,
            Cell: debtInitialCell,
        }, {
            accessor: "changed",
            Header: msgs.gui.labelActivity,
            className: "date",
            Cell: ({ value }) => formatDate(value)
        }, {
            accessor: "status",
            Header: msgs.gui.labelStatusStage,
            className: "status",
            Cell: ({ row, value }) => <DebtStatus variant="small" status={value} stage={row.original.stage} stageNames={globalDicts.stages}/>,
        }, {
            accessor: "recovered",
            Header: msgs.gui.labelRecovered,
            className: "money",
            Cell: ({ row, value }) => <>
                <h1>{formatMoney(value, row.original.currency, true)}</h1>
                <DebtProgress value={row.original.recovered} max={row.original.actual} label={1}/>
            </>,
        }, {
            accessor: "actual",
            Header: msgs.gui.labelCurrentBalance,
            className: "money",
            Cell: ({ row, value }) => <h1>
                {formatMoney(value, row.original.currency, true)}
            </h1>
        }];
    }, [msgs.gui.labelDateAndNumber, globalDicts]);
    useEffect(() => {
        store.getDictionaries().then(d => setGlobalDicts(d));
    }, [])
    const queryFunc=useCallback((query) => {
            return store.userApi.getCompanyVindications(value, query);
        }, [ value ]);
    if(!globalDicts) return null;

    return <DataTable
        columns={columns}
        data={queryFunc}
        defaultLimit={10}
        initialSortBy="-date"
    />
}

/**
 * Klucze API dla danej firmy
 * @constructor
 */
const CompanyApiKeys = ({ id }: { id: string }) => {
    const query = useRpcQuery("/user", "getCompanyKeys", id );
    const queryClient = useQueryClient();
    const [ dialog, setDialog ] = useState(null);
    if (!query.isSuccess) return null;
    const keys: Array<CompanyApiKey> = query.data;
    if(!Array.isArray(keys)) return <p className="text-info">{msgs.gui.labelNoAccess}</p>

    return <Container>
        {dialog}
        {keys.map((k: CompanyApiKey) => <Row key={k.role}>
            <Col sm={4}>
                {msgs.gui['apiKey'+k.role]}
            </Col>
            <Col sm={6}>
                <ApiKeyComponent
                    value={k.key} label={false}
                    onDisable={() => {
                        setDialog(<ConfirmDialog
                            title={msgs.gui.apiKeyDisableTitle}
                            onHide={() => setDialog(null)}
                            onAccept={async () => {
                                await store.userApi.disableCompanyKey(id, k.role);
                                await queryClient.invalidateQueries([ 'rpc', '/user', 'getCompanyKeys' ])
                                setDialog(null);
                            }}
                        ><div dangerouslySetInnerHTML={{ __html: msgs.gui.apiKeyDisableInfo }}/></ConfirmDialog>)
                    }}
                    onGenerate={async () => {
                        if(k.key) {
                            setDialog(<ConfirmDialog
                                title={msgs.gui.apiKeyRegenerateTitle}
                                onHide={() => setDialog(null)}
                                onAccept={async () => {
                                    await store.userApi.createCompanyKey(id, k.role);
                                    await queryClient.invalidateQueries(['rpc', '/user', 'getCompanyKeys'])
                                    setDialog(null);
                                }}
                            ><div dangerouslySetInnerHTML={{ __html: msgs.gui.apiKeyRegenerateInfo }}/></ConfirmDialog>);
                        } else {
                            await store.userApi.createCompanyKey(id, k.role);
                            await queryClient.invalidateQueries(['rpc', '/user', 'getCompanyKeys'])
                        }
                    }}
                />
            </Col>
        </Row>)}
    </Container>
}

/**
 * Komponent dla ekranu wyświetlającego szczegóły informacji o firmie, do której użytkownik
 * ma dostęp administracyjny.
 */
const UserCompanyDetails = ({ value }: { value: string }) => {
    const msgs=useMsgs();
    const history=useHistory();
    const location=useLocation();
    const [ dataTimestamp, setDataTimestamp ] = useState(0);
    const [ company, setCompany ] = useState<UserCompany>(null);

    useEffect(() => {
        if(!value) {
            setCompany(null);
            return;
        }
        store.userApi.getUserCompany(value).then(company => {
            setCompany(company);
            setDataTimestamp(Date.now());
        });
    }, [ value ])

    if(company==null) return null;

    return <>
        <Card className="blue">
            <Card.Body>
                <Row className="align-items-center">
                    <Col md={9} className="sep-border">
                        <Row className="mb-3">
                            <Col>
                                <h3>{company.name}</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <label>{msgs.gui.labelNIP}</label>
                                {formatTax(company.nip)}
                            </Col>
                            <Col md={3}>
                                <label>{msgs.gui.labelAddress}</label>
                                {company.address}<br/>
                                {company.postalCode} {company.city}
                            </Col>
                            <Col md={3}>
                                <label>{msgs.gui.labelLastLogged}</label>
                                {formatDateTime(company.lastLogged)}
                            </Col>
                            <Col md={3}>
                                <label>{msgs.gui.labelPacket}</label>
                                {getLangValue(company.packet)}
                            </Col>
                        </Row>
                    </Col>
                    <Col md={3} className="text-center">
                        <Button
                            variant="link"
                            onClick={() => {
                                dialogOpen(history, location, EditCompanyDialog.link(company.id), () => {
                                    store.userApi.getUserCompany(value).then(company => {
                                        setCompany(company);
                                    });
                                });
                            }}
                        >
                            <span className="icon-edit d-block font-h2 mb-1"/>
                            {msgs.gui.actionEdit}
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        <h3 className="section">{msgs.gui.labelApiKeys}</h3>
        <CompanyApiKeys id={value}/>
        <h3 className="section">{msgs.gui.titleNewestVindications}</h3>
        <CompanyDebtsTable value={value}/>
        <h3 className="section">{msgs.gui.titleNewestOrders}</h3>
        <CompanyServicesTable value={value}/>
        <Row className="mt-5">
            <Col md={6}>
                <Card className="properties">
                    <Card.Body>
                        <Card.Title>{msgs.gui.titleCompanyUsers}</Card.Title>
                        <div className="left-right">
                            <div>{msgs.gui.labelFullName}</div>
                            <div>{msgs.gui.labelLastLogged}</div>
                        </div>
                        {company.users.map((u: CompanyUser) => <div
                            key={u.id}
                            className="left-right"
                        >
                            <div>
                                <h6>{u.name}</h6>
                                {u.email}
                            </div>
                            <div>
                                {formatDateTime(u.lastLogged)}
                            </div>
                        </div>)}
                    </Card.Body>
                </Card>
            </Col>
            <Col md={6}>
                <Card className="company-notifications">
                    <Card.Body>
                        <Card.Title>{msgs.gui.titleCompanyNotifications}</Card.Title>
                        <ForEach value={company.notifications}>{(n: UserWebNotification, i) => <NotificationEntry key={i} value={n}/>}</ForEach>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Card className="mt-5">
            <Card.Body>
                <Card.Title>{msgs.gui.titleCompanyPrices}</Card.Title>
                <Row className="files">
                    {company.services.map((s: CompanyService, i) => <Col
                        key={i}
                        md={6}
                    ><div className="file-info">
                        <a target="_blank" rel="noopener noreferrer" href={getDownloadLink(s.fileId, s.fileName)}>
                            <span className="icon"><span className="icon-file-empty"/></span>
                            <div className="info">
                                <span className="name">{getLangValue(s.service)}</span>
                            </div>
                        </a></div>
                    </Col>)}
                </Row>
            </Card.Body>
        </Card>
    </>;
}

const CompaniesTable = ({ value, onRowClick }: {
    value: Array<CompanyInfo>|null;
    onRowClick?: (row: CompanyInfo) => void;
} ) => {
    const msgs=useMsgs();
    const columns=useMemo<Array<DataTableColumnInstance<CompanyInfo>>>(() => {
        return [{
            accessor: "tax",
            type: "string",
            Header: msgs.gui.labelNIP,
        }, {
            accessor: "name",
            type: "string",
            Header: msgs.gui.labelCompanyName,
        }, {
            accessor: "vindications",
            type: "number",
            className: "md",
            Header: msgs.gui.labelVindicationsCount
        }, {
            accessor: "users",
            type: "number",
            className: "md",
            Header: msgs.gui.labelUsersCount
        }]
    }, [ msgs.gui.labelCompanyName ]);

    return <ClientDataTable
        columns={columns}
        data={value}
        onRowClick={onRowClick}
    />
}

const UsersTable = ({ value, onRowClick }: {
    value: Array<CompanyUserMore>|null;
    onRowClick?: (row: CompanyUserMore) => void;
} ) => {
    const msgs=useMsgs();
    const columns=useMemo<Array<DataTableColumnInstance<CompanyUserMore>>>(() => {
        return [{
            accessor: "name",
            type: "string",
            Header: msgs.gui.labelFullName,
            Cell: ({ value, row }) => <>
                <h1>{value}</h1>
                {row.original.email}
            </>
        }, {
            accessor: "lastLogged",
            type: "datetime",
            Header: msgs.gui.labelLastLogged,
            Cell: ({ value }) => formatDateTime(value)
        }, {
            accessor: "status",
            className: "status",
            Header: msgs.gui.labelStatus,
            Cell: ({ value }) => msgs.gui["status"+value],
        }]
    }, [ msgs.gui.labelFullName ]);

    return <ClientDataTable
        columns={columns}
        data={value}
        onRowClick={onRowClick}
    />
}

const AgreementsTable = () => {
    const msgs=useMsgs();
    const [ agreements, setAgreements ] = useState<Array<UserAgreementInfo>>(null);

    const refresh=useCallback(() => {
        store.userApi.getUserAgreements().then(agreements => setAgreements(agreements.sort(agreementComparator)));
    }, [ setAgreements ]);

    useEffect(() => {
        refresh();
    }, []);

    if(agreements===null) return null;
    let rows=[];
    let curGroup="";
    agreements.forEach((a: UserAgreementInfo) => {
        const group=getAgreementGroupLabel(a);
        if(group!==curGroup) {
            curGroup=group;
            if(group) rows.push(<tr key={'gr_'+a.name}>
                <td colSpan={4} dangerouslySetInnerHTML={{ __html: group }}/>
            </tr>)
        }
        rows.push(<tr key={a.name}>
            <td className={"cell"+(curGroup?" pl-5":"")} dangerouslySetInnerHTML={{ __html: getLangValue(a.info) }}/>
            <td className="cell date">{formatDate(a.accepted)}</td>
            <td className="cell date">{formatDate(a.withdraw)}</td>
            <td className="cell action">{
                (!a.accepted || a.withdraw)?
                    <Button
                        variant="success"
                        onClick={() => {
                            store.userApi.addAgreement(a.name).finally(refresh);
                        }}
                    >{msgs.gui.actionAgreementAccept}</Button>
                    :<Button
                        variant="warning"
                        onClick={() => {
                            store.userApi.withdrawAgreement(a.name).then(() => {
                                if(a.name==='core') window.location.reload();   // wylogowanie itd
                            }).finally(refresh);
                        }}
                    >{msgs.gui.actionAgreementWithdraw}</Button>
            }</td>
        </tr>);
    });
    return <Table>
        <thead>
        <tr className="header">
            <th>{msgs.gui.labelAgreement}</th>
            <th>{msgs.gui.labelAgreementDate}</th>
            <th>{msgs.gui.labelAgreementWithdrawDate}</th>
            <th/>
        </tr>
        </thead>
        <tbody>{rows}</tbody>
    </Table>
}


type State ={
    page: "settings"|"agreements"|"users"|"companies";
    companies: Array<CompanyInfo>|null;
    users: Array<CompanyUserMore>|null;
    value: UserSettings;
    company: string|null;
}

export default class SettingsScreen extends React.Component<RouteComponentProps<{ page: string }>, State> {
    static urls = [ "/settings/:page", "/settings" ];

    static mainLink="/settings";
    static usersLink="/settings/users";
    static companiesLink="/settings/companies";

    admin: boolean;

    constructor(props) {
        super(props);
        const ui = store.user;
        this.admin = store.user.orgId && store.hasRight("Admin");   // trzeba być w jakieś organizacji
        this.state = {
            page: this.props.match.params.page,
            companies: null,
            users: null,
            value: {
                name: ui.name,
                email: ui.email,
                phone: ui.phone || "",

                notifications: ui.notifications,
                settings: ui.settings,
                userSettings: ui.userSettings,
                language: ui.language,
            },
            company: null,
        }
    }

    refreshUsers() {
        if(this.admin) {
            store.userApi.getUsers().then(users => this.setState({users}));
        }
    }

    refreshCompanies() {
        store.userApi.getCompanies().then(companies => this.setState({ companies }));
    }

    componentDidMount() {
        this.refreshUsers();
        this.refreshCompanies();
        if(this.props.match.params.page) {
            this.props.history.replace(langLink(SettingsScreen.mainLink));
        }
    }

    handleEditUser=(row: CompanyUserMore) => {
        dialogOpen(this, EditCompanyUserDialog.link(row.id), () => this.refreshUsers());
    }

    componentWillUnmount() {
    }

    render() {
        const ui = store.user;
        return <>
            <Breadcrumb>
                <BreadcrumbItem to={DesktopScreen.url}>{msgs.gui.titleSummary}</BreadcrumbItem>
                <BreadcrumbItem active>{msgs.gui.titleSettings}</BreadcrumbItem>
            </Breadcrumb>
            <PageHeader title={msgs.gui.titleSettings}>
                {this.admin ? <Button
                    disabled={!this.admin} className="btn-icon"
                    onClick={() => this.props.history.push(dialogLink(this.props.location, AddCompanyUserDialog.url))}
                ><span className="icon"><span className="icon-user-plus"/></span> {msgs.gui.actionAddUser}</Button> : null}
                <Button
                    className="btn-icon"
                    onClick={() => this.props.history.push(dialogLink(this.props.location, AddCompanyDialog.url))}
                ><span className="icon"><span className="icon-office"/></span> {msgs.gui.actionAddCompany}</Button>
            </PageHeader>
            <Form
                initialValues={this.state.value}
                validate={UserSettingsValidation}
                onSubmit={async (value, helpers) => {
                    let res=await store.userApi.saveUser(value);
                    if(!Form.setError(helpers, res)) {
                        if(res) {
                            store.refreshUser().finally();
                            emitEvent(Events.UserSettings);
                        }
                        return true;
                    }
                }}
            >{(formik) => <Tabs
                defaultActiveKey={this.state.page}
            >
                <Tab eventKey="user" title={msgs.gui.tabSettings}>
                    <Row>
                        {ui.orgId ? <Col md={8}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>{msgs.gui.titleNotificationSettings}</Card.Title>
                                    <Notifications
                                        className="no-bg"
                                        value={formik.values.notifications}
                                        onChange={(notifications) => formik.setFieldValue("notifications", notifications)}
                                        services={store.user.services}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>: null}
                        <Col md={4}>
                            {ui.orgId ? <Card className="mb-5">
                                <Card.Body>
                                    <Card.Title>{msgs.gui.titleOptions}</Card.Title>
                                    <BForm.Group>
                                        <BForm.Label>{msgs.gui.labelLanguage}</BForm.Label>
                                        <div className="d-flex">
                                            {LanguagesOrdered.map((l: LangInfo) => <BForm.Check
                                                key={l.code}
                                                className="flex-grow-1"
                                                id={"select_lang_" + l.code}
                                                custom inline
                                                type="radio"
                                                label={l.name}
                                                checked={formik.values.language === l.code}
                                                onChange={() => formik.setFieldValue("language", l.code)}
                                            />)}
                                        </div>
                                    </BForm.Group>
                                    <Form.Group name="settings.startScreen">
                                        <BForm.Label>{msgs.gui.labelStartScreen}</BForm.Label>
                                        <Form.Select as="select">
                                            <option value="desktop">{msgs.gui.titleSummary}</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Card.Body>
                            </Card> : null}
                            <Card>
                                <Card.Body>
                                    <Card.Title className="d-flex">
                                        <span className="flex-grow-1">{msgs.gui.titleMyData}</span>
                                        <Button
                                            variant="link"
                                            onClick={e => this.props.history.push(dialogLink(this.props.location, ChangePasswordDialog.url))}
                                        >
                                            <span className="icon-lock"/> {msgs.gui.actionChangePassword}
                                        </Button>
                                    </Card.Title>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group name="name" controlId="user-name">
                                                <Form.Label>{msgs.gui.labelFullName}</Form.Label>
                                                <Form.Text/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group name="email" controlId="user-email">
                                                <Form.Label>{msgs.gui.labelEmail}</Form.Label>
                                                <Form.Text readOnly={true}/>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group name="phone" controlId="user-phone">
                                                <Form.Label>{msgs.gui.labelPhone}</Form.Label>
                                                <Form.Text />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col className="text-center">
                            <Form.Save/>
                        </Col>
                        {/*<Form.Submit className="mx-auto">{msgs.gui.actionSave}</Form.Submit>*/}
                    </Row>
                </Tab>
                <Tab eventKey="agreements" title={msgs.gui.tabAgreements}>
                    <AgreementsTable/>
                </Tab>
                {this.admin ? <Tab eventKey="users" title={msgs.gui.tabUsers}>
                    {this.state.users?<UsersTable
                        value={this.state.users}
                        onRowClick={this.handleEditUser}
                    />:null}
                </Tab> : null}
                <Tab eventKey="companies" title={msgs.gui.tabCompanies}>
                    {this.state.company?<UserCompanyDetails
                        value={this.state.company}
                    />:<CompaniesTable
                        value={this.state.companies}
                        onRowClick={(row: UserCompanyInfo) => this.setState({ company: row.id })}
                    />}
                </Tab>
            </Tabs>}</Form>
        </>;
    }
}
SettingsScreen.contextType=LangContext;
SettingsScreen.mainClassName="screen-settings"


export class ChangePasswordDialog extends React.Component<RouteComponentProps, { value: UserPassword }> {
    static url="/settings/password";

    constructor(props) {
        super(props);
        this.state= {
            value: {
                oldPassword: "",
                newPassword: "",
                repeatPassword: "",
                passwordVisible: false
            }
        }
    }


    render() {
        return <Form
            initialValues={this.state.value}
            customValidation={(values: UserPassword) => {
                if(!values.passwordVisible) {
                    if(values.newPassword!==values.repeatPassword) {
                        return { 'repeatPassword': msgs.gui.validatePasswordDifferent }
                    }
                }
            }}
            onSubmit={async (values, helper) => {
                let res=await store.userApi.changePassword(values);
                if(!Form.setError(helper, res)) {
                    this.props.history.goBack();
                }
            }}
        >{(formik) => <Dialog
            title={msgs.gui.actionChangePassword}
            onAccept={(e) => { formik.handleSubmit(e); return false; }}
        >
            <Form.Group name="oldPassword">
                <Form.Label>{msgs.gui.labelOldPassword}</Form.Label>
                <Form.Password
                    toggleVisibility={false}
                />
            </Form.Group>
            <Form.Group name="newPassword">
                <Form.Label>{msgs.gui.labelNewPassword}</Form.Label>
                <Form.Password
                    checkStrength={true}
                    onVisibilityChange={(visible) => {
                        formik.setFieldValue("passwordVisible", visible);
                    }}
                />
            </Form.Group>
            <Form.Group name="repeatPassword">
                <Form.Label>{msgs.gui.labelRepeatPassword}</Form.Label>
                <Form.Password
                    disabled={formik.values.passwordVisible}
                    toggleVisibility={false}
                />
            </Form.Group>

        </Dialog>}</Form>;
    }
}
ChangePasswordDialog.contextType=LangContext;

export const EditCompanyDialog=({ }) => {
    const history=useHistory();
    const math=useRouteMatch<{ id: string }>();
    const [ company, setCompany ] = useState<CompanyEdit>(null);

    useEffect(() => {
        store.userApi.getUserEditCompany(math.params.id).then(c => setCompany(c));
    }, [ math.params.id ]);

    if(company==null) return null;

    return <Form
        initialValues={company}
        validate={CompanyEditValidation}
        onSubmit={async (values, helpers) => {
            let res=store.userApi.updateCompany(values);
            if(Form.setError(helpers, res)) return;
            history.goBack();
            emitEvent(Events.OrganizationChanged);
            store.refreshUser().finally();
        }}
    >{(formik) => <Dialog
        title={msgs.gui.titleEditCompany}
        acceptButton={msgs.gui.actionSave}
        onAccept={() => {
            formik.handleSubmit();
            return false;
        }}
    >
        <Form.FormError/>
        <Form.RowGroup name="tax">
            <Form.Label>{msgs.gui.labelNIP}</Form.Label>
            <Form.NIP readonly={true}/>
        </Form.RowGroup>
        <Form.RowGroup name="name">
            <Form.Label>{msgs.gui.labelCompanyName}</Form.Label>
            <Form.Text/>
        </Form.RowGroup>
        <Form.Row>
            <Form.ColGroup col={6} name="postalCode">
                <Form.Label>{msgs.gui.labelPostalCode}</Form.Label>
                <Form.PostalCode/>
            </Form.ColGroup>
            <Form.ColGroup col={6} name="city">
                <Form.Label>{msgs.gui.labelCity}</Form.Label>
                <Form.Text/>
            </Form.ColGroup>
        </Form.Row>
        <Form.Row>
            <Form.ColGroup col={6} name="address">
                <Form.Label>{msgs.gui.labelAddress}</Form.Label>
                <Form.Text/>
            </Form.ColGroup>
            <Form.ColGroup col={6} name="country">
                <Form.Label>{msgs.gui.labelCountry}</Form.Label>
                <Form.Country/>
            </Form.ColGroup>
        </Form.Row>
    </Dialog>}</Form>
}

EditCompanyDialog.url="/settings/company/:id";
EditCompanyDialog.link=(id: string) => "/settings/company/"+id;

let userRightsCache:Array<LangOption>=null;

export const EditCompanyUserDialog = () => {
    const history=useHistory();
    const math=useRouteMatch<{ id: string }>();
    const [ user, setUser ] = useState<UserEdit>(null);
    const edit=store.hasRight("Admin");
    const [ userRights, setUserRights ] = useState(userRightsCache);
    const [ dicts, setDicts ] = useState(null);
    const [ globalDicts, setGlobalDicts ] = useState(null);

    useEffect(() => {
        store.userApi.getUser(math.params.id).then(u => setUser(u));
    }, [ math.params.id ]);
    useEffect(() => {
        store.getDictionaries().then(d => setGlobalDicts(d));
        store.userApi.getRightsDicts().then(d => setDicts(d));

        if(userRights) return;
        store.userApi.getUsersRights().then(r => setUserRights(userRightsCache=r));
    }, []);

    if(user===null || userRights===null || !dicts || !globalDicts) return null;

    return <Form
        initialValues={user}
        validate={UserEditValidation}
        onSubmit={async (values, helpers) => {
            let res=store.userApi.updateUser(values);
            if(Form.setError(helpers, res)) return;
            history.goBack();
            emitEvent(Events.CompanyUserChange);
        }}
    >{(formik) => <Dialog
        title={msgs.gui.titleEditUser}
        acceptButton={msgs.gui.actionSave}
        onAccept={() => {
            formik.handleSubmit();
            return false;
        }}
    ><Tabs id="user-tabs">
        <Tab eventKey="main" title={msgs.gui.labelMainData}>
            <Form.Row>
                <Col md={12}>
                    <Form.Group name="name">
                        <Form.Label>{msgs.gui.labelFullName}</Form.Label>
                        <Form.Text/>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col md={6}>
                    <Form.Group name="email">
                        <Form.Label>{msgs.gui.labelEmailLogin}</Form.Label>
                        <Form.Email disabled/>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group name="phone">
                        <Form.Label>{msgs.gui.labelPhone}</Form.Label>
                        <Form.Phone/>
                    </Form.Group>
                </Col>
            </Form.Row>
        </Tab>
        <Tab eventKey="organizations" title={msgs.gui.labelOrganizations}>
            <UserCompanySettings
                dicts={dicts}
                globalDicts={globalDicts}
                organizations={formik.values.organizations}
                onChange={org => formik.setFieldValue("organizations", org)}
                readonly={!edit}
                userRights={userRights}
                admin
            />
        </Tab>
    </Tabs>
    </Dialog>}</Form>
}
EditCompanyUserDialog.url="/settings/user/:id";
EditCompanyUserDialog.link=(id: string) => "/settings/user/"+id;

export const AddCompanyUserDialog = () => {
    const history=useHistory();
    const location=useLocation();
    return <Form
        initialValues={UserEditInitial}
        validate={UserEditValidation}
        onSubmit={async (values, helpers) => {
            let res=await store.userApi.addUserToCompany(values);
            if(Form.setError(helpers, res)) return;
            history.replace(dialogLink(location.state.background, EditCompanyUserDialog.link(res)));
            emitEvent(Events.CompanyUserChange);
        }}
    >{(formik) => <Dialog
        title={msgs.gui.titleAddUser}
        acceptButton={msgs.gui.actionAddUser}
        onAccept={() => {
            formik.handleSubmit();
            return false;
        }}
    ><Form.Row>
        <Col md={12}>
            <Form.Group name="name">
                <Form.Label>{msgs.gui.labelFullName}</Form.Label>
                <Form.Text/>
            </Form.Group>
        </Col>
    </Form.Row>
    <Form.Row>
        <Col md={6}>
            <Form.Group name="email">
                <Form.Label>{msgs.gui.labelEmailLogin}</Form.Label>
                <Form.Email/>
            </Form.Group>
        </Col>
        <Col md={6}>
            <Form.Group name="phone">
                <Form.Label>{msgs.gui.labelPhone}</Form.Label>
                <Form.Phone/>
            </Form.Group>
        </Col>
    </Form.Row>
    </Dialog>}</Form>
}
AddCompanyUserDialog.url="/settings/add_user";
AddCompanyUserDialog.link=() => AddCompanyUserDialog.url;
