//@flow
/* eslint-disable no-use-before-define */
import React, {createRef, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
    Alert,
    Breadcrumb,
    Button,
    ButtonProps,
    Col,
    Container,
    Form,
    FormControl,
    InputGroup,
    Modal,
    ModalProps, Overlay,
    OverlayTrigger, Popover,
    Row, Tooltip
} from "react-bootstrap";
import Validate from "./Validation";
import msgs, {currentLang, dialogLink, formatString, getMessage, LangLink, useMsgs} from "./Language";
import {Helmet} from "react-helmet/es/Helmet";
import './commons.scss'
import {__RouterContext as RouterContext, matchPath, RouteChildrenProps} from "react-router";
import type {CountryEntry} from "./Data";
import {Countries} from "./Data";
import Select, {components} from "react-select";
import type {BannerStatus, OrganizationStatus, UOStatus, UserStatus, UserWebNotification} from "../api";
import {Variant} from "react-bootstrap/types";
import {useHistory, useLocation} from "react-router-dom";
import {formatDateTime, parseDate} from "./DateTime";
import {differenceInCalendarDays} from "date-fns";
import BigNumber from "bignumber.js";
import {parseMoney} from "./Utils";
import {RequestError} from "./Network";
import {useEvent} from "./Events";
import cn from 'classnames';

export const simpleChangeEvent = (value, type, name): SyntheticInputEvent => {
    return {
        target: {
            value, type, name
        }
    }
}


/**
 * Pomocniczy obiekt, który zwraca dla każdego zapytania funkcję która zwraca pusty obiekt
 * Do użycia w React-Select, w celu wyłączenia stylów.
 **/
export const reactSelectStyleProxy = new Proxy({}, {
    get: (target, propKey) => {
        return (provided, state) => {
            // if(propKey!=='option') console.log("Get: ", propKey, provided, state);
            if(propKey==="control" || propKey==="valueContainer" || propKey==="container" ||
                propKey==="singleValue" || propKey==="input" || propKey==="indicatorsContainer" ||
                propKey==="indicatorSeparator" || propKey==="dropdownIndicator") return {};
            // return {};
            return provided;
        }
    }
});

export const PasswordInput = ({checkStrength, value, onVisibilityChange, ...props}) => {
    let [ visible, setVisible ]=useState(false);
    let ref=createRef();
    let strength=(checkStrength===true)?Validate.passwordCheck(value):-1;
    return <>
        <Form.Control
            ref={ref}
            as="input"
            type="password"
            value={value}
            {...props}
        />
        <span
            className={"clickable fa fa-fw field-icon "+(visible?"fa-eye-slash":"fa-eye")}
            onClick={() => {
                if(visible===false) ref.current.setAttribute("type", "text");
                else ref.current.setAttribute("type", "password");
                setVisible(!visible);
                if(typeof(onVisibilityChange)==='function') onVisibilityChange(!visible);
            }}
        />
        {strength>=0?
            <span className={"password-strength "+Validate.passwordVariant(strength)}>{Validate.passwordMessage(strength)}</span>
            :null}
    </>;
};

export const EmailInput = ({validate, value, ...props}) => {
    let valid=Validate.validateComponent(value, validate, { email: true });
    return <>
        <Form.Control
            isInvalid={typeof(valid)==='string'}
            as="input"
            type="email"
            value={value}
            {...props}
        />
        {typeof(valid)==='string'?<FormControl.Feedback type="invalid">{valid}</FormControl.Feedback>:null}
    </>
};

export const TextInput = ({validate, value, ...props}) => {
    let valid=Validate.validateComponent(value, validate, {});
    return <>
        <Form.Control
            isInvalid={typeof(valid)==='string'}
            value={value}
            {...props}
        />
        {typeof(valid)==='string'?<FormControl.Feedback type="invalid">{valid}</FormControl.Feedback>:null}
    </>
};

function checkboxInputEvent(checked: boolean|null): SyntheticInputEvent {
    return { target: { checked, type: "click" } }
}

export const EmptyCheckboxInput = (props: {
    checked: boolean|null,
    onChange: (e: SyntheticEvent) => void,
    indeterminate?: boolean,
    id?: string,
}) => {
    const ref=useRef();
    useEffect(() => {
        if(ref.current) {
            ref.current.indeterminate=(props.checked===null && props.indeterminate===true);
        }
    });
    return <Form.Check
        custom
        type="checkbox"
        id={props.id}
        ref={ref}
        checked={props.checked===true}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
            if(props.indeterminate===true) {
                if(props.checked===true) props.onChange(checkboxInputEvent(false));
                else if(props.checked===false) props.onChange(checkboxInputEvent(null));
                else props.onChange(checkboxInputEvent(true));
            } else {
                if(props.checked===true) props.onChange(checkboxInputEvent(false));
                else props.onChange(checkboxInputEvent(true));
            }
        }}
    />
}

/**
 * Typowy komponent do wyboru statusu jednego z 4 nowy, aktywny, tymczasowo zablokowany, zablokowany
 */
export const StatusInput = ({ value, onChange, rejected, notAccepted, showNew }: {
    rejected?: boolean; notAccepted?: boolean;
    showNew?: boolean;
    value: UserStatus|UOStatus|BannerStatus|OrganizationStatus;
    onChange: (value: UserStatus|UOStatus|BannerStatus|OrganizationStatus) => void;
}) => {
    const initialValue=useRef(value);
    const newVisible=(showNew===true) || (initialValue.current==="New" || !initialValue.current);
    // console.log("Value: ", value, "Initial: ", initialValue.current, "New Visible: ", newVisible);

    return <Form.Control
        as="select" value={value} onChange={(e) => onChange( e.target.value )}
    >
        {rejected?<option value="Rejected">{msgs.gui.statusRejected}</option>:null}
        {notAccepted?<option value="NotAccepted">{msgs.gui.statusNotAccepted}</option>:null}
        {newVisible?<option value="New">{msgs.gui.statusNew}</option>:null}
        <option value="Active">{msgs.gui.statusActive}</option>
        <option value="TemporaryBlocked">{msgs.gui.statusTemporaryBlocked}</option>
        <option value="Blocked">{msgs.gui.statusBlocked}</option>
    </Form.Control>
}


let waitGlassCounter=0;
let waitGlassLongTimer=null;
export function controlWaitGlass(enter: boolean) {
    let glass=document.getElementById("wait-glass");
    if(enter===true) {
        if(waitGlassCounter===0) {
            glass.style.display = 'block';
            glass.style.cursor=null;
            waitGlassLongTimer=window.setTimeout(() => {
                waitGlassLongTimer=null;
                glass.style.cursor='wait';
            }, 600);
        }
        waitGlassCounter++;
    } else {
        --waitGlassCounter;
        if(waitGlassCounter===0) {
            glass.style.display='none';
            if(waitGlassLongTimer) {
                window.clearTimeout(waitGlassLongTimer);
                waitGlassLongTimer=null;
            }
        }
    }
}

export function waitGlassWrap<T>(func: T): T {
    return (...args) => {
        return new Promise((resolve, reject) => {
            controlWaitGlass(true);
            func(...args).then(resolve).catch(reject).finally(() => controlWaitGlass(false));
        })
    }
}

/**
 * Funkcja aktywuje panel blokujący kursor myszy oraz zwraca funkcję,
 * którą należy wywołać w celu usunięcia panelu. Funkcję można przekazać
 * do finally dla Promise.
 * @return {function(): void}
 */
export function waitGlass(): () => void {
    let removed=false;
    controlWaitGlass(true);
    return () => {
        if(removed) return;
        removed=true;
        controlWaitGlass(false);
    }
}

export const ContactInfo = () => {
    const msgs=useMsgs();
    return <div className="contact-info">
        {msgs.links.email?<div>
            <img src="/resources/img/footer/email.png"
                 srcSet="/resources/img/footer/email.png 1x, /resources/img/footer/email@2x.png 2x"
                 alt="Ikona e-maila" className="email"/>
            <a href={"mailto:"+msgs.links.email}>{msgs.links.emailLabel}</a>
        </div>:null}
        {msgs.links.phone?<div>
            <img src="/resources/img/footer/phone.png"
                 srcSet="/resources/img/footer/phone.png 1x, /resources/img/footer/phone@2x.png 2x"
                 alt="Ikona telefonu" className="phone"/>
            <a href={"tel:"+msgs.links.phone}>{msgs.links.phoneLabel}</a>
        </div>:null}
    </div>
}

export const AuthPage=({ title, children, header, footer, className } : {
    title: string;
    header?: React$Node;
    footer?: React$Node|false;
    className?: string;
}) => {
    return <>
        <Modal.Dialog className={className}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {header ? header : <Modal.Header>
                <Modal.Title>
                    <h1>{title}</h1>
                </Modal.Title>
            </Modal.Header>}
            {children}
        </Modal.Dialog>
        {footer===false?null:<div className="auth-info">
            {footer?footer:<a href={msgs.gui.authLink} target="_blank" rel="noopener noreferrer">{msgs.gui.authInfo}</a>}
        </div>}
    </>;
}

export const AuthInfoScreen = (props: {
    title: string, tabTitle?: string, variant?: string,
    html?: string, help?: boolean, children: any,
    footer?: React$Node|false;
    content?: React$Node;
}) => {
    const tabTitle=props.tabTitle || props.title;
    return (
        <AuthPage title={tabTitle} footer={props.footer}>
            <Modal.Body>
                <Alert variant={props.variant?props.variant:"info"}>
                    <div className="icon in-circle">
                        <Icon.Info/>
                    </div>
                    {props.html?<div
                        className="content" dangerouslySetInnerHTML={{ __html: props.html }}
                    />:props.children}
                </Alert>
                {props.content}
                {props.help===true?<div className="text-center"><ContactInfo/></div>:null}
            </Modal.Body>
        </AuthPage>
    );
}

export const SearchField = (props: {value: string, onChange: (value: string) => void}) => {
    return (
        <InputGroup>
            <FormControl type="text" placeholder="Filtruj" value={props.value} onChange={e => props.onChange(e.target.value)} />
            <InputGroup.Append>
                <Button variant="outline-success"><i className="fa fa-search"/></Button>
            </InputGroup.Append>
        </InputGroup>
    )
}

export const NotificationButton = ({ message, children, ...props }: ButtonProps & { message?: React$Node }) => {
    const buttonRef=useRef();
    const [ show, setShow ] = useState(false);
    let id=null;
    if(typeof(message)==='object') {
        if(message.message || message.id) {
            id=message.id;
            message=message.message;
        } else {
            message=null;
        }
    }
//    console.log("NotificationButton ", { message, id });

    useEffect(() => {
        setShow(!!message);
    }, [ message, id ])
    useEffect(() => {
        if(show && message) {
            const timer=window.setTimeout(() => setShow(false), 3000);
            return () => window.clearTimeout(timer);
        }
    }, [ show ])

    return <>
        <Button ref={buttonRef} {...props}>{children}</Button>
        {message && <Overlay target={buttonRef} show={show} placement="top">
            <Popover id="button-message" className="button-notification">
                <Popover.Content>
                    {message}
                </Popover.Content>
            </Popover>
        </Overlay>}
    </>
}

export type DialogProps = ModalProps & {
    titleClassName?: string;
    title: string;
    /** Zamknięcie okna oznacza wstecz w historii */
    closeIsBack?: boolean;
    /** Czy ma być przycisk zamykania okienka, domyślnie tak */
    closeButton?: boolean;
    /** Funkcja wywoływana w przypadku zamknięcia okna. Gdy funkcja zwróci false, to okno nie jest zamykane */
    onHide?: () => boolean|void;
    /** Jaka ma być stópka, gdy null/brak, domyślnie dwa przyciski */
    footer?: React$Node;
    cancelButton?: null|undefined|string;
    /** Funkcja wywoływana w przypadku wciśnięcia anuluj; gdy funkcja zwróci false, to okno nie jest zamykane */
    onCancel?: () => boolean|void;
    acceptButton: null|undefined|string;
    /** Czy akcja akceptacji jest zablokowana */
    acceptDisabled?: boolean;
    /** Opcjonalny komunikat do wyświetlenia dla przycisku dalej */
    acceptMessage?: React$Node;
    /** Własny przycisk akceptowania */
    acceptCustom?: React$Node;
    /** Funkcja wywoływana w przypadku wciśnięcia akceptuj; gdy funkcja zwróci false, to okno nie jest zamykane */
    onAccept: () => boolean|void|Promise<void>|Promise<boolean>;
    /** Czy okno można zamknąć przy pomocy klawiatury, domyślnie nie */
    keyboard?: boolean;
    /** Rodzaj tła dla okna; domyślnie 'static' */
    backdrop?: 'static'|true|false;
    /** Czy okno wewnętrzne, bez obsługi historii */
    internal?: boolean;

    /** Czy obsługa przewijania */
    scrollable?: boolean;

    dialogRef?: React$Ref;

    onEntering?: () => void;
    onEntered?: () => void;
}

export function closeDialog(history, location) {
    // czy mamy "tło"
    if(location.state && location.state.background) {
        // jeżeli tak, to normalny powrót przez wstecz
        history.goBack();
    } else {    // jeżeli nie, to wejście przez stronę (link) i należy sprawdzić
        console.log("History: ", history);
    }
}

let _dialogCloseCallback=null;
export const Dialog = ({ title, titleClassName, children, closeButton, onHide, size, keyboard, backdrop,
    cancelButton, onCancel, acceptButton, onAccept, acceptCustom, footer, closeIsBack, internal, dialogRef, scrollable,
    acceptMessage, acceptDisabled,
    ...props }: DialogProps) => {
    const location=useLocation();
    const history=useHistory();
    const closeCallback=useRef();

    useEffect(() => {
        if(internal!==true && (!location.state || !location.state.background)) {
            console.log("Missing background");
            history.replace("/");
            return;
        }
        closeCallback.current=_dialogCloseCallback;
        _dialogCloseCallback=null;
        if(typeof(closeCallback.current)!=='function') return undefined;
        return closeCallback.current;
    }, []);

    const handleHide=useCallback(() => {
        if(typeof(onHide)==='function') {
            if(onHide()===false) return;
        }
        if(internal===true || closeIsBack===false) return;
        closeDialog(history, location);
    }, [ onHide, closeIsBack, location ]);

    const handleAccept=useCallback(() => {
        if(typeof(onAccept)==='function') {
            Promise.resolve(onAccept()).then((res) =>{
                if(res===false) return;
                handleHide();
            });
            return;
        }
        handleHide();
    }, [ handleHide, onAccept ]);

    const handleCancel=useCallback(() => {
        if(typeof(onCancel)==='function') {
            if(onCancel(this)===false) return;
        }
        handleHide();
    }, [ handleHide, onCancel ]);

    return <Modal
        show={true}
        size={size?size:"lg"}
        onHide={handleHide}
        keyboard={keyboard===true}
        backdrop={backdrop===undefined?'static':backdrop}
        ref={dialogRef}
        scrollable={typeof(scrollable)==='boolean'?scrollable:true}
        {...props}
    >
        <Modal.Header closeButton={closeButton!==false}>
            <Modal.Title className={titleClassName}>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {children}
        </Modal.Body>
        <Modal.Footer>
            {footer?footer:<>
                {cancelButton===null?null:
                    <Button onClick={handleCancel}>
                        {cancelButton===undefined?msgs.gui.buttonCancel:cancelButton}
                    </Button>}
                {acceptCustom || (acceptButton===null || !onAccept?null:
                    <NotificationButton onClick={handleAccept} message={acceptMessage} disabled={acceptDisabled}>
                        {acceptButton===undefined?msgs.gui.buttonSave:acceptButton}
                    </NotificationButton>)}
            </>}
        </Modal.Footer>
    </Modal>;
}
type OpenFunction =
    (history: History, location: Location, to: string, closeCallback?: () => void) => void &
    (comp: React$Component<RouteChildrenProps>, to: string, closeCallback?: () => void) => void;

export const dialogOpen: OpenFunction=(history: History, location: Location, to: string, closeCallback?: () => void) => {
    if(typeof(history)==='object' && history.props && history.props.history && history.props.location) {
        return dialogOpen(history.props.history, history.props.location, location, to);
    }
    _dialogCloseCallback=closeCallback;
    history.push(dialogLink(location, to));
}

export function getDialogCloseCallback(): null|() => void {
    return _dialogCloseCallback;
}

export const InlineDialog = ({ title, titleClassName, children, closeButton, onHide, size, keyboard, backdrop,
                                 cancelButton, onCancel, acceptButton, onAccept, footer, closeIsBack, internal,
                                 acceptVariant, show, ...props }: DialogProps) => {
    if(show===undefined) show=true;
    return <Modal
        show={show}
        size={size?size:"lg"}
        onHide={onHide || onCancel}
        keyboard={keyboard===true}
        backdrop={backdrop===undefined?'static':backdrop}
        {...props}
    >
        <Modal.Header closeButton={closeButton!==false}>
            <Modal.Title className={titleClassName}>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {children}
        </Modal.Body>
        <Modal.Footer>
            {footer?footer:<>
                {cancelButton===null?null:
                    <Button onClick={onCancel}>
                        {cancelButton===undefined?msgs.gui.buttonCancel:cancelButton}
                    </Button>}
                {acceptButton===null || !onAccept?null:
                    <Button variant={acceptVariant} onClick={typeof(onAccept)==='function'?onAccept:undefined} disabled={onAccept===true}>
                        {acceptButton===undefined?msgs.gui.buttonSave:acceptButton}
                    </Button>}
            </>}
        </Modal.Footer>
    </Modal>;
}

const CaptchaImage = ({ onNewImage }: {
    onNewImage?: () => void;
}) => {
    const [ time, setTime ]=useState<number>(() => Date.now());
    const timeHandle=useRef();
    const [ renew, setRenew ] = useState(false);
    const msgs=useMsgs();

    const handleRenew=useCallback(() => {
        const elapsed=4000-(Date.now()-time);
        if(elapsed<0) {
            setTime(Date.now());
            if(onNewImage) onNewImage();
            return;
        }
        timeHandle.current=window.setTimeout(() => {
            setRenew(false);
            setTime(Date.now());
            if(onNewImage) onNewImage();
        }, elapsed);
        setRenew(true);
    }, [ time, onNewImage ]);
    useEffect(() => {
        return () => {
            if(timeHandle.current) window.clearTimeout(timeHandle.current);
        }
    }, []);
    return <div className={"captcha"+(renew?" renew":"")}>
        <img src={"/data/captcha/"+time+".png"} width={200} height={50} title="Captcha"/>
        <Button variant="outline-secondary" onClick={handleRenew} disabled={renew}>{msgs.gui.buttonCaptchaRenew}</Button>
    </div>
}

export const ConfirmDialog = ({ title, children, onHide, onAccept, captcha, accept, cancel, variant }: {
    title: string|React$Node;
    children: string|React$Node;
    onHide: () => void;
    onAccept: ((captcha: string) => Promise<boolean>) | (() => void) | () => Promise<void>;
    captcha?: boolean;
    accept?: string|React$Node;
    cancel?: string|React$Node;
    variant?: Variant;
}) => {
    const msgs=useMsgs();
    const [ captchaInput, setCaptchaInput ] = useState<string>("");
    const [ captchaError, setCaptchaError ] = useState<boolean>(false);
    const [ processing, setProcessing ] = useState(false);
    const handleAccept=useCallback(async () => {
        setProcessing(true);
        try {
            if (!captcha) {
                await onAccept();
                return;
            }
            if (!await onAccept(captchaInput)) {
                setCaptchaError(true);
                setCaptchaInput("")
            } else {
                setCaptchaError(false);
            }
        }finally {
            setProcessing(false);
        }
    }, [ captcha, captchaInput, onAccept ])
    return <Modal
        show={true}
        onHide={onHide}
        backdrop={'static'}
        size="lg"
    >
        <Modal.Header closeButton>
            <Modal.Title className>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {children}
            {captcha && <Form>
                <p>{msgs.gui.captchaMessage}</p>
                <Form.Group>
                    <CaptchaImage onNewImage={() => setCaptchaInput("")}/>
                    <Form.Control value={captchaInput} onChange={e => {
                        setCaptchaInput(e.target.value);
                        setCaptchaError(false);
                    }}/>
                    {captchaError && <Form.Control.Feedback type="invalid">{msgs.gui.captchaError}</Form.Control.Feedback>}
                </Form.Group>
            </Form>}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onHide} disabled={processing}>{cancel || msgs.gui.buttonCancel}</Button>
            <Button variant={variant} onClick={handleAccept} disabled={processing || (captcha && !captchaInput)}>{accept || msgs.gui.buttonOK}</Button>
        </Modal.Footer>
    </Modal>;
}

type IconProps={
    icon: string;
}

export class Icon extends React.Component<IconProps, void> {
    static Delete= () => <i className="fas fa-trash-alt"/>;
    static Notification = () => <i className="far fa-bell"/>;
    static Building = () => <i className="far fa-building"/>;
    static Search = () => <i className="fas fa-search"/>;
    static Export = () => <i className="fas fa-file-export"/>
    static ExportTable = () => <i className="far fa-file-excel"/>
    static Download = () => <i className="fas fa-cloud-download-alt"/>
    static Save = () => <i className="far fa-save"/>
    static PDF = () => <i className="far fa-file-pdf"/>
    static Refresh = () => <i className="fas fa-sync-alt"/>
    static Warning = () => <i className="fas fa-exclamation-circle"/>
    static Comment = () => <i className="fas fa-comment"/>
    static Phone = () => <i className="fas fa-phone"/>
    static Check = ({ className }: { className?: string }) => <i className={cn("fas fa-check",className)}/>
    static Email = () => <i className="far fa-envelope"/>
    static MoneyBill = () => <i className="fas fa-money-bill-alt"/>
    static Add = () => <i className="fas fa-plus-circle"/>
    static Undo = () => <i className="fas fa-undo-alt"/>
    static Info = () => <i className="fas fa-info"></i>
    static AddRow = () => <i className="far fa-plus-square"/>
    static Send = () => <i className="fas fa-paper-plane"/>
    static Checked = () => <i className="fas fa-check-circle"/>
    static Calendar = () => <i className="far fa-calendar-alt"/>
    static AddUser = () => <i className="fas fa-user-plus"/>
    /** Kula ziemska - użycie - numer IP */
    static Globe = () => <i className="fas fa-globe-europe"/>
    static Edit = () => <i className="fas fa-pen"/>
    static Location = () => <i className="fas fa-map-marker-alt"/>
    static Eye = () => <i className="far fa-eye"/>
    static Import = () => <i className="fas fa-file-import"/>
    static Settings = () => <i className="fas fa-cog"/>
    static Exit = () => <i className="fas fa-sign-out-alt"/>
    static Pause = () => <i className="fas fa-pause"/>
    static Play = () => <i className="fas fa-play"/>

    /* Pozostałe ikony bazujące na danych od firmy graficznej */
    static EULEO = () => {
        return <img className="euleo-logo" src={process.env.REACT_APP_SERVER+"/resources/img/euleo-logo.svg"} alt="EULEO logo"/>;
    }
    static EULEOWhite = () => {
        return <img className="euleo-logo" src={process.env.REACT_APP_SERVER+"/resources/img/euleo-logo-white.svg"} alt="EULEO logo"/>;
    }
    static Logo = () => {
        const msgs=useMsgs();
        return <img className="auth-logo" src={process.env.REACT_APP_SERVER+"/resources/img/logo-white-"+currentLang.code+".svg"} alt="RozważnaFirma logo"/>;
    }
    static Plane = () => <img src="/resources/img/alerts/plane.png" srcSet="/resources/img/alerts/plane.png 1x, /resources/img/alerts/plane@2x.png 2x" alt="Plane"/>
    static RoundOK = () => <img src="/resources/img/alerts/done.png" srcSet="/resources/img/alerts/done.png 1x, /resources/img/alerts/done@2x.png 2x" alt="OK"/>;
    static Help = () => (<svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" enableBackground="new 0 0 24 24" viewBox="0 0 24 24"><g><rect fill="none" height="24" width="24"/></g><g><path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M19.46,9.12l-2.78,1.15 c-0.51-1.36-1.58-2.44-2.95-2.94l1.15-2.78C16.98,5.35,18.65,7.02,19.46,9.12z M12,15c-1.66,0-3-1.34-3-3s1.34-3,3-3s3,1.34,3,3 S13.66,15,12,15z M9.13,4.54l1.17,2.78c-1.38,0.5-2.47,1.59-2.98,2.97L4.54,9.13C5.35,7.02,7.02,5.35,9.13,4.54z M4.54,14.87 l2.78-1.15c0.51,1.38,1.59,2.46,2.97,2.96l-1.17,2.78C7.02,18.65,5.35,16.98,4.54,14.87z M14.88,19.46l-1.15-2.78 c1.37-0.51,2.45-1.59,2.95-2.97l2.78,1.17C18.65,16.98,16.98,18.65,14.88,19.46z"/></g></svg>);
    static Danger = () => (<svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/></svg>);
    static Connected = () => (<svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24" ><path d="M0 0h24v24H0z" fill="none"/><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM10 17l-3.5-3.5 1.41-1.41L10 14.17 15.18 9l1.41 1.41L10 17z"/></svg>);
    static Login = () => (<svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" enableBackground="new 0 0 24 24" viewBox="0 0 24 24"><g><rect fill="none" height="24" width="24"/></g><g><path d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z"/></g></svg>);

    render() {
        return <i className={"fas fa-"+this.props.icon}/>;
    }
}

const CountryOption = ({children, value, ...props}) => {
    return <components.Option {...props}>
        <img className="flag" src={Countries.img(value)} alt={"Flag of "+value}/> {children}
    </components.Option>;
}

const CountryValueContainer=({children, data, ...props}) => {
    return <components.SingleValue {...props}>
        <img className="flag" src={Countries.img(data.code)} alt={"Flag of "+data.code}/> {children}
    </components.SingleValue>;
}

export const CountryInput = ({value, onChange, name, readonly, className, ...props} : { value: string, onChange: (value: string) => void, name?: string}) => {
    return <Select
        className={"react-select country-select "+(className || "")}
        classNamePrefix="react-select"
        styles={reactSelectStyleProxy}
        options={Countries.all}
        components={{
            Option: CountryOption,
            SingleValue: CountryValueContainer,
        }}
        disabled={readonly}
        getOptionLabel={(c: CountryEntry) => c.name}
        getOptionValue={(c: CountryEntry) => c.code}
        placeholder={msgs.gui.comboboxSelect}
        noOptionsMessage={() => msgs.gui.comboboxNoOptions}
        isSearchable={true}
        value={Countries.byCode.get(value)}
        isClearable={false}
        onChange={(c: CountryEntry) => onChange(simpleChangeEvent(c.code, "text", name))}
        menuPortalTarget={document.body}
        {...props}
    />
}


type NIPInputProps = {
    value: string;
    onChange: (value: string) => void;
    countryValue: string;
    onCountryChange: (country: string) => void;
    readonly ?: boolean;

    /* Z React-Select */
    menuPlacement: "bottom"|"auto"|"top";
    menuPosition: "absolute"|"fixed";
    menuPortalTarget: null|HTMLElement;
    maxMenuHeight?: string;
}


export const NIPInput = ({countryValue, onCountryChange, isInvalid, readonly, className,
                             menuPlacement, menuPosition, menuPortalTarget, maxMenuHeight, ...props }: NIPInputProps) => {
    const onChange=useCallback((c) => {
        // console.log("OnChange: ", c);
        if(!Array.isArray(c) || c.length===0) onCountryChange("");
    }, [ onCountryChange ]);
    // console.log("Value: ", countryValue);
    return <InputGroup className={isInvalid?"is-invalid":null}>
        <Select
            className={"input-group-prepend react-select country-select "+(className || "")}
            classNamePrefix="react-select"
            options={Countries.all}
            isDisabled={readonly===true}
            components={{
                Option: CountryOption,
                SingleValue: ({children, data, ...props}) => (
                    <components.SingleValue {...props}>
                        <img className="flag" src={Countries.img(data.code)} alt={"Flag of "+data.code}/> {data.iso}
                    </components.SingleValue>),
            }}
            styles={reactSelectStyleProxy}
            isClearable={false}
            isSearchable={!readonly}
            placeholder={msgs.gui.comboboxSelect}
            noOptionsMessage={() => msgs.gui.comboboxNoOptions}
            getOptionLabel={(c: CountryEntry) => c.name}
            getOptionValue={(c: CountryEntry) => c.code}
            value={Countries.byCode.get(countryValue)}
            onChange={(c: CountryEntry) => onCountryChange(c.code)}
            menuPlacement={menuPlacement} menuPosition={menuPosition} menuPortalTarget={menuPortalTarget}
            maxMenuHeight={maxMenuHeight}
        />
        <Form.Control
            as="input"
            type="text"
            readOnly={readonly}
            disabled={readonly}
            isInvalid={isInvalid}
            maxLength={16}
            {...props}/>
    </InputGroup>
}

/**
 * Nakładka na NIPInput, która łączy dwie wartości w jedną i wartość to odpowiednio
 * dwa znaki kraju oraz wartość NIPu.
 */
export const CombinedNIPInput = ({ value, onChange, name, ...props }: { value: string, onChange: (value: string) => void }) => {
    if(typeof(value)!=='string') value='';
    const country=(!value || value.length<2)?"PL":value.substr(0, 2);
    const nip=(!value || value.length<3)?"":value.substr(2);
    return <NIPInput
        value={nip}
        onChange={(e) => onChange(simpleChangeEvent(country+e.target.value, 'text', name)) }
        countryValue={country}
        onCountryChange={(country) => onChange(simpleChangeEvent(country+nip, 'text', name)) }
        {...props}
    />
}

/** Informacja o zgodzie */
export type TermInfo = {
    /** Identyfikator systemowy */
    id: string;
    /** Nazwa wyświetlana */
    label: string|React$Node;
    /** Czy zgoda wymagana */
    required?: boolean;
}

export const IconButton = ({ icon, block, className, size, children, ...props }: ButtonProps & {
    icon: string; block?: boolean
}) => {
    return <Button
        size={size || "xl"}
        className={"align-items-center btn-icon "+(block?"d-flex ":"d-inline-flex ")+(className||"")}
        {...props}
    >
        <span className="icon">{icon}</span> <span className="flex-fill">{children}</span>
    </Button>
}

export const staticPageUrl="/info/:name";
export const staticPageLink=(page: string) => "/info/"+page;

export const contactUrl="/contact";

export const Footer =({ simple }): {
    simple?: boolean
} => {
    const msgs=useMsgs();
    return <footer>
        <Container className="d-flex align-items-top">
            <div className="flex-grow-1 ">
                {simple===true?null:<ul>
                    <li><LangLink to={contactUrl}>{msgs.gui.linkContact}</LangLink></li>
                    <li><LangLink to={staticPageLink("privacy_policy")}>{msgs.gui.linkPrivacyPolicy}</LangLink></li>
                    <li><LangLink to={staticPageLink("regulations")}>{msgs.gui.linkTerms}</LangLink></li>
                    <li><LangLink to={staticPageLink("rodo")}>{msgs.gui.linkRODO}</LangLink></li>
                    {msgs.links.faq?<li><a href={msgs.links.faq} target="_blank" rel="noopener noreferrer">{msgs.links.faqLabel}</a></li>:null}
                    {msgs.links.euleo?<li><a href={msgs.links.euleo} target="_blank" rel="noopener noreferrer">{msgs.links.euleoLabel}</a></li>:null}
                </ul>}
                <p className="text-info">{msgs.gui.labelFooterCompanyInfo}</p>
            </div>
            <div className="contacts">
                <div>
                    <h3 className="font-weight-normal pb-3">{msgs.gui.labelNeedHelp}</h3>
                </div>
                <Row>
                    <Col sm={6} className="pb-3">
                        <img src="/resources/img/footer/email.png"
                             srcSet="/resources/img/footer/email.png 1x, /resources/img/footer/email@2x.png 2x"
                             alt="Ikona e-maila" className="email"/>
                        <a href={"mailto:"+msgs.links.email}>{msgs.links.emailLabel}</a>
                    </Col>
                    <Col sm={6} className="pb-3">
                        <img src="/resources/img/footer/phone.png"
                             srcSet="/resources/img/footer/phone.png 1x, /resources/img/footer/phone@2x.png 2x"
                             alt="Ikona telefonu" className="phone"/>
                        <a href={"tel:"+msgs.links.phone}>{msgs.links.phoneLabel}</a>
                    </Col>
                </Row>
            </div>
        </Container>
    </footer>
}

export const ErrorMessage=({ as, message, className, block, children }: {
    message?: string;
    as?: string;
    className?: string;
    block?: boolean;
}) => {
    const Component= as || "h3";
    if(!message && !children) {
        if(block!==true) return null;
        children=<>&nbsp</>;
    }
    return <Component className={"text-center mb-3 text-danger "+(className||"")}>
        {message?getMessage(message):null}
        {children}
    </Component>
}

export const ActionButton = ({ size, children, ...props }: { size?: string } & ButtonProps) => {
    return <Button size={size || "lg"} {...props}>{children}</Button>
}

export const DeleteButton = ({ size, children, onClick, confirm, className, ...props }:
                                 { size?: string, confirm?: boolean|string } & ButtonProps
) => {
    const msgs=useMsgs();
    return <Button
        size={size}
        onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if(confirm===true) {
                if(!window.confirm(msgs.gui.deleteConfirm)) {
                    return;
                }
            } else if(typeof(confirm)==='string' && confirm.length>0) {
                if(!window.confirm(confirm)) {
                    return;
                }
            }
            if(onClick) onClick(e);
        }}
        className={"delete-button "+ (className || "")}
        {...props}
    ><Icon icon="trash"/> {children}</Button>
}
export const PageHeader = ({title, custom, children}: {
    title: string,
    custom?: boolean,
}) => (<div className="page-header">
    <Helmet>
        <title>{title} - Rozważna Firma</title>
    </Helmet>
    {custom !== true ? <h1>{title}</h1> : null}
    {children}
</div>);

export const BreadcrumbItem = ({to, children, ...props}) => <Breadcrumb.Item
    linkAs={to?LangLink:undefined}
    linkProps={to?{to: to}:undefined}
    {...props}
>{children}</Breadcrumb.Item>;

export const IconAlert = ({ children, icon, variant, className, iconClassName }: {
    children: React$Node|string;
    icon?: React$Node;
    variant?: Variant;
    className?: string;
    iconClassName?: string;
}) => <Alert variant={variant || "info"} className={className}>
    <div className={typeof(iconClassName)!=='string'?"icon in-circle":iconClassName}>
        {icon?icon:<Icon.Info/>}
    </div>
    {typeof(children)==='string'?<div
            className="content"
            dangerouslySetInnerHTML={{ __html: children }}
        />:<div className="content">{children}</div>}
</Alert>;

/** Pomocniczy komponent, który jest nakładką dla IconAlert z konkretnymi ustawieniami */
export const SendAlert = ({ children, className }: { className?: string }) => {
    return <IconAlert
        iconClassName="icon"
        className={className}
        icon={<img src="/resources/img/alerts/plane.png" srcSet="/resources/img/alerts/plane.png 1x, /resources/img/alerts/plane@2x.png 2x" alt=""/>}
    >{children}</IconAlert>;
}

type ActiveSwitchProps = {
    location?: {};
    children: React$Node;
    /** Id elementu, w którym ma być ustawiona klasa */
    elementId: string;
}

export class ActiveSwitch extends React.Component<ActiveSwitchProps> {
    setClassName(cn: string): boolean {
        const el=document.getElementById(this.props.elementId);
        if(!el) return false;
        if(el.className!==cn) el.className=cn;
        return true;
    }

    updateClassName(cn: string) {
        if(!this.setClassName(cn)) {
            window.setTimeout(() => this.setClassName(cn), 0);
        }
    }

    render() {
        return (
            <RouterContext.Consumer>
                {context => {
                    const location = this.props.location || context.location;
                    let element, match;
                    // console.log("Switch for: ", location);

                    React.Children.forEach(this.props.children, child => {
                        if (match == null && React.isValidElement(child)) {
                            element = child;
                            const path = child.props.path || child.props.from;
                            match = path
                                ? matchPath(location.pathname, {...child.props, path})
                                : context.match;
                            // console.log("Checking for: ", !!match, path, child.props);
                        }
                    });
                    this.updateClassName((match && element && element.props && element.props.component)?element.props.component.mainClassName || "":"");
                    return match
                        ? React.cloneElement(element, {location, computedMatch: match})
                        : null;
                }}
            </RouterContext.Consumer>
        );
    }
}

export const Timeline = ({ children, className }) => {
    return <div className={"timeline "+(className||"")}>
        <div className="line"/>
        {children}
    </div>
}

export const TimelineEntry = ({ title, date, children, info }: {
    title: React$Node|string;
    info?: React$Node|string;
    date: string|Date;
}) => {
    return <div className="entry">
        <div className="header">
            <h1>{title}</h1>
            <div className="dot"/>
            <div className="date">{formatDateTime(date)}</div>
        </div>
        {!info?null:<div className="info">{info}</div>}
        <div className="content">{children}</div>
    </div>
}

export const DisplayHTML=({ value, className }: { value: string }) => {
    if(!value) return null;
    if(value.startsWith("data:text/html")) {
        return <iframe className={className} src={value}/>;
    }
    return <div className={className} dangerouslySetInnerHTML={{ __html: value }} />;
}

export function ForEach<T>({ value, children, limit, emptyInfo, loadMore, rowsLoad }: {
    value: Array<T>;
    children: (item: T, index: number) => any;
    limit?: number;
    emptyInfo?: any;
    loadMore?: React$Node|true;
    rowsLoad?: number;
}) {
    const [ newLimit, setNewLimit ] = useState(limit);
    const msgs=useMsgs();
    if(value===null) return null;
    if(value.length===0) {
        if(emptyInfo!==undefined) return emptyInfo;
        return <p className="text-info">{msgs.gui.dataTableEmpty}</p>
    }
    let to=value.length;
    if((typeof(newLimit)==='number') && to>newLimit) to=newLimit;

    let res=[];
    for(let i=0;i<to;++i) res.push(children(value[i], i));
    if(to<value.length && loadMore!==undefined) {
        if(loadMore===true) {
            res.push(<div className="load-more" key="load-more">
                <Button variant="outline-secondary" onClick={() => setNewLimit(newLimit+(typeof(rowsLoad)==='number'?rowsLoad:25))}>{msgs.gui.dataTableLoadMore}</Button>
                <p className="text-muted">{formatString(msgs.gui.tableItemsLeft, value.length-to)}</p>
            </div>)
        } else res.push(loadMore);
    }

    return res;
}

/**
 * Pomocniczy komponent, który wyświetla ilość dni do dziś
 */
export const DaysAgo = ({ value }) => {
    const msgs=useMsgs();
    const days=useMemo(() => {
        let d=parseDate(value);
        if(!d) return null;
        return differenceInCalendarDays(new Date(), d);
    }, [ value ])
    if(days===null) return null;
    let msg;
    if(days===0) msg=msgs.gui.agoToday;
    else if(days===1) msg=msgs.gui.agoDay;
    else msg=formatString(msgs.gui.agoDays, days);
    return <span className="days-ago">{msg}</span>
}

/**
 * Komponent wyświetlający powiadomienie
 */
export const NotificationEntry = ({ value, className }: {
    value: UserWebNotification;
    className?: string;
}) => {
    const history=useHistory();
    let cn="notification-entry ";
    if(className) cn+=className;
    return <a
            className={cn}
            href={value.url}
            onClick={(e) => {
                e.preventDefault(); e.stopPropagation();
                history.push({ pathname: value.url })
            }}
    >
        <div className="content">
            <div className="message">{value.message}</div>
        </div>
        <div className="date">{formatDateTime(value.time)}</div>
    </a>
}

/**
 * Komponent typu pole numeryczne, które zwraca wartość jako liczbę (BigNumber),
 * aczkolwiek wewnątrz trzyma ją jako tekst, dzięki czemu możliwe jest wpisanie wartości typu 0,05
 */
export const SmartMoneyField = ({ value, onChange, ...props }: {
    value: BigNumber|string|null; onChange: (value: BigNumber|null) => void;
}) => {
    const [ strValue, setStrValue ] = useState<string>((value===null || value===undefined)?"":String(value));
    useEffect(() => {
        const v1=parseMoney(value);
        const v2=parseMoney(strValue);
        if(v1===null && v2===null) return;
        if(v1===null) {
            setStrValue("");
            return;
        }
        if(v2!==null && v1.eq(v2)) return;
        setStrValue(String(v1));
    }, [ value, strValue ]);

    return <Form.Control
        type="number" value={strValue}
        onChange={(e) => {
            let str=e.target.value;
            setStrValue(str);
            onChange(parseMoney(str));
        }}
        {...props}
    />
}

export type SaveButtonState = null|void|"default"|"loading"|"done";

/**
 * Przycisk do akcji typu zapisz, który animacji oraz potwierdzenia, że zapisano (inny wygląd).
 */
export const SaveButton = ({ state, children, variant, ...props }: {
    state?: SaveButtonState;
    children?: React$Node|string;
    variant?: string;
    onClick?: (e: SyntheticEvent) => void;
}) => {
    const msgs=useMsgs();
    let content;
    let cn;
    if(state==="loading") {
        cn="loading";
        content = <div className="loader"/>;
    } else if(state==="done") {
        cn="done";
        content= <span className="icon-checkmark"/>;
    } else {
        cn="";
        content = children || msgs.gui.actionSave;
    }

    return <Button
        // size="lg"
        variant={variant || "success"}
        className={"progress-button "+cn}
        type="submit"
        {...props}
    >{content}</Button>
}

export const ErrorDialog = ({ title, children, onHide, ...props }: {
    title: string;
    onHide: () => void;
} ) => {
    const msgs=useMsgs();
    return <Modal
        show={true} onHide={onHide} backdrop={true} size="lg"
        className="modal-error"
        backdropClassName="modal-backdrop-error"
    >
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {children}
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant="primary"
                onClick={onHide}
            >{msgs.gui.buttonClose}</Button>
        </Modal.Footer>
    </Modal>
}

export const RequestErrorDialog=() => {
    const msgs=useMsgs();
    const [ error, setError ] = useState<RequestError|null>(null);
    const onHide=useCallback(() => setError(null), []);
    useEvent("Error", (err) => {
        setError(err);
    }, []);

    if(error===null) return null;
    let title=msgs.gui.networkErrorOther;
    if(error.code===400) title=msgs.gui.networkErrorInvalidRequest;
    else if(error.code===500) title=msgs.gui.networkErrorInternalError;
    else if(error.code===504) title=msgs.gui.networkErrorServerDown;

    let otherMessage=null;
    if(error.code===504) otherMessage=<p className="text-info">{msgs.gui.networkErrorServerDownMessage}</p>;

    return <ErrorDialog
        title={"["+error.code+"] "+title}
        onHide={onHide}
    >
        <p>{msgs.gui.networkErrorTime}<b>{formatDateTime(error.time)}</b></p>
        {otherMessage?otherMessage:<>
            <p>{msgs.gui.networkErrorMethod}<b>{error.method}</b></p>
            <p>{msgs.gui.networkErrorServerMessage}<br/><code>{error.message}</code></p>
            <p>{msgs.gui.networkErrorPayload}<br/><code>{error.payload}</code></p>
        </>}
    </ErrorDialog>
}

let idGen=0;
/**
 * Komponent ikonki (i) wraz z dowolną treścią HTML.
 */
export const Hint = ({ children, placement, className, buttonTextClass, ...props }: {
    children: string|React$Node;
    placement?: "top"|"bottom"|"auto"|"auto-end"|"top-start"|string;
    className?: string;
    buttonTextClass?: string;
}) => {
    const id=useRef();
    if(!id.current) id.current=++idGen;
    if(typeof(buttonTextClass)!=='string') buttonTextClass='text-info';

    return <OverlayTrigger
        placement={placement || "top"}
        overlay={(props) => <Tooltip id={id.current} className="hint-tooltip" {...props}>
            {typeof(children)==='string'?<div dangerouslySetInnerHTML={{ __html: children }}/>:children}
        </Tooltip>
        }
    >
        <button className={cn("btn btn-link", buttonTextClass, className)}><Icon.Info/></button>
    </OverlayTrigger>
}

export const ExpandableText = ({ text, limit, className, more }: {
    text: string;
    limit?: number;
    className?: string;
    more?: string;
}) => {
    const [ expanded, setExpanded ] = useState(false);
    if(typeof(limit)!=='number' || limit<1) limit=128;
    if(typeof(text)!=='string' || text.length<=limit || expanded) return <span className={className}>{text}</span>;
    return <span
        className={cn(className, "expandable")}
        onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setExpanded(true);
        }}
    >{text.substring(0, limit)}{more || "..."}</span>
}
